import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.light,
  },
  screen: {
    flex: 1,
    height: '100%',
  },
  content: {
    flex: 1,
    paddingTop: 40,
    paddingBottom: 100,
    paddingHorizontal: 30,
    alignItems: 'center',
  },
  footer: {
    borderTopWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTopColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  btnExternal: {
    marginRight: 10,
  },
  btnDelete: {
    marginRight: 'auto',
  },
});

export default styles;
