import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useMenus } from '../../../../hooks/app/menus/useMenus';
import { useIsFocused } from '@react-navigation/native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useVenues } from '../../../../hooks/app/useVenues';
import { CreateCatalogueInput } from '@oolio-group/domain';
import { useNotification } from '../../../../hooks/Notification';
import styles from './Menus.styles';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../common/default-theme';
import { MenuRow } from './MenuRow';
import CreateMenuModal from './Modals/CreateMenu';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';
import Pagination from '../../../../components/Office/Pagination/Pagination';

export const Menus: React.FC = () => {
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { getVenues, venues: venueMaps } = useVenues();
  const { loading, getMenus, menus: menuMaps, createMenu, error } = useMenus();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const venues = useMemo(() => Object.values(venueMaps), [venueMaps]);

  useEffect(() => {
    if (isFocused) {
      getMenus();
      getVenues();
    }
  }, [getMenus, isFocused, getVenues]);

  const onCreateMenu = useCallback(
    (input: CreateCatalogueInput) => {
      closeModal();
      createMenu(input);
    },
    [closeModal, createMenu],
  );

  const onPressCreateMenu = useCallback((): void => {
    showModal(<CreateMenuModal onCreate={onCreateMenu} venues={venues} />);
  }, [onCreateMenu, showModal, venues]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  const filteredList = useMemo(() => {
    return Object.values(menuMaps ?? {})
      .filter(menu =>
        menu.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [menuMaps, searchQuery]);

  const pageItems = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredList, currentPage]);

  return (
    <ScreenLayout loading={loading} title="Menus | Oolio" hideFooter>
      <Section
        title={translate('menus.menusHeading')}
        subtitle={translate('menus.description')}
        layoutWidth="medium"
      >
        <View style={styles.filters}>
          <Search
            testID="search-menus"
            value={searchQuery}
            onChangeText={setSearchQuery}
            containerStyle={styles.search}
            placeholder={translate('menus.search')}
          />
          <CreateButton onPress={onPressCreateMenu} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('backOfficeFeatures.menu')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerType]}>
              {translate('backOfficeProducts.type')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerCount]}>
              {translate('backOfficeProducts.pages')}
            </Text>
          </View>
          <View>
            {pageItems.map(menu => (
              <MenuRow key={menu.id} menu={menu} />
            ))}
          </View>
          <Pagination
            page={currentPage}
            dataLength={filteredList.length}
            onPageChange={setCurrentPage}
            entityName={translate('menus.menu')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
