import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNetInfo } from '@react-native-community/netinfo';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { useSettingsSync } from '../../../hooks/app/useSettingsSync';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { snakeCase } from 'change-case';
import styles from './SystemStatus.styles';
import theme from '../../../common/default-theme';
import selectStyles from '../../Shared/Select/Select.styles';
import Icon from '../../Icon/Icon';
import SystemStatusRow, { STATES, SystemStatusAction } from './SystemStatusRow';
import { catalogueUtility } from '../../../state/catalogueUtility';
import { pluck } from 'rxjs';
import { FEATURES } from '../../../constants';

const SystemStatus: FC = () => {
  const touchable = useRef(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [ignoreNewCatalogVersion, setIgnoreNewCatalogVersion] = useState<
    boolean | undefined
  >(false);

  const [isAutoPublishMenuEnabled, setAutoPublishMenu] = useState(false);

  const netInfo = useNetInfo();
  const { syncApp } = useSettingsSync();
  const { translate } = useTranslation();

  const onToggleOptions = useCallback(() => {
    setShowOptions(!showOptions);
    analyticsService.capture('system_status_pressed');
  }, [showOptions]);

  const onPressAction = useCallback((name: string, action?: () => void) => {
    analyticsService.capture('system_status_action', {
      action: snakeCase(name),
    });
    action && action();
  }, []);

  const onPressSync = useCallback(() => {
    syncApp();
    setShowOptions(false);
  }, [syncApp]);

  const onPressSyncMenu = useCallback(() => {
    catalogueUtility.refetchCatalogue({ forceUpdate: false });
    setShowOptions(false);
  }, []);

  useEffect(() => {
    const subscription = catalogueUtility.getSubscriptionState$
      .pipe(pluck('ignoredNewCatalogueVersion'))
      .subscribe(state => setIgnoreNewCatalogVersion(state));
    return () => subscription.unsubscribe?.();
  }, []);

  useEffect(() => {
    (async function () {
      const isAutoPublishMenuEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.PUBLISH_MENU,
      );
      setAutoPublishMenu(isAutoPublishMenuEnabled);
    })();
  }, []);

  const OPTIONS: SystemStatusAction[] = useMemo(() => {
    const defaultOptions = [
      {
        testID: 'status-internet',
        label: translate('common.internet'),
        type: netInfo.isConnected ? STATES.POSITIVE : STATES.NEGATIVE,
      },
      {
        testID: 'btn-syncData',
        label: translate('settings.syncApp'),
        type: STATES.NEUTRAL,
        action: onPressSync,
      },
    ];
    if (isAutoPublishMenuEnabled) {
      return defaultOptions.concat({
        testID: 'btn-syncMenu',
        label: translate('settings.syncMenu'),
        type: ignoreNewCatalogVersion ? STATES.NEGATIVE : STATES.POSITIVE,
        action: onPressSyncMenu,
      });
    }
    return defaultOptions;
  }, [
    ignoreNewCatalogVersion,
    isAutoPublishMenuEnabled,
    netInfo.isConnected,
    onPressSync,
    onPressSyncMenu,
    translate,
  ]);

  const isAllGood = OPTIONS.every(option => option.type !== STATES.NEGATIVE);

  return (
    <View>
      <View>
        {isAllGood ? <View style={styles.marker} /> : null}
        <TouchableOpacity
          ref={touchable}
          testID="btn-system"
          onPress={onToggleOptions}
          style={[
            styles.button,
            {
              backgroundColor: isAllGood
                ? theme.colors.teritiary2
                : ignoreNewCatalogVersion
                ? theme.colors.states.focus
                : theme.colors.states.negative,
            },
          ]}
        >
          <Icon name="heart-rate" size={20} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
      <Popover
        from={touchable}
        isVisible={showOptions}
        placement={PopoverPlacement.BOTTOM}
        onRequestClose={() => setShowOptions(false)}
        backgroundStyle={selectStyles.bgStyle}
        popoverStyle={selectStyles.optionsContainer}
      >
        <View style={styles.optionsBg}>
          {OPTIONS.map((option, i) => (
            <SystemStatusRow
              key={i}
              testID={option.testID}
              type={option.type}
              label={option.label}
              action={() => onPressAction(option.label, option.action)}
            />
          ))}
        </View>
      </Popover>
    </View>
  );
};

export default SystemStatus;
