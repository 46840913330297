import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { View } from 'react-native';
import { CustomerGroup, FeatureIDs } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useNotification } from '../../../hooks/Notification';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import styles from './Customers.styles';
import Search from '../../../components/Shared/Search/Search';
import TreatPicker from '../../../components/Shared/Select/Picker';
import AddCustomer from '../../../components/Modals/Customer/AddCustomer';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import CreateButton from '../../../components/Office/CreateButton/CreateButton';
import CustomerView from './CustomersTable/CustomersTable';
import useBehaviorSubjectState from '../../../hooks/app/useSubjectState';
import { postSalesObservableForCustomerBalance } from '../../../hooks/app/usePostSalesNavigation';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { useOolioLoyalty } from '../../../hooks/useOolioLoyalty';

enum CustomerFilter {
  showAll = 'ShowAll',
  onAccount = 'OnAccount',
  loyaltyMember = 'LoyaltyMember',
  regularMember = 'RegularMember',
}

const itemsPerPage = 10;

const findCustomerGroupByFilter = (filter: CustomerFilter) => {
  const filterToGroupMap = {
    [CustomerFilter.showAll]: CustomerGroup.ALL,
    [CustomerFilter.loyaltyMember]: CustomerGroup.LOYALTY,
    [CustomerFilter.onAccount]: CustomerGroup.ON_ACCOUNT,
  };
  return filterToGroupMap[filter];
};

const Customers: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { isLoyaltyEnabled } = useOolioLoyalty();
  const isCustomerAccountEnabled = isFeatureEnabled(
    FeatureIDs.CUSTOMER_ACCOUNT,
  );

  const { value: customerBalanceFlowTriggered } = useBehaviorSubjectState(
    postSalesObservableForCustomerBalance,
  );
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [customerFilter, setCustomerFilter] = useState<CustomerFilter>(
    CustomerFilter.showAll,
  );
  const [currentPage, setCurrentPage] = useState(1);

  const {
    loading,
    paginatedCustomers: customers,
    error: customerDataError,
    getPaginatedCustomers,
    onFetchMore,
  } = useCustomers();

  const filteredCustomers = useMemo(() => {
    if (customerFilter === CustomerFilter.showAll) return customers;
    if (customerFilter === CustomerFilter.loyaltyMember) {
      return customers.filter(customer => customer?.loyaltyMember);
    }
    if (customerFilter === CustomerFilter.regularMember) {
      return customers.filter(customer => !customer?.loyaltyMember);
    }
    return customers.filter(
      customer => customer.customerAccountDetails?.accountPayment,
    );
  }, [customerFilter, customers]);

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      // calculate to fetch more
      const totalPagesRequired = Math.ceil(
        filteredCustomers.length / itemsPerPage,
      );
      if (page >= totalPagesRequired - 1) {
        onFetchMore();
      }
    },
    [filteredCustomers.length, onFetchMore],
  );

  const resetLocalState = useCallback(() => {
    setSearchCustomer('');
    setCustomerFilter(CustomerFilter.showAll);
  }, []);

  const onSuccessUpdateCustomer = useCallback(() => {
    getPaginatedCustomers('');
    setCurrentPage(1);
    resetLocalState();
  }, [getPaginatedCustomers, resetLocalState]);

  useEffect(() => {
    if (isFocused || searchCustomer || customerFilter) {
      const customerGroup = findCustomerGroupByFilter(customerFilter);
      getPaginatedCustomers(searchCustomer, customerGroup);
      setCurrentPage(1);
    }
  }, [isFocused, getPaginatedCustomers, searchCustomer, customerFilter]);

  useEffect(() => {
    return () => resetLocalState();
  }, [isFocused, resetLocalState]);

  useEffect(() => {
    if (customerDataError) {
      showNotification({
        error: true,
        message: customerDataError,
      });
    }
  }, [customerDataError, showNotification]);

  const customerTypes = useMemo(
    () => [
      {
        value: CustomerFilter.showAll,
        label: translate('customerView.showAll'),
      },
      ...(isCustomerAccountEnabled
        ? [
            {
              value: CustomerFilter.onAccount,
              label: translate('customerView.onAccount'),
            },
          ]
        : []),
      {
        value: CustomerFilter.loyaltyMember,
        label: translate('customerView.loyaltyMembers'),
      },
      ...(isLoyaltyEnabled
        ? [
            {
              value: CustomerFilter.regularMember,
              label: translate('backOfficeCustomers.customerGroups.regular'),
            },
          ]
        : []),
    ],
    [translate, isCustomerAccountEnabled, isLoyaltyEnabled],
  );

  const onPressCreateCustomer = useCallback(() => {
    showModal(
      <AddCustomer isEditing={false} onSuccess={onSuccessUpdateCustomer} />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, onSuccessUpdateCustomer, closeModal]);

  return (
    <ScreenLayout
      onBack={() => {
        if (customerBalanceFlowTriggered) {
          postSalesObservableForCustomerBalance.next(false);
          navigation.navigate('Orders', {
            screen: 'TakeOrder',
            params: {},
          });
        } else navigation.goBack();
      }}
      title={translate('navigation.customers')}
    >
      <View style={styles.filters}>
        <TreatPicker
          testID="select-customerType"
          options={customerTypes}
          selectedValue={customerFilter}
          onValueChange={setCustomerFilter}
          containerStyle={styles.dropdown}
        />
        <Search
          testID="search-customers"
          placeholder={translate('customerView.searchPlaceholder')}
          onChangeText={setSearchCustomer}
          containerStyle={styles.search}
        />
        <CreateButton onPress={() => onPressCreateCustomer()} />
      </View>
      <CustomerView
        data={filteredCustomers}
        isCustomerAccountEnabled={isCustomerAccountEnabled}
        currentPage={currentPage}
        onPageChange={onPageChange}
        itemsPerPage={itemsPerPage}
        loading={loading}
        onSuccessUpdateCustomer={onSuccessUpdateCustomer}
      />
    </ScreenLayout>
  );
};
export default Customers;
