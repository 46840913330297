export const kitchenDocketTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
  <print-mode mode="{{printMode}}" />
  {{#title}}
  <align mode="center">
    <text-line size="0:1">{{title}}</text-line>
  </align>
  {{/title}}
  {{#printerProfile}}
  <align mode="center">
    <text-line size="1:1">{{printerProfile}}</text-line>
  </align>
  {{/printerProfile}}
  {{#timeStamp}}
  <align mode="center">
    <text-line size="0:0">{{timeStamp}}</text-line>
  </align>
  {{/timeStamp}}
  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{/divider}}
  {{#channel}}
  <align mode="center">
    <text-line size="0:1">{{channel}}</text-line>
    <text-line size="0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{/channel}}
  <align mode="center">
    <bold>
      <text-line size="1:1">{{orderType}}</text-line>
    </bold>
  </align>
  {{#orderName}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
    <text-line size="1:1">{{orderName}}</text-line>
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/orderName}}
  <line-feed />
  <align mode="center">
    <bold>
      <text-line size="0:0">{{orderDetails}}</text-line>
    </bold>
  </align>
  {{#reasonOrNote}}
  <align mode="center">
    <bold>
      <text-line size="0:1">{{customerInfo}}</text-line>
      <text-line size="0:1">{{reasonOrNote}}</text-line>
      <text-line>{{{divider}}}</text-line>
    </bold>
  </align>
  {{/reasonOrNote}}
  {{#allergens}}
  <bold>
    <text-line>{{{divider}}}</text-line>
    <text-line color="red">{{allergens}}</text-line>
  </bold>
  <line-feed />
  {{/allergens}}
  {{#orderItems}}
  <align mode="center">
    {{#header}}
    <bold>
      <text-line size="0:0">{{header}}</text-line>
    </bold>
    <text-line size="0:0">{{dashDivider}}</text-line>
    {{/header}}
    {{#groupedItems}}
    {{#seat}}
    <line-feed />
    <text-line size="0:0">{{dashDivider}}</text-line>
    <bold>
      <text-line size="0:1">{{seat}}</text-line>
    </bold>
    {{/seat}}
    {{#seatAllergens}}
    <line-feed />
    <bold>
      <text-line color="red">{{seatAllergens}}</text-line>
    </bold>
    {{/seatAllergens}}
    {{#items}}
    <line-feed />
    <bold>
      <text-line size="1:1">{{product}}</text-line>
    </bold>
    {{#note}}
    <bold>
      <text-line size="1:0">{{note}}</text-line>
    </bold>
    {{/note}}
    {{#modifiers}}
    <bold>
      <text-line size="0:1">{{modifiers}}</text-line>
    </bold>
    {{/modifiers}}
    {{/items}}
    {{/groupedItems}}
    <text-line size="0:0">{{dashDivider}}</text-line>
    </align>
  {{/orderItems}}
  <align mode="center">
    {{#customerName}}
    <text-line size="0:1">{{customerName}}</text-line>
    {{/customerName}}
    {{#customerPhone}}
    <text-line size="0:1">{{customerPhone}}</text-line>
    {{/customerPhone}}
    {{#customerAddress}}
    <text-line size="0:1">{{customerAddress}}</text-line>
    {{/customerAddress}}
  </align>
  <paper-cut />
  {{#kitchenBuzzer}}
  <open-cash-drawer></open-cash-drawer>
  {{/kitchenBuzzer}}
</document>
`;

export const billingReceiptTemplate = `
<?xml version='1.0' encoding='UTF-8'?>
<document>
    {{#logo}}
      <align mode='center'>
        <image density="d24" mode='NORMAL'>
          {{logo}}
        </image>
        <line-feed />
      </align>
    {{/logo}}
    {{#storeName}}
    <align mode='center'>
    <bold>
        <text-line size='0:1'>{{storeName}}</text-line>
    </bold>
    </align>
    {{/storeName}}
    <line-feed />
    {{#storeNo}}
    <align mode='center'>
        <text-line>{{businessIdentifier}}:{{storeNo}}</text-line>
    </align>
    {{/storeNo}}
    <line-feed />
    {{#storeAddress}}
    <align mode='center'>
        <text-line>{{storeAddress}}</text-line>
    </align>
    {{/storeAddress}}
    {{#storePhone}}
    <align mode='center'>
        <text-line>
          {{storePhone}}
        </text-line>
    </align>
    {{/storePhone}}
    {{#headerText}}
      <align mode='center'>
      <text-line size='0:0'>{{{divider}}}</text-line>
      <line-feed/>
      <text-line>{{headerText}}</text-line>
      </align>
    {{/headerText}}
    <align mode='center'>
        <text-line size='0:0'>{{{divider}}}</text-line>
        <text-line size= '1:1'>{{orderName}}</text-line>
    </align>
    <line-feed />
    <align mode='center'>
        <text-line size='0:0'>{{orderDetails}}</text-line>
    </align>
    <align mode='center'>
        <text-line size='0:0'>{{{divider}}}</text-line>
    </align>
    <line-feed />
    <align mode='center'>
      <bold>
        <text-line size= '0:0'>{{orderItemsHeaders}}</text-line>
      </bold>
    </align>
    <line-feed />
    <align mode='center'>
        <text-line size='0:0'>{{orderItems}}</text-line>
    </align>
    <line-feed />
    <align mode='center'>
        <bold>
            <text-line size='0:1'>{{orderTotalDue}}</text-line>
        </bold>
    </align>
    <align mode='center'>
        <text-line size='0:0'>{{{divider}}}</text-line>
    </align>
    <line-feed />
    {{#paymentDetails}}
    <align mode='center'>
        <text-line>{{paymentDetails}}</text-line>
    </align>
    {{/paymentDetails}}
    {{#currentNthPayment}}
    <align mode='center'>
        <bold>
            <text-line>{{currentNthPayment}}</text-line>
        </bold>
    </align>
    {{/currentNthPayment}}
    {{#totalOutstanding}}
    <line-feed />
    <align mode='center'>
        <bold>
            <text-line>{{totalOutstanding}}</text-line>
        </bold>
    </align>
    {{/totalOutstanding}}
    {{#isPrintingPaymentReceipt}}
    <align mode='center'>
        <text-line size='0:0'>{{paymentReceiptDetails}}</text-line>
        <bold>
            <text-line size='0:0'>{{adyenTotal}}</text-line>
        </bold>
    </align>
    {{/isPrintingPaymentReceipt}}
    {{#isPrintingQR}}
    <align mode='center'> {{#qrCode}} <text-line size='0:0'>{{{divider}}}</text-line>
        <line-feed />
        <bold>
            <text-line size='0:0'>{{qrText}}</text-line>
        </bold>
        <qrcode model='2' size='5'
            ecl='L'>{{qrCode}}</qrcode> {{/qrCode}}
    </align>
    {{/isPrintingQR}}
    {{#customerName}}
    <align mode='center'>
        <text-line size='0:0'>{{{divider}}}</text-line>
    </align>
    <line-feed />
    <align mode='center'>
        <small>
            <text-line>Customer</text-line>
        </small>
        <text-line size='0:1'>
            {{customerName}}
        </text-line>
            {{#customerPhone}}
        <text-line size='0:0'>
            {{customerPhone}}
        </text-line>
            {{/customerPhone}}
        {{#customerAddress}}
        <text-line size='0:0'>
            {{customerAddress}}
        </text-line>
        {{/customerAddress}}
    </align>
    {{/customerName}}
    <align mode='center'>
        <text-line size='0:0'>{{{divider}}}</text-line>
    </align>
    <line-feed />
    {{#loyaltySnapshot}}
    <align mode='center'> {{#loyalty}} <bold>
            <text-line size='0:0'>{{loyaltyHeader}}</text-line>
        </bold>
        <text-line>{{loyalty}}</text-line>
        <line-feed />
        {{/loyalty}} {{#redeemedRewards}} <bold>
            <text-line size='0:0'>{{redeemedRewardsHeader}}</text-line>
        </bold>
        <text-line>
        {{redeemedRewards}}
        </text-line>
        <line-feed /> {{/redeemedRewards}}
        {{#availableRewards}} <bold>
            <text-line size='0:0'>{{availableRewardsHeader}}</text-line>
        </bold>
        <text-line>
        {{availableRewards}}
        </text-line>
        <line-feed /> {{/availableRewards}} <align
            mode='center'>
            <text-line size='0:0'>{{{divider}}}</text-line>
        </align>
        <line-feed />
    </align>
    {{/loyaltySnapshot}}
    {{#footerText}}
      <align mode='center'>
      <text-line size='0:0'>
          {{footerText}}
      </text-line>
      </align>
    {{/footerText}}
    {{#website}}
    <align mode='center'>
      <text-line size='0:0'>
          Website: {{website}}
      </text-line>
    </align>
    {{/website}}
    {{#facebook}}
    <align mode='center'>
      <text-line size='0:0'>
        Facebook: {{facebook}}
      </text-line>
    </align>
    {{/facebook}}
    {{#instagram}}
    <align mode='center'>
      <text-line size='0:0'>
        Instagram: {{instagram}}
      </text-line>
    </align>
    {{/instagram}}
    {{#twitter}}
    <align mode='center'>
      <text-line size='0:0'>
          X (Twitter): {{twitter}}
      </text-line>
    </align>
    {{/twitter}}
    {{#oolioBranding}}
    <align mode='center'>
      <line-feed/>
      <text-line size='0:0'>
      Powered By
      </text-line>
      <image>
      {{oolioLogo}}
      </image>
    </align>
    {{/oolioBranding}}
    <paper-cut />
</document>`;

export const tokenNumberTemplate = `
    <?xml version= "1.0" encoding= "UTF-8"?>
    <document>
      <align mode= "center">{{#organization}}
        <bold>
          <text-line size= "0:1">{{organization}}</text-line>
        </bold>{{/organization}}
        <align mode= "center">
          <under-line mode= "two-point"></under-line>
          <align mode= "center">
          <text-line size= "0:0">{{orderDetails}}</text-line>
          </align>
          <align mode= "center">
          <text-line size= "0:0">{{{divider}}}</text-line>
          </align>
          {{#orderName}}
            <align mode= "center">
            <line-feed />
              <text-line size= "1:1">{{orderName}}</text-line>
            </align>
          {{/orderName}}
          <align mode= "center">
            <text-line size= "0:0">{{{divider}}}</text-line>
          </align>
          <paper-cut />
        </document>`;

export const recipeTemplate = `
    <?xml version= "1.0" encoding= "UTF-8"?>
    <document>
      <print-mode mode="{{printMode}}" />
      <align mode= "center">
        <bold>
          <text-line size= "0:1">{{title}}</text-line>
        </bold>
        <line-feed />
        <bold>
          <text-line size= "0:1">{{productName}}</text-line>
        </bold>
        <text-line size= "0:0">{{{divider}}}</text-line>
        <line-feed />
        <text-line>{{recipe}}</text-line>
        <paper-cut />
        </document>`;

export const smlkitchenDocketTemplate = `
<?xml version= "1.0" encoding= "UTF-8"?>
<document>
  <print-mode mode= {{printMode}} />
  <align mode= "center">
    <text-line size= "0:0">{{title}}</text-line>
    {{#printerProfile}}
    <text-line size= "1:0">{{printerProfile}}</text-line>
    {{/printerProfile}}
    <text-line size= "0:0">{{timeStamp}}</text-line>
  </align>
  <align mode= "center">
    <text-line size= "0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{#channel}}
    <align mode= "center">
      <text-line size= "0:0">{{channel}}</text-line>
      <text-line size= "0:0">{{{divider}}}</text-line>
      <line-feed />
    </align>
  {{/channel}}
  <align mode= "center">
    <bold>
      <text-line size= "1:0">{{orderType}}</text-line>
    </bold>
  </align>
  {{#orderName}}
    <align mode= "center">
      <text-line size= "0:0">{{{divider}}}</text-line>
      <text-line size= "1:0">{{orderName}}</text-line>
      <text-line size= "0:0">{{{divider}}}</text-line>
    </align>
  {{/orderName}}
  <line-feed />
  <align mode= "center">
    <bold>
      <text-line size= "0:0">{{orderDetails}}</text-line>
    </bold>
  </align>
  <align mode= "center">
    <text-line>{{{divider}}}</text-line>
  </align>
  <align mode= "center">
    <line-feed />
    {{#customerInfo}}
    <text-line size= "0:0">{{customerInfo}}</text-line>
    <line-feed />
    {{/customerInfo}}
    {{#reasonOrNote}}
      <bold>
        <text-line size= "0:0">{{reasonOrNote}}</text-line>
      </bold>
    <line-feed />
    {{/reasonOrNote}}
    {{#allergens}}
    <bold>
      <text-line>{{{divider}}}</text-line>
      <text-line color="red">{{allergens}}</text-line>
    </bold>
    <line-feed />
    {{/allergens}}
    <text-line>{{{divider}}}</text-line>
  </align>
  {{#orderItems}}
    {{#header}}
      <align mode= "center">
        <bold>
          <text-line size= "0:0">{{header}}</text-line>
        </bold>
        <text-line size= "0:0">{{dashDivider}}</text-line>
      </align>
    {{/header}}
      {{#groupedItems}}
      <line-feed />
        {{#seat}}
          <align mode= "center">
            <bold>
              <text-line size= "0:0">{{seat}}</text-line>
            </bold>
          </align>
        {{/seat}}
        {{#seatAllergens}}
        <line-feed />
        <bold>
          <text-line color="red">{{seatAllergens}}</text-line>
        </bold>
        {{/seatAllergens}}
        {{#items}}
          <line-feed />
          <align mode= "center">
            <bold>
              <text-line size= "1:0">{{product}}</text-line>
            </bold>
            {{#note}}
            <bold>
            <text-line size= "1:0">{{note}}</text-line>
            </bold>
            {{/note}}
            {{#modifiers}}
            <bold>
            <text-line size= "0:0">{{modifiers}}</text-line>
            </bold>
            {{/modifiers}}
          </align>
        {{/items}}
        <text-line size= "0:0">{{dashDivider}}</text-line>
      {{/groupedItems}}
    <align mode= "center">
    </align>
  {{/orderItems}}
  <align mode= "center">
    {{#customerName}}
      <text-line size= "0:0">{{customerName}}</text-line>
    {{/customerName}}
    {{#customerPhone}}
      <text-line size= "0:0">{{customerPhone}}</text-line>
    {{/customerPhone}}
    {{#customerAddress}}
      <text-line size= "0:0">{{customerAddress}}</text-line>
    {{/customerAddress}}
  </align>
  <paper-cut />
  {{#kitchenBuzzer}}
    <open-cash-drawer></open-cash-drawer>
  {{/kitchenBuzzer}}
</document>
`;

export const lrgKitchenDocketTemplate = `
  <?xml version= "1.0" encoding= "UTF-8"?>
  <document>
    <print-mode mode= {{printMode}} />
    <align mode= "center">
      <text-line size= "0:1">{{title}}</text-line>
      {{#printerProfile}}
      <text-line size= "1:1">{{printerProfile}}</text-line>
      {{/printerProfile}}
      <text-line size= "0:0">{{timeStamp}}</text-line>
    </align>
    <align mode= "center">
      <text-line size= "0:0">{{{divider}}}</text-line>
      <line-feed />
    </align>
    {{#channel}}
      <align mode= "center">
        <text-line size= "0:1">{{channel}}</text-line>
        <text-line size= "0:0">{{{divider}}}</text-line>
        <line-feed />
      </align>
    {{/channel}}
    <align mode= "center">
      <bold>
        <text-line size= "1:1">{{orderType}}</text-line>
      </bold>
    </align>
    {{#orderName}}
      <align mode= "center">
        <text-line size= "0:0">{{{divider}}}</text-line>
        <text-line size= "1:1">{{orderName}}</text-line>
        <text-line size= "0:0">{{{divider}}}</text-line>
      </align>
    {{/orderName}}
    <line-feed />
    <align mode= "center">
      <bold>
        <text-line size= "0:0">{{orderDetails}}</text-line>
      </bold>
    </align>
    <align mode= "center">
      <text-line>{{{divider}}}</text-line>
    </align>
    <align mode= "center">
    <line-feed />
    {{#customerInfo}}
    <text-line size= "1:1">{{customerInfo}}</text-line>
    <line-feed />
    {{/customerInfo}}
    {{#reasonOrNote}}
      <bold>
        <text-line size= "1:1">{{reasonOrNote}}</text-line>
      </bold>
    <line-feed />
    {{/reasonOrNote}}
    {{#allergens}}
    <bold>
      <text-line>{{{divider}}}</text-line>
      <text-line color="red">{{allergens}}</text-line>
    </bold>
    <line-feed />
    {{/allergens}}
    <text-line>{{{divider}}}</text-line>
    </align>
    {{#orderItems}}
      {{#header}}
        <align mode= "center">
          <bold>
            <text-line size= "0:0">{{header}}</text-line>
          </bold>
          <text-line size= "0:0">{{dashDivider}}</text-line>
        </align>
      {{/header}}
        {{#groupedItems}}
          {{#seat}}
          <line-feed />
            <align mode= "center">
            <text-line size= "0:0">{{dashDivider}}</text-line>
              <bold>
                <text-line size= "0:1">{{seat}}</text-line>
              </bold>
            </align>
          {{/seat}}
          {{#seatAllergens}}
          <line-feed />
          <bold>
            <text-line color="red">{{seatAllergens}}</text-line>
          </bold>
          {{/seatAllergens}}
          {{#items}}
            <line-feed />
            <align mode= "center">
              <bold>
                <text-line size= "1:1">{{product}}</text-line>
              </bold>
              {{#note}}
              <bold>
              <text-line size= "1:1">{{note}}</text-line>
              </bold>
              {{/note}}
              {{#modifiers}}
              <bold>
              <text-line size= "1:1">{{modifiers}}</text-line>
              </bold>
              {{/modifiers}}
            </align>
          {{/items}}
        {{/groupedItems}}
      <align mode= "center">
        <text-line size= "0:0">{{dashDivider}}</text-line>
      </align>
    {{/orderItems}}
    <align mode= "center">
      {{#customerName}}
        <text-line size= "0:1">{{customerName}}</text-line>
      {{/customerName}}
      {{#customerPhone}}
        <text-line size= "0:1">{{customerPhone}}</text-line>
      {{/customerPhone}}
      {{#customerAddress}}
        <text-line size= "0:1">{{customerAddress}}</text-line>
      {{/customerAddress}}
    </align>
    <paper-cut />
    {{#kitchenBuzzer}}
      <open-cash-drawer></open-cash-drawer>
    {{/kitchenBuzzer}}
  </document>
`;

export const kitchenDocket58mmTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
  <print-mode mode="{{printMode}}" />
  {{#title}}
  <align mode="center">
    <text-line size="0:0">{{title}}</text-line>
  </align>
  {{/title}}

  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

  {{#orderName}}
  <align mode="left">
    <text-line size="1:1">{{orderName}}</text-line>
  </align>
  {{/orderName}}
  <line-feed />
  <align mode="center">
    <bold>
      <text-line size="0:0">{{orderType}}</text-line>
    </bold>
  </align>

    {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

  {{#orderItems}}
  <align mode="center">
    {{#groupedItems}}
    {{#seat}}
    <bold>
      <text-line size="0:1">{{seat}}</text-line>
    </bold>
    {{/seat}}
    {{#seatAllergens}}
    <line-feed />
    <bold>
      <text-line color="red">{{seatAllergens}}</text-line>
    </bold>
    {{/seatAllergens}}
    {{#items}}
    <line-feed />
    <bold>
      <text-line size="1:1">{{product}}</text-line>
    </bold>
    {{#note}}
    <bold>
      <text-line size="1:0">{{note}}</text-line>
    </bold>
    {{/note}}
    {{#modifiers}}
    <bold>
      <text-line size="0:1">{{modifiers}}</text-line>
    </bold>
    {{/modifiers}}
    {{/items}}
    {{/groupedItems}}
    </align>
  {{/orderItems}}


  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

    {{#footer}}
  <align mode="center">
    <text-line size="0:0">{{footer}}</text-line>
    <line-feed />
  </align>
  {{/footer}}
  <paper-cut />
</document>
`;
