/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View } from 'react-native';
import {
  useTranslation,
  getBusinessIdentifierType,
} from '@oolio-group/localization';
import theme from '../../../../../../common/default-theme';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import AddressForm from '../../../../../../components/Shared/Forms/AddressForm';
import { Store } from '@oolio-group/domain';
import { styles } from './DetailsForm.styles';
import {
  isNotEmpty,
  isValidBusinessIdentifier,
  isValidEmail,
  isValidPostCode,
} from '../../../../../../utils/validator';

interface DetailsFormProps {
  testID?: string;
  form: Store;
  onChange: (key: string, value: string) => void;
  onChangeAddress: (key: string, value: string) => void;
}

export const DetailsForm: React.FC<DetailsFormProps> = ({
  form,
  onChange,
  onChangeAddress,
}) => {
  const { translate } = useTranslation();
  const businessIdentifierType = getBusinessIdentifierType(
    form.address?.country || '',
  );

  const formValidationStatus: Record<string, boolean> = {
    // business identifier Details
    businessIdentifier: isValidBusinessIdentifier(
      form.businessIdentifier || '',
      form.address?.country,
    ),
    // Contact Details
    email: isValidEmail(form.email || ''),
    // Location Details
    line1: isNotEmpty(form.address?.line1),
    line2: isNotEmpty(form.address?.line2),
    city: isNotEmpty(form.address?.city),
    state: isNotEmpty(form.address?.state),
    postalCode: isValidPostCode(
      form.address?.postalCode || '',
      form.address?.country,
    ),
    country: isNotEmpty(form.address?.country),
  };

  return (
    <>
      <View style={theme.forms.row}>
        <InputText
          testID="input-name"
          title={translate('form.name')}
          value={form.name || ''}
          placeholder={translate('form.name')}
          onChangeText={onChange.bind(null, 'name')}
          maxLength={50}
          containerStyle={
            !!businessIdentifierType
              ? theme.forms.inputHalf
              : theme.forms.inputFluid
          }
        />
        {!!businessIdentifierType ? (
          <InputText
            testID="input-businessIdentifier"
            title={translate('form.businessNumber', {
              type: businessIdentifierType,
            })}
            value={form.businessIdentifier || ''}
            placeholder={translate('form.businessNumber', {
              type: businessIdentifierType,
            })}
            errorMessage={
              form.businessIdentifier &&
              !formValidationStatus.businessIdentifier
                ? translate('form.invalidInput', {
                    title: translate('form.businessNumber', {
                      type: businessIdentifierType,
                    }),
                  })
                : undefined
            }
            maxLength={13}
            onChangeText={onChange.bind(null, 'businessIdentifier')}
            containerStyle={theme.forms.inputHalf}
          />
        ) : null}
      </View>
      <View style={theme.forms.row}>
        <InputEmail
          testID="input-email"
          title={translate('form.email')}
          value={form?.email}
          placeholder={translate('form.email')}
          errorMessage={
            form?.email && !formValidationStatus.email
              ? translate('form.invalidInput', {
                  title: translate('form.email'),
                })
              : undefined
          }
          onChangeText={onChange.bind(null, 'email')}
          containerStyle={theme.forms.inputHalf}
        />
        <InputPhone
          testID="input-phone"
          title={translate('form.phoneNumber')}
          value={form?.phoneNumber}
          defaultCountry={form.address?.country}
          onChangeText={text => onChange('phoneNumber', text)}
          onPressCountry={country => onChange('countryCode', country)}
          containerStyle={theme.forms.inputHalf}
        />
      </View>

      <AddressForm
        onChangeAddress={onChangeAddress}
        value={{
          line1: form.address?.line1,
          line2: form.address?.line2,
          city: form.address?.city,
          suburb: form.address?.suburb,
          state: form.address?.state,
          postalCode: form.address?.postalCode,
          country: form.address?.country,
        }}
      />
      <View>
        <InputText
          testID="merchant-input-name"
          title={translate('form.merchantCode')}
          value={form.merchantCode}
          placeholder={translate('form.merchantCode')}
          onChangeText={onChange.bind(null, 'merchantCode')}
          containerStyle={styles.inputContainer}
        />
      </View>
    </>
  );
};
