import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { View, Text, Modal, ScrollView, FlatList } from 'react-native';
import {
  Modifier,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
  ComboItem,
  Product,
  OrderItemComboProduct,
  OrderItemModifier,
  Money,
  VariantAsItem,
  ProductModifierGroup,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import styles from './RequiredOptionsModal.styles';
import theme from '../../../../common/default-theme';
import modalStyles from '../../../Shared/Modals/Modal/Modal.styles';
import OptionGroupTab from './OptionGroupTab';
import MenuItem from '../../MenuItem/MenuItem';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import { sumDecimals } from '@oolio-group/order-helper';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import { getBestPriceOfModifier } from '@oolio-group/catalog-helper';
import {
  OptionItem,
  SelectedOptionGroup,
  SelectedOptions,
} from '../../../../types/Options.type';
import { SelectedComboOptions } from '../../Cart/Cart';
import { useOptionGroupHelper } from './useOptionGroupHelper';

export type OptionModifierGroup = ProductModifierGroup & { productId?: string };
export type OptionGroupItem = ProductModifierGroup & { isSelecting: boolean };
export interface OptionsModalProps {
  parentProduct: Product;
  onCancel: () => void;
  onAddOptionsToItem: (
    selectedOptionGroups: SelectedOptionGroup[],
    comboItems?: OrderItemComboProduct[],
  ) => void;
  selectedOptions?: SelectedOptions;
  selectedOptionGroupId?: string;
  isComboProduct?: boolean;
  selectedComboOptions?: SelectedComboOptions;
}

const filterValidItem = (item: OptionItem) =>
  Boolean(item?.quantity && item.quantity >= 1);

const mapDefaultOptionItem = (item: OptionItem): OptionItem => {
  if (!item.isDefault) return item;
  const assumedQuantity = item.quantity;
  const validQuantity = item.removeDefault ? 1 : assumedQuantity - 1;
  return {
    ...item,
    quantity: validQuantity,
  };
};

const getTotalSelection = (options?: OptionItem[]): number => {
  return sumDecimals((options || []).map(item => item.quantity));
};
const RequiredOptionsModal: FC<OptionsModalProps> = ({
  parentProduct,
  onAddOptionsToItem,
  onCancel,
  selectedOptions: selectedOptionsProp,
  selectedOptionGroupId: selectedOptionGroupIdProp,
  isComboProduct,
  selectedComboOptions,
}) => {
  const { translate } = useTranslation();
  const [currentProductAsModifier, setCurrentProductAsModifier] =
    useState<ComboItem>();
  const isDataEdited = useRef<boolean>(false);

  const currentItem = useMemo(() => {
    const product = currentProductAsModifier
      ? currentProductAsModifier.productDetail ||
        currentProductAsModifier.variantDetail
      : parentProduct;
    return {
      id: product?.id ?? '',
      name: product?.name ?? '',
      variant: currentProductAsModifier?.variantDetail,
    };
  }, [parentProduct, currentProductAsModifier]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>(
    selectedOptionsProp || {
      [currentItem.id]: {},
    },
  );
  const flatListRef = useRef<FlatList<OptionGroupItem> | null>(null);
  const allOptionGroups = useMemo(() => {
    const parentModifierGroups = parentProduct?.modifierGroups || [];
    const allOptionGroups: OptionModifierGroup[] = [];
    parentModifierGroups.forEach(group => {
      const productGroups = group.products
        .map(p =>
          p.productDetail?.modifierGroups?.length
            ? p.productDetail.modifierGroups.map(
                g =>
                  ({
                    ...g,
                    productId: p.productDetail?.id,
                  } as OptionModifierGroup),
              )
            : [],
        )
        .flat()
        .filter(g => g);
      allOptionGroups.push(...productGroups, group);
    });
    return allOptionGroups || [];
  }, [parentProduct?.modifierGroups]);
  const [currentOptionGroups, setCurrentOptionGroups] = useState<
    ProductModifierGroup[]
  >(parentProduct?.modifierGroups || []);

  const [activeGroupId, setActiveGroupId] = useState(
    currentOptionGroups?.[0]?.id ?? '',
  );

  const optionGroupMaps = useMemo(
    () => keyBy(currentOptionGroups, 'id'),
    [currentOptionGroups],
  );

  const allOptionGroupMaps = useMemo(
    () => keyBy(allOptionGroups, 'id'),
    [allOptionGroups],
  );

  const activeGroup = useMemo(
    () => optionGroupMaps[activeGroupId],
    [activeGroupId, optionGroupMaps],
  );

  const {
    productAndVariantMap,
    getFilteredVariantProductGroups,
    getSelectionLimitString,
    getSelectedModifiers,
    getVariantOptionValues,
    setOrRemoveVariantOptions,
  } = useOptionGroupHelper({
    product: parentProduct,
    selectedOptions,
    setSelectedOptions,
    selectedOptionsProp,
    isComboProduct,
  });

  const onNavigateToProduct = useCallback(
    ({
      groups,
      optionItem,
      activeGroupId,
    }: {
      groups: ProductModifierGroup[];
      optionItem?: ComboItem;
      activeGroupId?: string;
    }) => {
      setCurrentOptionGroups(groups);
      setActiveGroupId(activeGroupId || groups?.[0]?.id || '');
      if (optionItem?.productDetail) {
        setSelectedOptions({
          ...selectedOptions,
          [optionItem.productDetail.id]: {
            ...selectedOptions[optionItem.productDetail.id],
          },
        });
      }
      setCurrentProductAsModifier(optionItem ? optionItem : undefined);
    },
    [selectedOptions],
  );

  //set edit value fo option group and combo product
  useEffect(() => {
    if (selectedComboOptions?.parentModifierGroupId && !isDataEdited.current) {
      const {
        parentModifierGroupId,
        childModifierGroupId,
        comboProductId,
        selectedVariantId,
      } = selectedComboOptions;
      let comboItem: ComboItem | undefined = undefined,
        optionGroups: ProductModifierGroup[] =
          parentProduct.modifierGroups ?? [];

      if (selectedVariantId) {
        const activeGroup = parentProduct.modifierGroups.find(
          g => g.id === parentModifierGroupId,
        ) as ProductModifierGroup;
        comboItem = productAndVariantMap[selectedVariantId] as ComboItem;
        optionGroups = getFilteredVariantProductGroups({
          variant: comboItem.variantDetail as VariantAsItem,
          productId: comboProductId as string,
          group: activeGroup,
        });
      } else if (childModifierGroupId) {
        const allItems = parentProduct.modifierGroups
          .map(g => (g.products?.length ? g.products : []))
          .flat();
        comboItem = allItems.find(item => item.id === comboProductId);
        optionGroups = comboItem?.productDetail?.modifierGroups ?? [];
      }
      onNavigateToProduct({
        groups: optionGroups,
        optionItem: comboItem,
        activeGroupId: childModifierGroupId || parentModifierGroupId,
      });
      isDataEdited.current = true;
    }
  }, [
    selectedComboOptions,
    parentProduct.modifierGroups,
    getFilteredVariantProductGroups,
    productAndVariantMap,
    onNavigateToProduct,
  ]);

  const formattedOptionGroups = useMemo<OptionGroupItem[]>(() => {
    return currentOptionGroups.map(item => ({
      ...item,
      isSelecting: item?.id === activeGroupId,
    }));
  }, [activeGroupId, currentOptionGroups]);

  // Helper function to check if a group's selection criteria is met
  const isGroupWithinLimits = useCallback(
    (group?: ProductModifierGroup, optionSelectionsProp?: SelectedOptions) => {
      if (!group) return false;
      if (group?.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) return true;

      const numOfSelectedOptions = getTotalSelection(
        (optionSelectionsProp || selectedOptions)[currentItem.id]?.[group.id] ||
          [],
      );
      const { min, max } = group.selectionLimit || {};
      if (group?.isRequired)
        return (
          numOfSelectedOptions >= Math.max(min, 1) &&
          numOfSelectedOptions <= max
        );
      return numOfSelectedOptions <= max;
    },
    [selectedOptions, currentItem.id],
  );

  // Generates subtitle string for option group selections
  const getGroupSelectionsString = useCallback(
    (group?: ProductModifierGroup) => {
      const productIds = group?.products?.map(p => p.id) ?? [];
      const isSingleSelection =
        (group?.maxSelectionPerOption ?? 1) <= 1 &&
        (group?.selectionLimit?.max ?? 1) <= 1;
      let modifierName = '';

      isSingleSelection &&
        productIds?.map(productId => {
          const productState = selectedOptions[productId];
          if (productState && Object.values(productState || {})) {
            modifierName = Object.values(productState || {})
              .flat()
              .map(option => option.name)
              .join(', ');
          }
        });
      const groupSelections =
        selectedOptions[currentItem.id]?.[group?.id as string];

      if (!groupSelections)
        return { subTitle: translate('modifiers.notSelected') };

      const names = groupSelections
        .filter(filterValidItem)
        .map(option => option.name);
      if (names.length === 0) {
        return group?.isRequired
          ? { subTitle: translate('modifiers.notSelected') }
          : { subTitle: translate('modifiers.optional') };
      } else {
        return { subTitle: names.join(', '), modifiers: modifierName };
      }
    },
    [selectedOptions, translate, currentItem.id],
  );

  const onPressNextGroup = useCallback(
    (actualSelections?: SelectedOptions) => {
      const nextRequiredGroup = currentOptionGroups.find(
        group => !isGroupWithinLimits(group, actualSelections),
      );
      let nextActiveGroupId;
      if (nextRequiredGroup?.id) {
        nextActiveGroupId = nextRequiredGroup?.id;
      } else {
        const currentIndex = currentOptionGroups.findIndex(
          optionGroup => optionGroup?.id === activeGroupId,
        );
        const maxIndex = currentOptionGroups?.length - 1;
        let nextIndex = currentIndex + 1;
        if (nextIndex > maxIndex) {
          nextIndex = 0;
        }
        nextActiveGroupId = currentOptionGroups[nextIndex]?.id;
      }
      setActiveGroupId(nextActiveGroupId);
    },
    [activeGroupId, isGroupWithinLimits, currentOptionGroups],
  );

  // onPress to add option to components state
  const onSelectOptionItem = useCallback(
    (activeOption: Modifier | ComboItem, quantity: number) => {
      const productId = currentItem.id;
      const variantId = (activeOption as ComboItem).variantDetail?.id;
      setSelectedOptions(prevOptions => {
        const selectingOptions = prevOptions[productId]?.[activeGroupId] || [];
        const isOptionSelected = selectingOptions.find(
          o => o.id == activeOption.id,
        );

        let updatedSelections: OptionItem[];
        let isRemoveVariantOptions: boolean | undefined;
        if (isOptionSelected) {
          updatedSelections = selectingOptions
            .map(option => {
              if (option.id === activeOption.id) {
                const updatedQuantity = (option.quantity || 0) + quantity;
                isRemoveVariantOptions = !!(variantId && updatedQuantity === 0);
                if (option.isDefault) {
                  option.removeDefault = updatedQuantity == 0;
                } else if (updatedQuantity < 1) return undefined;
                return { ...option, quantity: updatedQuantity };
              }
              return option;
            })
            .filter(o => o) as OptionItem[];
        } else {
          updatedSelections = selectingOptions.concat({
            ...activeOption,
            quantity,
          });
        }

        const updatedOptions = {
          ...prevOptions,
          [productId]: {
            ...prevOptions[productId],
            [activeGroupId]: updatedSelections,
          },
          ...(variantId && isRemoveVariantOptions && { [variantId]: {} }),
        };

        const max =
          activeGroup?.selectionLimit?.max ||
          DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION;

        const numOfOptionSelected = getTotalSelection(updatedSelections);

        if (numOfOptionSelected > max) {
          return prevOptions;
        }

        if (numOfOptionSelected === max) {
          // auto move to next option group
          onPressNextGroup(updatedOptions);
        }

        return updatedOptions;
      });
    },
    [
      activeGroup?.selectionLimit?.max,
      activeGroupId,
      onPressNextGroup,
      currentItem,
    ],
  );

  /**
   * Preparing the combo objects which we need to send to events
   * OrderItemComboProduct type
   */
  const getSelectedComboOptions = useCallback(() => {
    const parentGroupOptions = selectedOptions[parentProduct.id];
    const comboItems = Object.keys(parentGroupOptions || {})
      .map(groupId => {
        return parentGroupOptions[groupId].map(p => {
          const comboItem = p as ComboItem;
          const product = productAndVariantMap[
            comboItem.productDetail?.id as string
          ] as Product;
          const variantId = product?.variantId as string;
          // product can be normal product or can be variant
          const childProduct = selectedOptions[variantId || p.id];
          const modifiers = (
            childProduct && product?.modifierGroups?.length
              ? getSelectedModifiers(childProduct, p)
              : []
          ) as OrderItemModifier[];

          const variant = (productAndVariantMap[variantId] as ComboItem)
            ?.variantDetail;
          const optionValues = getVariantOptionValues(
            comboItem,
            variant?.options || [],
          );

          return {
            product: {
              id: product.id,
              name: product.name,
              ...(optionValues?.length && { optionValues }),
            } as Product,
            ...(product?.pricings?.length && { pricing: product.pricings }),
            modifierGroupId: groupId,
            priority: allOptionGroupMaps[groupId].priority,
            amount: p.price?.amount,
            quantity: p.quantity,
            modifiers: modifiers || [],
            ...(variant && { variant: { id: variant.id, name: variant.name } }),
          } as OrderItemComboProduct;
        });
      })
      .flat();
    return comboItems;
  }, [
    selectedOptions,
    parentProduct,
    getSelectedModifiers,
    allOptionGroupMaps,
    productAndVariantMap,
    getVariantOptionValues,
  ]);

  // onPress to add selected options to cart
  const onPressAddToCart = useCallback(() => {
    const updatedOptionGroups: SelectedOptionGroup[] = [];
    let comboItems: OrderItemComboProduct[] = [];
    const productIds = Object.keys(selectedOptions);
    if (isComboProduct) {
      comboItems = getSelectedComboOptions();
    } else {
      productIds.forEach(productId => {
        const options = selectedOptions[productId];
        const optionsToUpdate = Object.keys(options).map(groupId => ({
          groupId,
          priority: allOptionGroupMaps[groupId].priority,
          options: selectedOptions[productId][groupId]
            .map(mapDefaultOptionItem)
            .filter(filterValidItem),
        }));
        updatedOptionGroups.push(...optionsToUpdate);
      });
    }

    const sortedData = sortBy(updatedOptionGroups, 'priority');
    const sortedComboProducts = sortBy(comboItems, 'priority');

    onAddOptionsToItem(sortedData, sortedComboProducts);
  }, [
    onAddOptionsToItem,
    selectedOptions,
    isComboProduct,
    allOptionGroupMaps,
    getSelectedComboOptions,
  ]);

  /**
   *
   * This will set the variant product and its option/modifier to state upon saving
   */
  const setSaveSelectionForVariantOptions = () => {
    if (!currentItem.variant) return;

    const variantProducts = currentItem.variant.products || [];
    const productIdsToExclude: string[] = [],
      groupIdsToExclude: string[] = [];
    variantProducts.forEach(p => {
      const groups = (p as ComboItem)?.productDetail?.modifierGroups || [];
      productIdsToExclude.push(p.id);
      const groupIds = groups.map(g => g.id).flat();
      groupIdsToExclude.push(...groupIds);
    });

    //Finding variant option group and variant product, we'll replace it with old state
    const variantOptionGroup = currentOptionGroups.find(
      group => !groupIdsToExclude.includes(group.id),
    ) as ProductModifierGroup;
    const variantProduct = selectedOptions[currentItem.id]?.[
      variantOptionGroup.id
    ].find(i => (i as ComboItem).productDetail) as OptionItem;

    let oldVariantQuantity = 0;
    const optionItems = (
      selectedOptions[parentProduct.id]?.[variantOptionGroup.id] || []
    ).filter(p => {
      if (p.id === currentItem.id) {
        oldVariantQuantity = p.quantity;
        return false;
      }
      return true;
    });
    //Setting the state for the variant into cart
    setSelectedOptions({
      ...selectedOptions,
      [parentProduct.id]: {
        ...selectedOptions[parentProduct.id],
        [variantOptionGroup.id]: [
          ...optionItems,
          {
            ...variantProduct,
            id: currentItem.variant.id,
            name:
              (variantProduct as ComboItem).productDetail?.name ||
              variantProduct.name,
            quantity: oldVariantQuantity || variantProduct.quantity,
            isVariant: true,
          },
        ],
      },
    });
    onNavigateToProduct({
      groups: parentProduct.modifierGroups,
      activeGroupId: variantOptionGroup.id,
    });
  };

  /*
    This function will set the product into the state rather modifier, modifier was set as soon as we select it
   */
  const onSaveSelection = () => {
    // [UPDATE START FOR VARIANT] Update variant product to state
    if (currentItem.variant) {
      setSaveSelectionForVariantOptions();
      return;
    }
    // [UPDATE END FOR VARIANT] Update variant product to state

    let productToAdd: ComboItem | undefined;
    const modifierGroup = parentProduct.modifierGroups.find(group =>
      group.products.find(p => {
        if (p.id === currentItem.id) {
          productToAdd = p;
          return true;
        }
      }),
    );
    /**
     * While selecting modifier if we haven't select the product then upon clicking on save selection
     * product is by default selected as 1 qty
     */
    if (modifierGroup && productToAdd) {
      const isProductExistInState = selectedOptions[parentProduct.id]?.[
        modifierGroup?.id
      ]?.some(p => p.id === productToAdd?.id);
      const selectedGroupOptions =
        selectedOptions[parentProduct.id]?.[modifierGroup.id] ?? [];
      //Push combo product if not exist in state
      if (!isProductExistInState) {
        selectedGroupOptions.push({
          ...productToAdd,
          quantity: 1,
        } as OptionItem);
      }
      setSelectedOptions({
        ...selectedOptions,
        [parentProduct.id]: {
          ...selectedOptions[parentProduct.id],
          [modifierGroup.id]: selectedGroupOptions,
        },
      });
    }
    onNavigateToProduct({
      groups: parentProduct.modifierGroups,
      activeGroupId: modifierGroup?.id,
    });
  };

  const isValid = useMemo(() => {
    return currentOptionGroups.every(option => isGroupWithinLimits(option));
  }, [isGroupWithinLimits, currentOptionGroups]);

  const sortedActiveOptions = useMemo(() => {
    const entity = [
      ...(activeGroup?.modifiers || []),
      ...(activeGroup?.products || []),
    ];
    return sortBy(entity, 'priority');
  }, [activeGroup?.modifiers, activeGroup?.products]);

  const selectingOptionMaps = useMemo(
    () => keyBy(selectedOptions[currentItem.id]?.[activeGroupId], 'id'),
    [activeGroupId, selectedOptions, currentItem.id],
  );

  useEffect(() => {
    if (selectedOptionGroupIdProp === undefined) return;
    setActiveGroupId(selectedOptionGroupIdProp);
  }, [selectedOptionGroupIdProp]);

  useEffect(() => {
    if (!activeGroupId || !currentOptionGroups?.length) return;
    const selectingIndex = currentOptionGroups.findIndex(
      x => x.id === activeGroupId,
    );
    flatListRef.current?.scrollToIndex({
      index: selectingIndex,
    });
  }, [activeGroupId, currentOptionGroups]);

  const isMultiSelection = useMemo(() => {
    return (activeGroup?.maxSelectionPerOption || 0) > 1;
  }, [activeGroup]);

  const setVariantOrVariantGroups = ({
    option,
    variant,
    qty = 0,
    modifierGroups = [],
    productId = '',
  }: {
    option: ComboItem | Modifier;
    variant?: VariantAsItem;
    qty?: number;
    modifierGroups?: ProductModifierGroup[];
    productId?: string;
  }) => {
    if (variant) {
      const variantProductGroups = getFilteredVariantProductGroups({
        variant,
        productId,
        modifierGroups,
        group: activeGroup,
      });

      onNavigateToProduct({
        groups: variantProductGroups,
        optionItem: option as ComboItem,
      });
    } else if (currentItem.variant) {
      const optionItems = Object.values(selectingOptionMaps);
      const numOfOptionSelected = getTotalSelection(optionItems);
      if (numOfOptionSelected && qty >= 0) return;

      const comboItem = option as ComboItem;
      const product = comboItem.productDetail;
      const productModifierGroups =
        qty === -1 ? [] : product?.modifierGroups ?? [];
      if (!!currentItem.variant) {
        const variantId = currentItem.variant.id;
        const groupIdsToRemove = (
          currentItem.variant?.products?.find(p => p.id === option.id)
            ?.productDetail?.modifierGroups || []
        ).map(g => g.id);
        const filteredCurrentOptionGroups =
          qty === -1
            ? currentOptionGroups.filter(g => !groupIdsToRemove.includes(g.id))
            : currentOptionGroups;

        setCurrentOptionGroups([
          ...filteredCurrentOptionGroups,
          ...productModifierGroups,
        ]);
        setOrRemoveVariantOptions(
          qty,
          variantId,
          productModifierGroups,
          option as ComboItem,
        );
      }
    }
  };

  const onPressProduct = (option: Modifier | ComboItem) => {
    const isValid = isOptionSelectedWithinGroupLimit();
    const selectedItem = selectingOptionMaps[option.id];
    const quantity = selectedItem?.quantity || 0;
    const updatedQty = isMultiSelection ? 1 : quantity == 1 ? -1 : 1;
    if (isComboProduct) {
      const comboItem = option as ComboItem;
      const variant = comboItem.variantDetail;
      // If we click on variant
      if (variant) {
        if (!isValid) return;
        const productId = (selectedItem as ComboItem)?.productDetail?.id || '';
        setVariantOrVariantGroups({
          option,
          variant,
          modifierGroups:
            (productAndVariantMap[productId] as Product)?.modifierGroups || [],
          productId,
        });
        return;
      }
      const productModifierGroups =
        comboItem.productDetail?.modifierGroups || [];
      if (
        productModifierGroups?.length &&
        (isMultiSelection || (!isMultiSelection && !selectedItem)) &&
        !currentItem.variant
      ) {
        if (!isValid) return;
        onNavigateToProduct({
          groups: productModifierGroups,
          optionItem: option as ComboItem,
        });
      } else {
        // For Miscellaneous option group, modifier price is null so we're getting it from pricingGroup
        const isPriceEmptyForModifier =
          !(option as ComboItem).productDetail && !option.price;
        if (isPriceEmptyForModifier) {
          option.price = {
            amount: getBestPriceOfModifier(option as Modifier),
          } as Money;
        }
        onSelectOptionItem(option, updatedQty);
      }
    } else {
      onSelectOptionItem(option, updatedQty);
    }
    /**
     * If we click on variant product
     * Upon click on variant product we need certain action:
     * Ex: reset variant products modifier selection & set default selection when select it
     */
    if (currentItem.variant) {
      setVariantOrVariantGroups({ option, qty: updatedQty });
    }
  };

  const isRootProduct = useMemo(() => {
    return parentProduct.id === currentItem.id;
  }, [parentProduct, currentItem]);

  const resetCurrentOption = () => {
    currentOptionGroups
      .find(mg => mg.id === activeGroupId)
      ?.products?.forEach(p => {
        if (selectedOptions[p.id]) delete selectedOptions[p.id];
      });

    setSelectedOptions({
      ...selectedOptions,
      [currentItem.id]: {
        ...selectedOptions[currentItem.id],
        [activeGroupId]: [],
      },
    });
  };

  const onBackPress = useCallback(() => {
    onNavigateToProduct({ groups: parentProduct.modifierGroups });
  }, [parentProduct.modifierGroups, onNavigateToProduct]);

  const isOptionSelectedWithinGroupLimit = () => {
    const selectedGroupOptions =
      selectedOptions[currentItem.id]?.[activeGroup.id] || [];

    const numOfOptionSelected = getTotalSelection(selectedGroupOptions);
    return numOfOptionSelected < activeGroup.selectionLimit.max;
  };

  /**
   *
   * @param option selected product(It can be product or modifier)
   * @param quantity
   * If option is > 1 and required modifier is assigned to product then upon click on + navigate to modifier page
   */
  const onPressIncrease = (option: ComboItem | Modifier, quantity: number) => {
    if (!isOptionSelectedWithinGroupLimit()) return;
    const selectedItem = selectingOptionMaps[option.id];
    const qty = isMultiSelection ? 1 : quantity == 1 ? -1 : 1;
    if (isMultiSelection && !selectedItem?.quantity) {
      onPressProduct(option);
    } else onSelectOptionItem(option, qty);
  };

  const isUpdateOptions = selectedOptionsProp !== undefined;

  return (
    <Modal
      visible={true}
      transparent={true}
      animationType="fade"
      onDismiss={onCancel}
      onRequestClose={onCancel}
      presentationStyle="overFullScreen"
    >
      <View style={modalStyles.background}>
        <View style={[modalStyles.container, styles.container]}>
          <View style={styles.groups}>
            <View style={styles.product}>
              {!isRootProduct && (
                <ButtonIcon
                  icon="arrow-left"
                  size={34}
                  containerStyle={styles.backIconContainerStye}
                  onPress={onBackPress}
                />
              )}
              <Text style={styles.productText}>{currentItem?.name}</Text>
            </View>
            <FlatList
              data={formattedOptionGroups}
              ref={flatListRef}
              renderItem={({ item }) => {
                const groupText = getGroupSelectionsString(item);
                return (
                  <OptionGroupTab
                    key={item?.id}
                    name={item?.name}
                    subtitle={groupText.subTitle}
                    modifiers={groupText.modifiers}
                    onPress={() => setActiveGroupId(item.id)}
                    status={
                      item?.isSelecting
                        ? 'active'
                        : !item.isRequired
                        ? 'optional'
                        : isGroupWithinLimits(item)
                        ? 'selected'
                        : 'required'
                    }
                  />
                );
              }}
              showsVerticalScrollIndicator={false}
              keyExtractor={item => item.id}
              onScrollToIndexFailed={info => {
                const wait = new Promise(resolve => setTimeout(resolve, 500));
                wait.then(() => {
                  flatListRef.current?.scrollToIndex({
                    index: info.index,
                    animated: true,
                  });
                });
              }}
            />
          </View>
          <View style={styles.body}>
            <View style={styles.optionInfoTextContainer}>
              <View
                style={[
                  styles.bodyTitle,
                  isGroupWithinLimits(activeGroup) && {
                    backgroundColor: theme.colors.greenLightest,
                  },
                ]}
              >
                <View
                  style={[
                    styles.circle,
                    isGroupWithinLimits(activeGroup) && {
                      backgroundColor: theme.colors.green,
                    },
                  ]}
                />
                <Text style={styles.bodyTitleText}>{`${
                  activeGroup?.name
                } (${getSelectionLimitString(activeGroup?.selectionLimit)}) ${
                  activeGroup?.isRequired
                    ? ''
                    : `– ${translate('modifiers.optional')}`
                }`}</Text>
                <View
                  style={[
                    styles.formattingSelection,
                    {
                      backgroundColor: isGroupWithinLimits(activeGroup)
                        ? theme.colors.green
                        : theme.colors.red,
                    },
                  ]}
                >
                  <Text style={styles.activeText}>
                    {translate('modifiers.selectedModifierOutOfTotal', {
                      selected: getTotalSelection(
                        selectedOptions[currentItem.id]?.[activeGroupId],
                      ),
                      total:
                        activeGroup?.selectionLimit?.max ||
                        DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
                    })}
                  </Text>
                </View>
              </View>
              <ButtonIcon
                icon="times-circle"
                size={38}
                containerStyle={styles.resetOptions}
                type="negativeLight"
                onPress={resetCurrentOption}
              />
            </View>
            <ScrollView style={styles.gridScroll}>
              <View style={styles.grid}>
                {sortedActiveOptions.map((option, i) => {
                  const productAsItem = (option as ComboItem).productDetail;
                  const amount =
                    (productAsItem
                      ? option?.price?.amount
                      : getBestPriceOfModifier(option as Modifier)) || 0;
                  const selectedItem = selectingOptionMaps[option.id];
                  const quantity = selectedItem?.quantity || 0;
                  const isSelected = quantity >= 1;
                  const isDefaultOptionDeselected = selectedItem?.removeDefault;

                  const color = isDefaultOptionDeselected
                    ? theme.colors.states.negative
                    : isSelected
                    ? theme.colors.states.positive
                    : undefined;

                  return (
                    <MenuItem
                      key={i}
                      name={option.name}
                      onPressDecrease={() => onSelectOptionItem(option, -1)}
                      disabled={
                        isMultiSelection &&
                        quantity === activeGroup?.maxSelectionPerOption
                      }
                      color={color}
                      quantity={selectedItem?.quantity}
                      amount={amount}
                      isMultiSelection={isMultiSelection}
                      onPress={() => onPressProduct(option)}
                      onPressIncrease={() => onPressIncrease(option, quantity)}
                    />
                  );
                })}
              </View>
            </ScrollView>
            <View style={styles.actions}>
              <TreatButton
                type="negativeLight"
                label={translate('button.cancel')}
                onPress={onCancel}
              />
              <TreatButton
                type="positiveLight"
                label={`${translate('button.next')} ->`}
                onPress={() => onPressNextGroup()}
                containerStyle={styles.nextBtn}
                disabled={!isGroupWithinLimits(activeGroup)}
              />
              <TreatButton
                type="positive"
                onPress={isRootProduct ? onPressAddToCart : onSaveSelection}
                containerStyle={styles.btnCart}
                disabled={!isValid}
                label={
                  !isRootProduct
                    ? translate('cart.saveSelections')
                    : isUpdateOptions
                    ? translate('cart.update')
                    : translate('cart.addToCart')
                }
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default RequiredOptionsModal;
