import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import {
  CreateRefundReasonInput,
  RefundReason,
} from '@oolio-group/domain/dist';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useRefundReasons } from '../../../../hooks/app/useRefundReasons';
import { pick } from 'lodash';
import theme from '../../../../common/default-theme';
import styles from './RefundReasons.styles';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

interface RefundReasonRowProps {
  index: number;
  refundReason: RefundReason;
  onChange: (
    index: number,
    id: string,
    toggleOn: boolean,
    value: string,
  ) => void;
  onDeleteRefundReason: (index: number, id: string) => void;
}

const RefundReasonRow: React.FC<RefundReasonRowProps> = ({
  refundReason,
  onDeleteRefundReason,
  onChange,
  index,
}: RefundReasonRowProps) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (refundReason.id === undefined) {
      onDeleteRefundReason(index, refundReason.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: refundReason.name,
          })}
          onConfirm={() => {
            onDeleteRefundReason(index, refundReason.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeleteRefundReason,
    translate,
    refundReason,
    index,
  ]);

  const restockSelected = refundReason?.restock || false;

  return (
    <View testID="refund-reason-row" style={theme.tables.row}>
      <InputText
        testID="refund-reason-name"
        value={refundReason.name}
        placeholder={translate('backOfficeRefundReasons.refundReasonName')}
        onChangeText={onChange.bind(
          null,
          index,
          refundReason?.id,
          restockSelected,
        )}
        containerStyle={styles.cellName}
      />
      <ButtonIcon
        testID="delete-reason"
        icon="trash-alt"
        type="negativeLight"
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, RefundReason & { isChanged: boolean }>;

export const RefundReasons: React.FC = () => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<FormState>({});
  const [newRefundReasons, setNewRefundReasons] = useState<
    CreateRefundReasonInput[]
  >([]);

  const [deletedRefundReasonId, setDeletedRefundReasonId] =
    useState<string>('');

  const {
    loading,
    error,
    refundReasons,
    getRefundReasons,
    createRefundReasons,
    createdRefundReasonIds,
    updateRefundReasons,
    updatedRefundReasonIds,
    deleteRefundReason,
    deletedRefundReason,
  } = useRefundReasons();

  const { closeModal } = useModal();

  useEffect(() => {
    getRefundReasons();
  }, [getRefundReasons]);

  useEffect(() => {
    if (refundReasons) {
      setForm(refundReasons as FormState);
    }
  }, [refundReasons]);

  useEffect(() => {
    if (deletedRefundReason && deletedRefundReasonId) {
      delete form[deletedRefundReasonId];
    }
  }, [deletedRefundReasonId, deletedRefundReason, form]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeleteRefundReason = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentRefundReasonsCount = Object.keys(form).length;
        const updatedRefundReasons = newRefundReasons;
        newRefundReasons.splice(index - currentRefundReasonsCount, 1);
        setNewRefundReasons([...updatedRefundReasons]);
      } else {
        setDeletedRefundReasonId(id);
        deleteRefundReason(id);
      }
    },
    [deleteRefundReason, newRefundReasons, form],
  );

  useEffect((): void => {
    if (deletedRefundReason) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeRefundReasons.refundReasonDeletedSuccessfully',
        ),
      });
    }
  }, [deletedRefundReason, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedRefundReasonIds && updatedRefundReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedRefundReasonIds.length > 1
              ? 'backOfficeRefundReasons.refundReasonsUpdatedSuccessfully'
              : 'backOfficeRefundReasons.refundReasonUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedRefundReasonIds, showNotification, translate]);

  useEffect((): void => {
    if (createdRefundReasonIds && createdRefundReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdRefundReasonIds.length > 1
              ? 'backOfficeRefundReasons.refundReasonsCreatedSuccessfully'
              : 'backOfficeRefundReasons.refundReasonCreatedSuccessfully'
          }`,
        ),
      });
      setNewRefundReasons([]);
    }
  }, [
    createdRefundReasonIds,
    showNotification,
    translate,
    setNewRefundReasons,
  ]);

  const onChangeReason = useCallback(
    (index: number, id: string, toggleOn: boolean, value: string): void => {
      if (!id) {
        const currentRefundReasonsCount = Object.keys(form).length;
        const updatedRefundReasons = newRefundReasons;
        updatedRefundReasons[index - currentRefundReasonsCount] = {
          name: value,
          restock: toggleOn,
        };
        setNewRefundReasons([...updatedRefundReasons]);
      } else {
        setForm(form => ({
          ...form,
          [id]: {
            ...form[id],
            name: value,
            restock: toggleOn,
            isChanged: true,
          },
        }));
      }
    },
    [form, newRefundReasons],
  );

  const onPressSave = useCallback((): void => {
    const refundReasonsToUpdate = Object.values(form)
      .filter(refundReason => refundReason.isChanged)
      .map(refundReason => pick(refundReason, ['id', 'name', 'restock']));

    if (
      newRefundReasons.some(
        refundReason => !(refundReason?.name || '').trim(),
      ) ||
      refundReasonsToUpdate.some(
        refundReason => !(refundReason?.name || '').trim(),
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeRefundReasons.enterRefundReasonName'),
      });
    } else {
      if (newRefundReasons.length > 0) createRefundReasons(newRefundReasons);
      if (refundReasonsToUpdate.length > 0)
        updateRefundReasons(refundReasonsToUpdate);
    }
  }, [
    createRefundReasons,
    updateRefundReasons,
    newRefundReasons,
    showNotification,
    translate,
    form,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewRefundReasons([...newRefundReasons, {} as CreateRefundReasonInput]);
  }, [newRefundReasons]);

  const refundReasonsData = [
    ...Object.values(form),
    ...(newRefundReasons as unknown as RefundReason[]),
  ];

  return (
    <ScreenLayout
      title="Refund Reasons | Oolio"
      loading={loading}
      onSave={onPressSave}
    >
      <Section
        layoutWidth="small"
        title={translate('backOfficeRefundReasons.refundReasons')}
        subtitle={translate('backOfficeRefundReasons.refundReasonsDescription')}
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeRefundReasons.refundReasonName')}
            </Text>
          </View>
          <View>
            {refundReasonsData.map((reason: RefundReason, i: number) => (
              <RefundReasonRow
                key={i}
                index={i}
                refundReason={reason}
                onChange={onChangeReason}
                onDeleteRefundReason={onDeleteRefundReason}
              />
            ))}
          </View>
        </View>
        <CreateButton onPress={onPressCreateNew} />
      </Section>
    </ScreenLayout>
  );
};

export default RefundReasons;
