import React from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import styles from './SectionTabs.styles';
import theme from '../../../common/default-theme';

export interface SectionTab {
  label: string;
  value: string;
}

export interface SectionTabsProps {
  testID?: string;
  selectedTab: string;
  tabs: Array<SectionTab>;
  containerStyle?: ViewStyle;
  onPress: (value: string) => void;
}

const SectionTabs: React.FC<SectionTabsProps> = ({
  selectedTab,
  tabs,
  testID,
  containerStyle,
  onPress,
}) => {
  return (
    <View testID={testID} style={[styles.container, containerStyle]}>
      {tabs.map((tab: SectionTab, i: number) => {
        const isSelected = tab.value === selectedTab;
        return (
          <TouchableOpacity
            key={i}
            onPress={() => onPress(tab.value)}
            style={[styles.tab, isSelected && styles.activeTab]}
          >
            <Text
              style={[
                styles.text,
                {
                  color: isSelected ? theme.colors.white : theme.colors.dark,
                },
              ]}
            >
              {tab.label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default SectionTabs;
