import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Gradient from '../../components/Gradient/Gradient';
import theme from '../../common/default-theme';
import { NotificationList } from '../../components/Notification/NotificationList';
import {
  useNotification,
  useNotificationsList,
} from '../../hooks/Notification';

const styles = StyleSheet.create({
  screenContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    padding: 40,
    borderRadius: 6,
    alignItems: 'center',
    ...theme.shadow.strong,
    backgroundColor: theme.colors.white,
  },
});

export const LoadingScreen: React.FC = () => {
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();
  return (
    <Gradient style={styles.screenContainer}>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
      <View style={styles.modalContainer}>
        <ActivityIndicator
          size="large"
          testID="loading-spinner"
          color={theme.colors.primary}
        />
      </View>
    </Gradient>
  );
};

export default LoadingScreen;
