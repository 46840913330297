import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  headerContainer: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
  },
  lhs: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rhs: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  breadcrumbContainer: {
    flexDirection: 'row',
    marginLeft: 16,
  },
  buttonContainer: {
    height: 38,
    marginLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  buttonText: {
    paddingLeft: 20,
    paddingRight: 8,
    textTransform: 'uppercase',
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
  btnWhatsNew: {
    marginLeft: 6,
  },
});

export default styles;
