import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  AssignCustomerEvent,
  OrderAction,
  OrderItem,
  OrderPaymentEvent,
  OrderStatus,
  UpdateOrderItemPriceEvent,
  Product as ProductAlias,
  FunctionMapActions,
  ShiftStatus,
  OrderItemModifier,
  DEVICE_PRICING_GROUP_ID,
  FUNCTION_MAPS_FOR_ORDER_COMPLETED,
  VoidOrderEvent,
  VoidReason,
  Order,
  OrderItemStatus,
  OrderType,
  OrderTypeCode,
  RemoveOrderItemEvent,
  AddOrderSurcharge,
  AdjustmentType,
  CUSTOM_ADJUSTMENT_ID,
  AdjustmentUnit,
  Adjustment,
  Resource,
  USER_EXPLICIT_SELECT_PRICING_GROUP,
  OrderPaymentStatus,
  FeatureIDs,
  LoyaltySettings,
  RewardRule,
  EarningRule,
  DefaultPaymentTypes,
  UpdateCustomerEvent,
  LoyaltyEnrolmentSource,
  OrderIdentifier,
  DEFAULT_ENTITY_ID,
  AdditionalPaymentInfo,
  FunctionAction,
  ADJUSTMENT_STATIC_NAME,
  UpdateOrderAdjustmentsEvent,
  Page,
  OrderEvent,
  RewardAdjustment,
  RemoveRewardItemEvent,
  RemoveOrderAdjustments,
  AdjustmentByOrderItemMap,
} from '@oolio-group/domain';
import {
  Customer,
  PaymentType,
  ReceiptPrintOption,
  Variant,
  Product,
  Catalogue,
  NotificationType,
  NotificationMode,
} from '@oolio-group/domain';
import { v4 as uuidv4 } from 'uuid';
import { useSyncOrderEvents } from '../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../state/userUtility';

import { useModal } from '@oolio-group/rn-use-modal';
import {
  useCurrency,
  useTranslation,
  getCountry,
  useLocalization,
} from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';
import { isEmpty, keyBy, map } from 'lodash';
import { CartSelectionState } from '../../components/POS/Cart/Cart';
import { CancelOrderItemModalMap } from '../../components/Modals/FunctionMaps/CancelOrderItem';
import SendOrderReceiptModal from '../../components/Modals/SendOrderReceipt/SendOrderReceiptModal';
import { AppScreen } from '../../types/AppScreen';
import { VoidOrderModal } from '../../components/POS/Modals/VoidOrder/VoidOrder';
import {
  adjustAdjustments,
  SetPriceModalMap,
  SET_PRICE_OPTIONS,
} from '../../components/Modals/FunctionMaps/SetPrice';
import { CashPaymentModal } from '../../components/Modals/FunctionMaps/Payment';
import AddCustomer from '../../components/Modals/Customer/AddCustomer';
import { PickerModal } from '../../components/Modals/Picker/Picker';
import { useSession } from '../../hooks/app/useSession';
import * as storage from '../../storage/interface';
import { useOrders } from '../../hooks/app/orders/useOrders';
import AddMoneyEvent from '../../components/POS/Modals/AddMoneyEvent/AddMoneyEvent';
import { useShifts } from '../../hooks/app/useShifts';
import CloseShift from '../../components/POS/Modals/Shifts/CloseShift/CloseShift';
import CloseShiftWarning from '../../components/POS/Modals/Shifts/CloseShiftWarning/CloseShiftWarning';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import { useNotification } from '../../hooks/Notification';
import { usePrinting } from '../../hooks/PrintingProvider';
import {
  computeLineTotal,
  getScheduleAdjustments,
  limitDecimalCount,
  sumDecimals,
} from '@oolio-group/order-helper';
import { useLogout } from '../../hooks/app/useLogout';
import { useNetInfo } from '@react-native-community/netinfo';
import { isErrorForDeviceOffline } from '../../utils/validator';
import PaymentSurcharge from '../../components/POS/Modals/PaymentSurcharge/PaymentSurcharge';
import { getAdjustmentValue } from '@oolio-group/order-helper';
import { useAdjustmentSchedule } from '../../hooks/app/useAdjustmentSchedule';

// helpers
import {
  cloneJSON,
  roundOffByValue,
  getAllRedeemedRewards,
  calculatePointsEarnedForOrder,
  getOpenDaysForShift,
} from '@oolio-group/client-utils';
import { getRoundOffValue } from '../../utils/roundOffHelper';
import ItemAvailability, {
  ProductInventory,
} from '../../../src/components/Modals/ItemAvailability/ItemAvailability';
import PermissionsModal from './../../components/Modals/Users/PermissionsModal';
import usePOSUserAuthorization from '../app/users/usePOSUserAuthorization';
import {
  inventoryProductFragment,
  useProducts,
} from '../app/products/useProducts';
import { productQuantitiesToReturnOnVoidOrder } from '../../../src/utils/OpenOrdersHelper';
import SaleCompleteModal, {
  sendReceiptInput,
} from '../../components/POS/Modals/SaleComplete/SaleCompleteModal';
import OnHoldOrderOptions from '../../components/POS/PaymentsScreen/OnHoldPaymentActions';
import { replaceFunctionMapCurrency } from '../../utils/replaceFunctionMapCurrency';
import { ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER } from '../../types/Common';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';
import CustomerRewardModal from '../../components/Modals/CustomerLoyalty/CustomerRewardModal';
import { UseRewardsInterface, useRewards } from './useRewards';
import { changeDueVar } from '../../App';
import { useCardPayFunctionMap } from './useCardPayFunctionMap';
import { useCustomers } from './useCustomers';
import { Option } from '../../components/Shared/Select/Select';
import { SwitchCourse } from '../../components/Modals/SwitchCourse/SwitchCourseModal';
import { useSyncItemAvailability } from '../app/orders/useSyncItemAvailability';
import { IMap } from '../../screens/BackOffice/Reports/types';
import { PriceListModal } from '../../components/Modals/FunctionMaps/PriceList';
import ClosePreviousShiftWarning from '../../components/POS/Modals/Shifts/ClosePreviousShiftWarning/ClosePreviousShiftWarning';
import { useReportSettings } from '../app/useReportSettings';
import { OPEN_DAYS_FOR_SHIFT } from '../../state/preferences';
import { ApplySurcharge } from '../../components/Modals/Surcharge/ApplySurcharge';
import { AppliedAdjustment } from '../../state/Session';
import { useSettings } from '../../hooks/app/useSettings';
import { useCart } from './useCart';
import { mapLoyaltySnapshot, useOolioLoyalty } from '../useOolioLoyalty';
import { EnrollmentSource } from '@oolio-group/loyalty-sdk';

const CASH_PAYMENT_TYPE = 'cash';
const READ_ONLY_STATUSES = [
  OrderStatus.COMPLETED,
  OrderStatus.REFUNDED,
  OrderStatus.VOID,
];

enum ADJUSTMENT_TITLE {
  DISCOUNTS = 'DISCOUNTS',
  SURCHARGES = 'SURCHARGES',
}

export interface UseFunctionMapsProps {
  orderItems: {
    unitPrice: number;
    product: ProductAlias;
    variant: Variant;
    id: string;
    quantity: number;
    status: OrderItemStatus;
    notes: string;
    modifiers: OrderItemModifier[];
    taxInclusive: boolean;
    costPrice: number;
  }[];
  selectedCartItem: CartSelectionState | undefined;
  escapeDiscardModal: { current: boolean };
  advancedKeypadValue: number;
  pricingGroup: string;
  orderType: OrderType | undefined;
  orderTypes: OrderType[] | undefined;
  assignedCustomer: Customer | undefined;
  pricingGroupOptions: {
    label: string;
    value: string;
  }[];
  courseOptions: Option[];
  isOrderComplete: boolean;
  menus: {
    id: string;
    name: string;
  }[];
  selectCartItem: (value: CartSelectionState | undefined) => void;
  setAdvancedKeypadValue: (value: number) => void;
  setPricingGroup: (value: string) => void;
  onPressUpdateOrderNotes: () => void | Promise<void>;
  onPressTransferItems: () => void | Promise<void>;
  productsMap: Record<string, Product>;
  onPressNewOrder: () => void;
  onPressOpenTable: () => void;
  openTableNumberModal: (callback?: () => void) => void;
  unselectCartItem: () => void;
  areCartItemsValid: (errMsg: string) => boolean;
  checkForUnfiredItems: (callback: () => void) => void;
  isValidTotalPriceBeforeRemoveItem: (quantityToBeKept?: number) => boolean;
  navigateToPostSaleScreen: () => void;
  onRewardRedeem: ReturnType<UseRewardsInterface>['redeemRewards'];
  loyaltySettings: Partial<LoyaltySettings>;
  rewardRules: RewardRule[];
  earningRules: EarningRule[];
  updateOrderNameModal: (callback?: () => void) => void;
  onSwitchCourseItem: (orderItemId: string, courseId: string) => void;
  pages: Page[];
  onChangeProductQuantity: (
    productId: string | undefined,
    quantity: number,
  ) => void;
  computeRestrictVoidItem: (order?: Order, item?: OrderItem) => void;
  onAddVoucher: () => void | Promise<void>;
  isAdvanceDiscountEnabled: boolean;
  adjustmentParams: {
    setRemovedScheduleAdjustmentIds: (
      type?: AdjustmentType,
      excludeIds?: string[],
    ) => void;
    appliedManualAdjustmentIdRef: React.MutableRefObject<string>;
    resetManualAdjustmentId: (
      adjustmentByOrderItem: AdjustmentByOrderItemMap,
    ) => void;
  };
}

export const getFunctionMapName = (
  name: string,
  translate: (x: string) => string,
) => {
  switch (name) {
    case 'MANAGER FUNCTIONS':
      return translate('functionMaps.managerFunctions');
    case '$1 SURCHARGE':
      return translate('functionMaps.oneDollorSurcharge');
    case 'MONEY IN/OUT':
      return translate('functionMaps.moneyInOrOut');
    case 'SWITCH MENU':
      return translate('functionMaps.switchMenu');
    case 'CUSTOM DISCOUNT $':
      return translate('functionMaps.customDiscount');
    case 'SYSTEM LOGOUT':
      return translate('functionMaps.systemLogout');
    case 'PRINT OPTIONS':
      return translate('functionMaps.printOptions');
    case 'OPEN TABLE':
      return translate('functionMaps.openTable');
    case 'CUSTOM SURCHARGE %':
      return translate('functionMaps.customSurchargePercent');
    case 'OPEN DRAWER':
      return translate('functionMaps.openDrawer');
    case 'SCHEDULED ADJUSTMENTS':
      return translate('functionMaps.scheduledSurcharge');
    case '2.5% SURCHARGE':
      return translate('functionMaps.twoPointFiveSurcharge');
    case '$1.5 SURCHARGE':
      return translate('functionMaps.onePointFiveSurcharge');
    case '$2 SURCHARGE':
      return translate('functionMaps.twoDollorSurcharge');
    case 'VOID':
      return translate('functionMaps.void');
    case '5% OFF':
      return translate('functionMaps.fivePerCentOff');
    case 'SWITCH PRICING GRP':
      return translate('functionMaps.switchPricingGroup');
    case 'CASH PAY':
      return translate('functionMaps.cashPay');
    case 'CARD PAY':
      return translate('functionMaps.cardPay');
    case 'ADD NOTE':
      return translate('functionMaps.addNote');
    case 'PRINT SHIFT SUMMARY':
      return translate('functionMaps.printShiftSummary');
    case 'REWARDS':
      return translate('functionMaps.rewards');
    case 'CUSTOM DISCOUNT %':
      return translate('functionMaps.customDiscountPercent');
    case 'SEND RECEIPT':
      return translate('functionMaps.sendReceipt');
    case '10% OFF':
      return translate('functionMaps.tenPerCentOff');
    case '$10 OFF':
      return translate('functionMaps.tenDollorOff');
    case 'PRINT PREV RECEIPT':
      return translate('functionMaps.printPrevReceipt');
    case 'CUSTOM SURCHARGE $':
      return translate('functionMaps.customSurchargeDollor');
    case '1.9% SURCHARGE':
      return translate('functionMaps.onePointNineSurchange');
    case '$5 OFF':
      return translate('functionMaps.fiveDollorOff');
    case 'EXACT CASH':
      return translate('functionMaps.exactCash');
    case '2% SURCHARGE':
      return translate('functionMaps.twoPerCentSurcharge');
    case '100% OFF':
      return translate('functionMaps.hunderedPerCentOff');
    case 'SURCHARGES':
      return translate('functionMaps.surcharges');
    case 'END SHIFT':
      return translate('functionMaps.endShift');
    case 'ITEM AVAILABILITY':
      return translate('functionMaps.itemAvailabilityTile');
    case 'DISCOUNTS':
      return translate('functionMaps.discounts');
    case 'RESEND TO KITCHEN':
      return translate('functionMaps.resendToKitchen');
    case '$2 OFF':
      return translate('functionMaps.twoDollorOff');
    case 'SWITCH COURSE':
      return translate('functionMaps.switchCourse');
    case 'EMAIL RECEIPT':
      return translate('functionMaps.emailReceipt');
    case 'ADD VOUCHER':
      return translate('functionMaps.addVoucher');
    case 'TRANSFER':
      return translate('functionMaps.transfer');
    default:
      return name;
  }
};

export const translateFunctionMaps = (
  actions: FunctionAction[],
  translate: (x: string) => string,
  locale?: string,
) => {
  if (!locale) {
    return actions;
  }

  return actions.map(x => ({
    ...x,
    name: getFunctionMapName(x.name, translate),
  }));
};

export const useFunctionMaps = (
  orderItems: UseFunctionMapsProps['orderItems'],
  selectCartItem: UseFunctionMapsProps['selectCartItem'],
  selectedCartItem: UseFunctionMapsProps['selectedCartItem'],
  escapeDiscardModal: UseFunctionMapsProps['escapeDiscardModal'],
  advancedKeypadValue: UseFunctionMapsProps['advancedKeypadValue'],
  pricingGroup: UseFunctionMapsProps['pricingGroup'],
  pricingGroupOptions: UseFunctionMapsProps['pricingGroupOptions'],
  isOrderComplete: UseFunctionMapsProps['isOrderComplete'],
  menus: UseFunctionMapsProps['menus'],
  setAdvancedKeypadValue: UseFunctionMapsProps['setAdvancedKeypadValue'],
  setPricingGroup: UseFunctionMapsProps['setPricingGroup'],
  onPressUpdateOrderNotes: UseFunctionMapsProps['onPressUpdateOrderNotes'],
  onPressTransferItems: UseFunctionMapsProps['onPressTransferItems'],
  productsMap: UseFunctionMapsProps['productsMap'],
  onPressNewOrder: UseFunctionMapsProps['onPressNewOrder'],
  onPressOpenTable: UseFunctionMapsProps['onPressOpenTable'],
  orderType: UseFunctionMapsProps['orderType'],
  openTableNumberModal: UseFunctionMapsProps['openTableNumberModal'],
  unselectCartItem: UseFunctionMapsProps['unselectCartItem'],
  areCartItemsValid: UseFunctionMapsProps['areCartItemsValid'],
  checkForUnfiredItems: UseFunctionMapsProps['checkForUnfiredItems'],
  isValidTotalPriceBeforeRemoveItem: UseFunctionMapsProps['isValidTotalPriceBeforeRemoveItem'],
  navigateToPostSaleScreen: UseFunctionMapsProps['navigateToPostSaleScreen'],
  assignedCustomer: UseFunctionMapsProps['assignedCustomer'],
  onRewardRedeem: UseFunctionMapsProps['onRewardRedeem'],
  loyaltySettings: UseFunctionMapsProps['loyaltySettings'],
  rewardRules: UseFunctionMapsProps['rewardRules'],
  earningRules: UseFunctionMapsProps['earningRules'],
  updateOrderNameModal: UseFunctionMapsProps['updateOrderNameModal'],
  courseOptions: UseFunctionMapsProps['courseOptions'],
  onSwitchCourseItem: UseFunctionMapsProps['onSwitchCourseItem'],
  pages: UseFunctionMapsProps['pages'],
  onChangeProductQuantity: UseFunctionMapsProps['onChangeProductQuantity'],
  computeRestrictVoidItem: UseFunctionMapsProps['computeRestrictVoidItem'],
  onAddVoucher: UseFunctionMapsProps['onAddVoucher'],
  isAdvanceDiscountEnabled: UseFunctionMapsProps['isAdvanceDiscountEnabled'],
  adjustmentParams: {
    setRemovedScheduleAdjustmentIds: (
      type?: AdjustmentType,
      filteredRemovedAdjustmentId?: string[],
    ) => void;
    appliedManualAdjustmentIdRef: React.MutableRefObject<string>;
    resetManualAdjustmentId: (
      adjustmentByOrderItem: AdjustmentByOrderItemMap,
    ) => void;
  },
) => {
  const { canI } = usePOSUserAuthorization();
  const [session, setSession] = useSession();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { formatCurrency } = useCurrency();
  const { locale } = useLocalization();
  const { syncItemAvailability } = useSyncItemAvailability();
  const { showNotification } = useNotification();
  const { dateTime, getDateTime } = useReportSettings();
  const forceCloseShiftRef = useRef<boolean>(false);
  const previousShiftModelTriggeredRef = useRef<boolean>(false);
  const [localSurcharges, setLocalSurcharges] = useSettings<
    AppliedAdjustment[] | undefined
  >('localSurcharges');
  const handleSendSuccess = useCallback(() => {
    showNotification({
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
      success: true,
    });
  }, [showNotification, translate]);
  const handleSendFailed = useCallback(() => {
    showNotification({
      message: translate('common.sendSaleReceiptToCustomerFailed'),
      error: true,
    });
  }, [showNotification, translate]);
  const { syncOrderEvents } = useSyncOrderEvents(
    handleSendSuccess,
    handleSendFailed,
  );
  const currentStoreId = session.currentStore?.id ?? '';
  const menuId = session?.deviceProfile?.menu?.id;

  const { logout } = useLogout();

  const {
    order,
    updateCart,
    discardChanges,
    resetCart,
    setCartParams,
    closeOrderCart,
    openOrderCart,
    addEventsToCart,
  } = useCart();

  const orderId = order?.id;
  const {
    printBill,
    printShiftReceipt,
    reprintKitchenDocket,
    openCashDrawer,
    printMoneyMovementReceipt,
  } = usePrinting();

  const { shifts, getShifts, error, loading: shiftLoading } = useShifts();

  const closeShiftClicked = useRef<boolean>(false);
  const printShiftClicked = useRef<boolean>(false);
  const selectedAdjustmentRef = useRef<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const storeInventoryRef = useRef<IMap<ProductInventory>>({});
  const { applyAdvancedDiscounts } = useAdjustmentSchedule();
  const netInfo = useNetInfo();
  const { postSaleScreen, receiptPrintOption } = session.deviceProfile || {};
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;
  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );
  const enableQuickPaymentMode: boolean =
    session?.settings?.enableQuickPaymentModeSetting || false;

  const { paymentTypes } = usePaymentTypes({ fetchPolicy: 'cache-first' });

  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLegacyLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { isLoyaltyEnabled: isNewLoyaltyEnabled, estimateSnapshot } =
    useOolioLoyalty();
  const isLoyaltyEnabled = isNewLoyaltyEnabled || isLegacyLoyaltyEnabled;
  const isOrderOnHold = order?.status === OrderStatus.ON_HOLD;

  const processingPaymentAmount = order?.amountDue || 0;

  const {
    orders,
    getOrdersFromCache,
    getOrderFromCache,
    getLatestCompletedOrderByDevice,
    returnOrdersFromCache,
  } = useOrders();
  const { getProductsFromCache, updateCacheProductQuantities } = useProducts(
    undefined,
    inventoryProductFragment,
  );

  const {
    enrollCustomerLoyalty,
    loading: enrolling,
    updateCustomerInfo: updateCustomerCache,
    enrollMemberLoyalty,
  } = useCustomers();

  const { updateLoyaltySnapshot, calculateAndUpdateLoyaltySnapshot } =
    useRewards(rewardRules);

  const refetchOrdersFromCache = useCallback(() => {
    getOrdersFromCache(OrderStatus.IN_PROGRESS);
    getOrdersFromCache(OrderStatus.COMPLETED);
  }, [getOrdersFromCache]);

  useEffect(() => {
    refetchOrdersFromCache();
  }, [refetchOrdersFromCache]);

  const ordersArray = useMemo(() => {
    const onlineOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS, true);
    return [...Object.values(orders), ...onlineOrders];
  }, [orders, returnOrdersFromCache]);

  const { appendCurrency, currencySymbol } = useCurrency();

  const [functionMap, setFunctionMap] = useState('');
  const [localAppliedAdjustments, setLocalAppliedAdjustments] = useState<
    AppliedAdjustment[]
  >(localSurcharges || []);

  const isZeroAmountDue = useCallback(
    (order?: Order) => {
      // if there are any payments and the amount due is 0 then we can not pay , we can complete order
      if (order && order?.payments?.length && order?.amountDue === 0) {
        showNotification({
          message: translate('payment.payZeroError'),
          error: true,
        });
        return true;
      }
      return false;
    },
    [showNotification, translate],
  );

  const navigateToDefaultScreen = useCallback(async () => {
    const screen: AppScreen = postSaleScreen
      ? AppScreen[postSaleScreen]
      : AppScreen.NEW_ORDER;
    if (screen && screen == AppScreen.NEW_ORDER) {
      await onPressNewOrder();
    } else {
      navigateToPostSaleScreen();
    }
  }, [postSaleScreen, onPressNewOrder, navigateToPostSaleScreen]);

  const assignCustomerToOrder = useCallback(
    (customer: Customer): void => {
      // trigger assign order to customer event
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: Boolean(
          isLegacyLoyaltyEnabled && customer?.loyaltyMember,
        ),
      });
    },
    [updateCart, isLegacyLoyaltyEnabled],
  );

  const onCancelOrder = useCallback(
    async (reason: VoidReason, description: string, order: Order) => {
      // Discarding all unsaved events in local, as order is getting "VOIDED".
      const allProducts = cloneJSON(keyBy(getProductsFromCache(), 'id'));
      const productQuantities = productQuantitiesToReturnOnVoidOrder(
        allProducts,
        order,
        currentStoreId,
      );

      updateCacheProductQuantities(productQuantities, currentStoreId);

      discardChanges();

      updateCart<VoidOrderEvent>(OrderAction.ORDER_VOID, {
        reason,
        description,
        productQuantities: Object.keys(productQuantities).map(key => {
          return { id: key, quantity: productQuantities[key] };
        }),
        inventoryTracked: !!Object.keys(productQuantities).length,
      });
      syncItemAvailability();
      closeOrderCart();
      onPressNewOrder();
    },
    [
      getProductsFromCache,
      currentStoreId,
      updateCacheProductQuantities,
      discardChanges,
      updateCart,
      syncItemAvailability,
      closeOrderCart,
      onPressNewOrder,
    ],
  );

  const removeCartItem = useCallback(
    (item: OrderItem, reason: VoidReason) => {
      const product = item.product;
      const variant = item.variant;

      if (isLoyaltyEnabled && assignedCustomer?.loyaltyMember) {
        const rewardItems = (item?.adjustments || []).filter(
          adj => adj.adjustmentType == AdjustmentType.REWARD,
        ) as RewardAdjustment[];

        if (rewardItems?.length) {
          rewardItems.forEach(reward => {
            updateCart<RemoveRewardItemEvent>(OrderAction.ORDER_REWARD_REMOVE, {
              rewardId: reward.id,
              productId: item.product.id,
            });
          });
        }
      }

      updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
        orderItemId: item.id,
        productId: product.id || variant.id,
        quantity: -item.quantity,
        inventoryTracked: Boolean(product.storesInventory?.[currentStoreId]),
        reason,
      });

      unselectCartItem();
      onChangeProductQuantity(product.id, -item.quantity);
    },
    [
      isLoyaltyEnabled,
      assignedCustomer?.loyaltyMember,
      updateCart,
      currentStoreId,
      unselectCartItem,
      onChangeProductQuantity,
    ],
  );

  const onPressVoidSale = useCallback(() => {
    const selectedOrderItem = orderItems.find(
      item => item.id === selectedCartItem?.item,
    );

    if (
      selectedOrderItem &&
      selectedOrderItem?.status === OrderItemStatus.IN_PROGRESS
    ) {
      const allowVoidItem = canI([{ onResource: Resource.VOID_ORDER_ITEMS }], {
        prompt: true,
      });
      if (!allowVoidItem) return;
      if (selectedOrderItem.unitPrice < 1) {
        if (!isValidTotalPriceBeforeRemoveItem()) {
          showNotification({
            message: translate('payment.amountCannotBeLessThanRemaining'),
            error: true,
          });
          return;
        }
      }

      if (
        Boolean(
          computeRestrictVoidItem(
            order,
            selectedOrderItem as unknown as OrderItem,
          ),
        )
      ) {
        showNotification({
          error: true,
          message: translate('order.voidPartiallyPaid'),
        });
        return;
      }
      showModal(
        <CancelOrderItemModalMap
          orderId={order?.id || ''}
          item={selectedOrderItem as unknown as OrderItem}
          onSubmit={(orderItem: OrderItem, reason: VoidReason) =>
            removeCartItem(orderItem as unknown as OrderItem, reason)
          }
        />,
      );
    } else if (
      selectedOrderItem?.status === OrderItemStatus.CREATED ||
      selectedOrderItem?.status === OrderItemStatus.ON_HOLD ||
      order?.status === OrderStatus.CREATED
    ) {
      return showNotification({
        error: true,
        message: translate('order.canNotVoidOrder'),
      });
    } else {
      const isAnyPaymentCompleted = (order?.payments || []).some(
        x => x.status == OrderPaymentStatus.COMPLETE && !x.isPrePayment,
      );
      if (isAnyPaymentCompleted) {
        return showNotification({
          error: true,
          message: translate('order.voidPartiallyPaid'),
        });
      }

      const allowVoidOrder = canI([{ onResource: Resource.VOID_ORDERS }], {
        prompt: true,
      });
      if (!allowVoidOrder) return;
      showModal(
        <VoidOrderModal order={order as Order} onCancel={onCancelOrder} />,
      );
    }
  }, [
    orderItems,
    order,
    selectedCartItem?.item,
    canI,
    computeRestrictVoidItem,
    showModal,
    isValidTotalPriceBeforeRemoveItem,
    showNotification,
    translate,
    removeCartItem,
    onCancelOrder,
  ]);

  const refetchActiveShift = useCallback(() => {
    getShifts({ status: ShiftStatus.OPEN });
  }, [getShifts]);

  const closeActiveShift = useCallback(
    (openDaysForShift?: number) => {
      const openShift = shifts?.[0];
      const closeShift = (): void => {
        if (openShift) {
          const showViewClosureDifferences = canI(
            [{ onResource: Resource.VIEW_CLOSURE_DIFFERENCES }],
            {
              prompt: false,
            },
          );
          showModal(
            <CloseShift
              data={openShift}
              showViewClosureDifferences={showViewClosureDifferences}
              printShiftReceipt={printShiftReceipt}
              openCashDrawer={openCashDrawer}
              forceCloseShiftRef={forceCloseShiftRef}
              openDaysForShift={openDaysForShift}
            />,
          );
        } else {
          showNotification({
            info: true,
            message: translate('shift.noActiveShifts'),
          });
        }
      };

      closeShiftClicked.current = false;
      const filteredOrders = ordersArray.filter(
        x => x.status === OrderStatus.IN_PROGRESS,
      );

      if (filteredOrders.length) {
        showModal(
          <CloseShiftWarning
            onClose={closeShift}
            orders={filteredOrders}
            forceCloseShift={forceCloseShiftRef.current}
          />,
        );
      } else closeShift();
    },
    [
      openCashDrawer,
      ordersArray,
      printShiftReceipt,
      shifts,
      showModal,
      showNotification,
      translate,
      canI,
    ],
  );

  useEffect(() => {
    const hasAccess = canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }]);
    if (hasAccess) {
      getDateTime();
      getShifts({ status: ShiftStatus.OPEN });
    }
  }, [getDateTime, getShifts, canI]);

  const renderPreviousDayOpenShiftModel = useCallback(
    (openDaysForShift: number) => {
      showModal(
        <ClosePreviousShiftWarning
          closeShift={closeActiveShift}
          openDaysForShift={openDaysForShift}
          forceCloseShiftRef={forceCloseShiftRef}
        />,
      );
    },
    [showModal, closeActiveShift],
  );

  useEffect(() => {
    const getAndRenderPreviousDayShiftModel = async () => {
      if (shifts?.[0] && dateTime && !previousShiftModelTriggeredRef.current) {
        previousShiftModelTriggeredRef.current = true;
        const shiftOpenDays = getOpenDaysForShift(
          shifts?.[0].createdAt,
          dateTime.startTime,
        );
        const shiftOpenDaysStorage =
          (await storage.getItem<number>(OPEN_DAYS_FOR_SHIFT)) ?? 0;
        if (shiftOpenDays > 0 && shiftOpenDaysStorage !== shiftOpenDays) {
          renderPreviousDayOpenShiftModel(shiftOpenDays);
        }
      }
    };
    getAndRenderPreviousDayShiftModel();
  }, [dateTime, shifts, renderPreviousDayOpenShiftModel]);

  const printActiveShift = useCallback(() => {
    const openShift = shifts?.[0];

    if (openShift) {
      printShiftReceipt(openShift);
    } else {
      showNotification({
        info: true,
        message: translate('shift.noActiveShifts'),
      });
    }

    printShiftClicked.current = false;
  }, [printShiftReceipt, shifts, showNotification, translate]);

  useEffect(() => {
    if (!shiftLoading && shifts) {
      if (error) {
        const errorMessage = isErrorForDeviceOffline(error)
          ? translate('shift.printShiftOfflineErrorMessage')
          : error;
        showNotification({
          error: true,
          message: errorMessage,
        });
      } else {
        if (closeShiftClicked.current) closeActiveShift();
        if (printShiftClicked.current) printActiveShift();
      }
    }
  }, [
    closeActiveShift,
    error,
    printActiveShift,
    shiftLoading,
    shifts,
    showNotification,
    translate,
  ]);

  const onPressEndShift = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    /**
     * Calling refetch shifts to get shifts data.
     * Also, we refetch orders from cache to look for any open orders.
     * Once we get shifts we are doing closeShift action in useEffect.
     */
    refetchOrdersFromCache();
    refetchActiveShift();
    setTimeout(() => {
      closeShiftClicked.current = true;
    }, 200);
  }, [canI, refetchOrdersFromCache, refetchActiveShift]);

  const onPrintShift = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.PRINT_SHIFT_SUMMARY }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    refetchActiveShift();
    setTimeout(() => {
      printShiftClicked.current = true;
    }, 200);
  }, [refetchActiveShift, canI]);

  const getPaymentTypeByName = useCallback(
    (name: string): PaymentType => {
      return paymentTypes.find(
        (paymentType: { name: string }) =>
          paymentType?.name.toLowerCase() == name.toLowerCase(),
      ) as PaymentType;
    },
    [paymentTypes],
  );

  const onPressAddNewCustomer = useCallback(() => {
    showModal(
      <AddCustomer
        orderId={orderId}
        assignCustomerToOrder={assignCustomerToOrder}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, closeModal, orderId, assignCustomerToOrder]);

  const isExistingAdjustment = useCallback(
    (adjustment: Adjustment) => {
      const isAdjustmentApplied = order?.adjustments?.some(
        a => adjustment.id !== CUSTOM_ADJUSTMENT_ID && a.id === adjustment.id,
      );
      return isAdjustmentApplied;
    },
    [order?.adjustments],
  );

  const removeOrderAdjustments = useCallback(
    (type: AdjustmentType) => {
      const isPartiallyPaid = order?.payments?.some(
        p => p.status === OrderPaymentStatus.COMPLETE && !p.isPrePayment,
      );
      if (isPartiallyPaid) {
        showNotification({
          message: translate('adjustment.adjustmentRemoveErrorMessage'),
          error: true,
        });
        return;
      }

      const canRemoveItemAdjustments = (order?.orderItems ?? []).some(
        (item: OrderItem) => {
          return (item?.adjustments ?? []).some(
            (a: Adjustment) => a.adjustmentType === type,
          );
        },
      );

      if (
        (!canRemoveItemAdjustments &&
          !order?.adjustments?.find(a => a.adjustmentType === type)) ||
        order?.status === OrderStatus.COMPLETED
      ) {
        return;
      }
      adjustmentParams.setRemovedScheduleAdjustmentIds(type);
      updateCart<RemoveOrderAdjustments>(OrderAction.ORDER_REMOVE_ADJUSTMENTS, {
        adjustmentType: type,
      });
      showNotification({
        message: translate('adjustment.allAdjustmentRemoved', {
          type: type === AdjustmentType.DISCOUNT ? 'discounts' : 'surcharges',
        }),
        info: true,
      });
    },
    [
      order?.payments,
      order?.orderItems,
      order?.adjustments,
      order?.status,
      adjustmentParams,
      updateCart,
      showNotification,
      translate,
    ],
  );

  const onConfirmSetPrice = useCallback(
    (
      unitPrice: number,
      adjustments: Adjustment[],
      isLocalCustomAdjustment?: boolean,
    ) => {
      if (isLocalCustomAdjustment) {
        setLocalAppliedAdjustments(prevLocalAdjustments => {
          const now = Date.now();
          const updatedLocalAdjustments = (prevLocalAdjustments || []).map(
            existingAdjustment => {
              const newAdjustment = adjustments.find(
                newAdjustment => newAdjustment.id === existingAdjustment.id,
              );

              return newAdjustment
                ? { ...newAdjustment, timestamp: now } // Add timestamp to new adjustment
                : existingAdjustment;
            },
          );

          // Add new adjustments that are not already present in local storage
          adjustments.forEach(newAdjustment => {
            if (
              !updatedLocalAdjustments.some(
                existingAdjustment =>
                  existingAdjustment.id === newAdjustment.id,
              )
            ) {
              updatedLocalAdjustments.push({
                ...newAdjustment,
                timestamp: now,
              });
            }
          });
          setLocalSurcharges(updatedLocalAdjustments);
          // Return the updated local state
          return updatedLocalAdjustments;
        });

        return;
      }
      if (selectedCartItem) {
        updateCart<UpdateOrderItemPriceEvent>(
          OrderAction.ORDER_ITEM_UPDATE_PRICE,
          {
            unitPrice,
            adjustments,
            orderItemId: selectedCartItem?.item || '',
          },
        );
        unselectCartItem();
      } else {
        if (isExistingAdjustment(adjustments[0]) || !adjustments[0]?.amount)
          return;

        const orderAdjustments = [
          ...(order?.adjustments ?? []),
          adjustments[0],
        ];
        updateCart<UpdateOrderAdjustmentsEvent>(
          OrderAction.ORDER_UPDATE_ADJUSTMENTS,
          {
            adjustments: orderAdjustments,
          },
        );
      }
    },
    [
      selectedCartItem,
      setLocalSurcharges,
      updateCart,
      unselectCartItem,
      isExistingAdjustment,
      order?.adjustments,
    ],
  );

  const onPressPrintReceipt = useCallback(
    async (isPrintSplit?: boolean) => {
      if (order) {
        const cachedOrder = getOrderFromCache(order.id) || order;
        let nthPaymentToPrint;
        if (isPrintSplit) {
          nthPaymentToPrint = (cachedOrder?.payments || []).length - 1;
        }
        updateCart(OrderAction.ORDER_PRINT);
        const result = await printBill(cachedOrder, nthPaymentToPrint);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
        closeModal();
        if (!isOrderOnHold) {
          navigateToDefaultScreen();
        }
      }
    },
    [
      order,
      getOrderFromCache,
      printBill,
      updateCart,
      closeModal,
      isOrderOnHold,
      showNotification,
      navigateToDefaultScreen,
    ],
  );

  const readOnly = READ_ONLY_STATUSES.includes(
    order?.status || OrderStatus.CREATED,
  );
  const totalCartItems = order?.orderItems.length || 0;
  const disableCartActions = totalCartItems === 0;
  const disableOrderActions = readOnly || disableCartActions;

  const sendOrderReceipt = useCallback(
    async (input: sendReceiptInput) => {
      if (session && order?.id) {
        const event = {
          action: OrderAction.CUSTOMER_NOTIFICATION,
          orderId: order.id,
          id: uuidv4(),
          notificationType:
            input.mode === NotificationMode.PHONE
              ? NotificationType.SEND_MESSAGE
              : NotificationType.SEND_RECEIPT,
          notificationMode: input.mode,
          organizationId: session.currentOrganization?.id || '',
          venueId: session.currentVenue?.id || '',
          storeId: session.currentStore?.id || '',
          deviceId: session.device?.id || '',
          timestamp: new Date().getTime(),
          triggeredBy: userUtility.userActivity.posUser?.id || '',
          ...(input.email && { email: input.email }),
          ...(input.phone?.number && {
            phone: `${getCountry(input.phone.countryCode)?.phone} ${
              input.phone.number
            }`,
          }),
        };
        syncOrderEvents([event]);
      }
    },
    [session, order?.id, syncOrderEvents],
  );

  const showSendOrderReceiptModal = useCallback(async () => {
    const lastCompletedOrder = getLatestCompletedOrderByDevice(
      session.device?.id,
    );
    if (!lastCompletedOrder) {
      showNotification({
        message: translate('common.noLastOrderFound'),
        error: true,
      });
      return;
    }
    showModal(
      <SendOrderReceiptModal
        onSendSuccess={handleSendSuccess}
        onSendFailed={handleSendFailed}
        order={lastCompletedOrder}
      />,
    );
  }, [
    getLatestCompletedOrderByDevice,
    handleSendFailed,
    handleSendSuccess,
    session.device?.id,
    showModal,
    showNotification,
    translate,
  ]);

  const sendOrderReceiptHandler = useCallback(
    async (input: sendReceiptInput) => {
      const lastCompletedOrder = getLatestCompletedOrderByDevice(
        session.device?.id,
      );
      if (!lastCompletedOrder) {
        showNotification({
          message: translate('common.noLastOrderFound'),
          error: true,
        });
        return;
      }

      sendOrderReceipt(input);
    },
    [
      getLatestCompletedOrderByDevice,
      sendOrderReceipt,
      session.device?.id,
      showNotification,
      translate,
    ],
  );
  const onPressSendOrderReceipt = useCallback(
    async (input: sendReceiptInput) => {
      await sendOrderReceiptHandler(input);
      if (!isOrderOnHold) {
        navigateToDefaultScreen();
      }
    },
    [isOrderOnHold, navigateToDefaultScreen, sendOrderReceiptHandler],
  );

  const onPressUpdatePriceMap = useCallback(
    (type, item?: OrderItem, isLocalCustomAdjustment?: boolean) => {
      const maxPercentage = 100;
      escapeDiscardModal.current = false;
      if (!type) {
        // sanity check, default value
        type = SET_PRICE_OPTIONS.DISCOUNT_PRICE;
      }
      if (advancedKeypadValue) {
        switch (type) {
          case SET_PRICE_OPTIONS.DISCOUNT_PERCENT:
            if (order && order?.subTotal <= 0) {
              showNotification({
                message: translate('order.minDiscountWarning', {
                  price: '0',
                }),
                error: true,
              });
            }
            if (advancedKeypadValue > maxPercentage) {
              showNotification({
                message: translate('order.maxDiscountWarning', {
                  value: '100%',
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                item ? item.unitPrice : order?.subTotal || 0,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: -Number(advancedKeypadValue),
                      adjustmentUnit: AdjustmentUnit.PERCENTAGE,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.DISCOUNT,
                    },
                  ],
                  1,
                ),
              );
            }
            break;

          case SET_PRICE_OPTIONS.DISCOUNT_PRICE:
            const originalTotal = computeLineTotal({
              ...(item
                ? item
                : ({
                    unitPrice: order?.subTotal || 0,
                    quantity: 1,
                    discounts: order?.discounts || [],
                  } as OrderItem)),
              unitPrice: item ? item.unitPrice : order?.subTotal || 0,
              discounts: [],
            });
            if (advancedKeypadValue > originalTotal) {
              showNotification({
                message: translate('order.maxDiscountWarning', {
                  value: `${formatCurrency(originalTotal)}`,
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                item ? item.unitPrice : order?.subTotal || 0,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: -Number(advancedKeypadValue),
                      adjustmentUnit: AdjustmentUnit.FLAT,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.DISCOUNT,
                    },
                  ],
                  1,
                ),
              );
            }
            break;

          case SET_PRICE_OPTIONS.SURCHARGE_PERCENT:
          case SET_PRICE_OPTIONS.SURCHARGE_PRICE:
            const product = item ? productsMap[item.product.id] : undefined;
            const price = item ? item.unitPrice : order?.subTotal || 0;
            const surchargeType =
              type === SET_PRICE_OPTIONS.SURCHARGE_PERCENT
                ? AdjustmentUnit.PERCENTAGE
                : AdjustmentUnit.FLAT;
            if (
              product &&
              product.minSellingPrice &&
              price < product.minSellingPrice
            ) {
              showNotification({
                message: translate('order.minPriceWarning', {
                  price: appendCurrency(product.minSellingPrice.toString()),
                }),
                error: true,
              });
            } else if (
              product &&
              product.maxSellingPrice &&
              price > product.maxSellingPrice
            ) {
              showNotification({
                message: translate('order.maxPriceWarning', {
                  price: appendCurrency(product.maxSellingPrice.toString()),
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                price,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: advancedKeypadValue,
                      adjustmentUnit: surchargeType,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.SURCHARGE,
                    },
                  ],
                  1,
                ),
                isLocalCustomAdjustment,
              );
            }
            break;
        }
        setAdvancedKeypadValue(0);
      } else {
        showModal(
          <SetPriceModalMap
            item={
              item
                ? item
                : ({
                    unitPrice: order?.subTotal || 0,
                    quantity: 1,
                    discounts: order?.discounts || [],
                  } as OrderItem)
            }
            onSubmit={onConfirmSetPrice}
            maxDiscounts={1}
            adjustmentType={type}
            defaultPrice={item ? item.unitPrice : order?.subTotal || 0}
            isLocalCustomAdjustment={isLocalCustomAdjustment}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      }
    },
    [
      escapeDiscardModal,
      advancedKeypadValue,
      setAdvancedKeypadValue,
      order,
      productsMap,
      showNotification,
      translate,
      onConfirmSetPrice,
      formatCurrency,
      appendCurrency,
      showModal,
      closeModal,
    ],
  );

  const onCompleteSale = useCallback(
    async (roundedOffChange: number) => {
      syncItemAvailability();
      if (!isOrderOnHold) {
        setTimeout(async () => {
          if (order) {
            const { postSaleScreen, defaultOrderType } =
              session.deviceProfile || {};
            if (
              postSaleScreen &&
              AppScreen[postSaleScreen] != AppScreen.NEW_ORDER
            ) {
              setCartParams(undefined, defaultOrderType?.id, '', false);
              const newOrderId = await resetCart();
              navigation.setParams({
                id: newOrderId,
                orderType: defaultOrderType?.id,
                tableId: '',
                isCompleted: false,
                isExisting: false,
              });
            }
          }
        });
      }
      if (receiptPrintOption === ReceiptPrintOption.AUTO) {
        // print bill receipt with delay silently
        setTimeout(onPressPrintReceipt);
      } else if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        const isPartialPayment =
          processingPaymentAmount < (order?.totalPaymentAmount || 0);
        setTimeout(() => {
          showModal(
            <SaleCompleteModal
              customer={assignedCustomer}
              sendReceipt={onPressSendOrderReceipt}
              onPressPrintReceipt={onPressPrintReceipt}
              onPrintSplit={() => onPressPrintReceipt(true)}
              onPressNewSale={navigateToPostSaleScreen}
              changeDue={roundedOffChange}
              isSplitPayment={isPartialPayment}
              loyaltyAction={null}
              isOrderOnHold={isOrderOnHold}
              onHoldAction={
                <OnHoldOrderOptions
                  order={order}
                  updateCart={updateCart}
                  openOrderCart={openOrderCart}
                  isFromFunctionMap={true}
                />
              }
            />,
          );
        });
      } else {
        if (!isOrderOnHold) {
          navigateToDefaultScreen();
        }
      }
    },
    [
      syncItemAvailability,
      isOrderOnHold,
      receiptPrintOption,
      order,
      session.deviceProfile,
      resetCart,
      setCartParams,
      navigation,
      onPressPrintReceipt,
      processingPaymentAmount,
      showModal,
      assignedCustomer,
      onPressSendOrderReceipt,
      navigateToPostSaleScreen,
      updateCart,
      openOrderCart,
      navigateToDefaultScreen,
    ],
  );

  const getLoyaltyPoints = useCallback(() => {
    const pointsEarned = calculatePointsEarnedForOrder(earningRules, order);
    const pointsRedeemed = getAllRedeemedRewards(order).reduce(
      (totalPoints, reward) =>
        totalPoints + reward.quantity * reward.pointsRequired,
      0,
    );

    return {
      pointsEarned,
      pointsRedeemed,
    };
  }, [order, earningRules]);

  const onPressPayAmount = useCallback(
    async (
      paymentType: PaymentType,
      amount: number,
      surchargeAmount = 0,
      additionalInfo?: AdditionalPaymentInfo,
      saveEventId?: string,
    ) => {
      let validReceivedAmount = amount;

      if (
        paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE &&
        enableRoundOff
      ) {
        validReceivedAmount = roundOffByValue(amount, roundOffValue);
      }

      const change = limitDecimalCount(
        sumDecimals([
          validReceivedAmount,
          -processingPaymentAmount,
          -surchargeAmount,
        ]),
      );

      const roundedOffChange = enableRoundOff
        ? roundOffByValue(change, roundOffValue)
        : change;

      if (
        enableQuickPaymentMode &&
        paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE
      ) {
        changeDueVar(roundedOffChange);
      }

      if (isLoyaltyEnabled && assignedCustomer?.loyaltyMember) {
        if (isNewLoyaltyEnabled && order) {
          // new Loyalty logic, involve calling API for estimation
          const estimated = await estimateSnapshot(assignedCustomer.id, order);
          estimated && updateLoyaltySnapshot(mapLoyaltySnapshot(estimated));
        } else {
          // logic for legacy Loyal
          const { pointsEarned, pointsRedeemed } = getLoyaltyPoints();
          calculateAndUpdateLoyaltySnapshot(
            assignedCustomer?.loyaltyPointsBalance,
            pointsEarned,
            pointsRedeemed,
          );
        }
      }

      updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
        tendered: validReceivedAmount,
        paymentTypeId: paymentType.id,
        tip: 0,
        change: roundedOffChange,
        roundOffDifference: limitDecimalCount(
          sumDecimals([change, -roundedOffChange]),
        ),
        paymentTypeName: paymentType.name,
        ...(additionalInfo &&
          additionalInfo.paymentRequestId && {
            paymentRequestId: additionalInfo.paymentRequestId,
          }),
        ...(additionalInfo &&
          additionalInfo.paymentStatus && {
            paymentStatus: additionalInfo.paymentStatus,
          }),
      });
      // Open cash drawer
      if (
        ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
          paymentType.name?.toLowerCase(),
        )
      ) {
        openCashDrawer();
      }

      if (saveEventId) {
        updateCart(OrderAction.ORDER_SAVE, undefined, saveEventId);
      } else {
        updateCart(OrderAction.ORDER_SAVE);
      }

      if (additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING) {
        onCompleteSale(roundedOffChange);
      }
    },
    [
      enableRoundOff,
      processingPaymentAmount,
      roundOffValue,
      enableQuickPaymentMode,
      updateCart,
      isLoyaltyEnabled,
      isNewLoyaltyEnabled,
      assignedCustomer?.id,
      assignedCustomer?.loyaltyMember,
      assignedCustomer?.loyaltyPointsBalance,
      order,
      estimateSnapshot,
      updateLoyaltySnapshot,
      getLoyaltyPoints,
      calculateAndUpdateLoyaltySnapshot,
      openCashDrawer,
      onCompleteSale,
    ],
  );

  const onPaymentProcessed = useCallback(
    (events: OrderEvent[]) => {
      const roundedOffChange = 0;

      const paymentCompleteEvent = events.find(
        event =>
          event.action === OrderAction.ORDER_PAYMENT_PROCESSED &&
          (event as OrderPaymentEvent).paymentStatus ===
            OrderPaymentStatus.COMPLETE,
      );

      addEventsToCart(events);

      if (paymentCompleteEvent) {
        onCompleteSale(roundedOffChange);
      }
    },
    [addEventsToCart, onCompleteSale],
  );

  const { createCardPayment } = useCardPayFunctionMap(
    order,
    onPressPayAmount,
    onPaymentProcessed,
    openOrderCart,
  );

  const isDiscountAppliedToCart = (
    orderItems: OrderItem[],
    adjustment: Adjustment,
  ) => {
    return orderItems.some(item =>
      item.adjustments?.find(a => a.id === adjustment.id),
    );
  };

  const findAndApplyAdjustment = useCallback(
    (adjustment: Adjustment) => {
      const isDiscountApplied = isDiscountAppliedToCart(
        order?.orderItems ?? [],
        adjustment,
      );
      const adjustmentResponse = getScheduleAdjustments(
        [{ ...adjustment, autoApply: false }],
        order?.orderItems,
        {
          isManuallyTriggered: true,
          selectedOrderType: orderType?.id,
          storeId: currentStoreId,
        },
      );
      const orderItemKeys = Object.keys(
        adjustmentResponse?.adjustmentByOrderItem || {},
      );
      const isValidAdjustment = !!(
        orderItemKeys?.length
          ? orderItemKeys
          : adjustmentResponse?.validAdjustments
      )?.length;
      /*
        If discount found then we'll apply/return and won't execute the normal flow
        where we're applies the adjustment right away
      */
      if (adjustment.isDiscountRuleEnabled && orderItemKeys?.length) {
        adjustmentParams.appliedManualAdjustmentIdRef.current =
          adjustment.id as string;
        adjustmentParams.resetManualAdjustmentId(
          adjustmentResponse.adjustmentByOrderItem,
        );
        adjustmentParams.setRemovedScheduleAdjustmentIds(undefined, [
          adjustment.id as string,
        ]);
        applyAdvancedDiscounts(
          adjustmentResponse?.adjustmentByOrderItem as AdjustmentByOrderItemMap,
        );
        return false;
      }

      // If adjustment is not valid then shows the notification to user
      if (!isDiscountApplied && !isValidAdjustment) {
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.invalidPromoDiscount',
            { name: adjustment.name },
          ),
          error: true,
        });
        return false;
      }
      // If normal discount is clicked(not advanced) and valid then we'll follow the normal flow
      const normalAdjustmentToApply = !!(
        !adjustment.isDiscountRuleEnabled && isValidAdjustment
      );
      return normalAdjustmentToApply;
    },
    [
      applyAdvancedDiscounts,
      currentStoreId,
      order?.orderItems,
      orderType?.id,
      showNotification,
      translate,
      adjustmentParams,
    ],
  );

  const onApplyDiscount = useCallback(
    (
      discountType: AdjustmentUnit,
      amount,
      item?: OrderItem,
      adjustment?: Adjustment,
    ) => {
      if (isAdvanceDiscountEnabled) {
        const discountToApply = findAndApplyAdjustment(
          adjustment as Adjustment,
        );
        if (!discountToApply) return;
      }
      const price = (item ? computeLineTotal(item) : order?.subTotal) || 0;
      if (order && order?.subTotal <= 0) {
        showNotification({
          //TODO: Need to update this to display correct message
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(price)}`,
          }),
          error: true,
        });
      }
      if (
        (item || order) &&
        discountType == AdjustmentUnit.FLAT &&
        price < Math.abs(+amount)
      ) {
        showNotification({
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(price)}`,
          }),
          error: true,
        });
      } else {
        onConfirmSetPrice(
          (item ? item.unitPrice : order?.subTotal) || 0,
          adjustAdjustments(
            [
              {
                id: selectedAdjustmentRef.current.id,
                name: selectedAdjustmentRef.current.name,
                amount: Number(amount),
                adjustmentUnit: discountType,
                allowOnPaymentType: false,
                adjustmentType: AdjustmentType.DISCOUNT,
              },
            ],
            1,
          ),
        );
      }
    },
    [
      isAdvanceDiscountEnabled,
      order,
      findAndApplyAdjustment,
      showNotification,
      translate,
      formatCurrency,
      onConfirmSetPrice,
    ],
  );

  const onApplySurcharge = useCallback(
    (
      surchargeType: AdjustmentUnit,
      amount,
      item?: OrderItem,
      adjustment?: Adjustment,
    ) => {
      if (isAdvanceDiscountEnabled) {
        /*
          If we're applying the standard discount where no promo is configured and
          If it matches the adjustment validations then we're going to execute that adjustment flow as of before
        */
        const surchargeToApply = findAndApplyAdjustment(
          adjustment as Adjustment,
        );
        if (!surchargeToApply) return;
      }

      onConfirmSetPrice(
        (item ? item.unitPrice : order?.subTotal) || 0,
        adjustAdjustments(
          [
            {
              id: selectedAdjustmentRef.current.id,
              name: selectedAdjustmentRef.current.name,
              amount: Number(amount),
              adjustmentUnit: surchargeType,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.SURCHARGE,
              doNotIncludeInSalesAmount:
                adjustment?.doNotIncludeInSalesAmount || false,
            },
          ],
          1,
        ),
      );
    },
    [
      isAdvanceDiscountEnabled,
      onConfirmSetPrice,
      order?.subTotal,
      findAndApplyAdjustment,
    ],
  );

  const applySurcharge = useCallback(
    (paymentType, surcharge) => {
      if (order && surcharge?.amount) {
        updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: surcharge,
        });
        const surchargeAmount = getAdjustmentValue(
          processingPaymentAmount,
          [surcharge],
          { adjustmentType: AdjustmentType.SURCHARGE },
        );
        onPressPayAmount &&
          onPressPayAmount(
            paymentType,
            processingPaymentAmount + surchargeAmount,
            surchargeAmount,
          );
      } else {
        onPressPayAmount &&
          onPressPayAmount(paymentType, processingPaymentAmount);
      }
    },
    [order, updateCart, processingPaymentAmount, onPressPayAmount],
  );

  const checkAllowAdjustmentPermission = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    return allowAdjustment;
  }, [canI]);

  const checkAllowTakePaymentPermission = useCallback(() => {
    const allowCheckPay = canI([{ onResource: Resource.FINISH_SALE }], {
      prompt: true,
    });
    return allowCheckPay;
  }, [canI]);

  const applySurchargeTillPay = useCallback(
    (paymentType, surcharge) => {
      setTimeout(() => {
        let total = processingPaymentAmount;

        if (order && surcharge?.amount) {
          updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
            adjustment: surcharge,
          });
          const surchargeAmount = getAdjustmentValue(
            processingPaymentAmount,
            [surcharge],
            { adjustmentType: surcharge.adjustmentType },
          );

          total = processingPaymentAmount + surchargeAmount;
        }

        createCardPayment({
          orderInfo: {
            orderId: order?.id as string,
            orderAmount: total,
            orderNumber: order?.orderNumber as string,
          },
          paymentType,
          surchargeAmount: total - processingPaymentAmount,
        });
      }, 500);
    },
    [order, updateCart, processingPaymentAmount, createCardPayment],
  );

  const payExactTillAmount = useCallback(async () => {
    const paymentType = getPaymentTypeByName(DefaultPaymentTypes.CARD);
    if (paymentType) {
      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingPaymentAmount}
            onSubmit={applySurchargeTillPay}
            paymentType={paymentType}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        createCardPayment({
          orderInfo: {
            orderId: order?.id as string,
            orderAmount: processingPaymentAmount,
            orderNumber: order?.orderNumber as string,
          },
          paymentType,
        });
      }
    }
  }, [
    order,
    getPaymentTypeByName,
    showModal,
    processingPaymentAmount,
    applySurchargeTillPay,
    checkAllowAdjustmentPermission,
    createCardPayment,
  ]);

  const payExactCashAmount = useCallback(
    async (type: string, paymentType: PaymentType | undefined) => {
      if (paymentType && paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingPaymentAmount}
            onSubmit={applySurcharge}
            paymentType={paymentType}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        onPressPayAmount(
          getPaymentTypeByName(type) as PaymentType,
          processingPaymentAmount,
        );
      }
    },
    [
      applySurcharge,
      checkAllowAdjustmentPermission,
      getPaymentTypeByName,
      onPressPayAmount,
      processingPaymentAmount,
      showModal,
    ],
  );

  const onPressPayExactAmount = useCallback(
    async paymentName => {
      if (order && !disableOrderActions && !isZeroAmountDue(order)) {
        const callEvent = areCartItemsValid('order.incorrectOrder');
        if (callEvent) {
          switch (paymentName) {
            case 'cash':
              const paymentType = paymentTypes.find(
                currentPaymentType =>
                  currentPaymentType.name.toLowerCase() == paymentName,
              );
              payExactCashAmount(paymentName, paymentType);
              break;
            case 'card':
              if (netInfo.isConnected) {
                payExactTillAmount();
              } else {
                showNotification({
                  message: translate('order.offlineWarning'),
                  error: true,
                });
              }
              break;
          }
        }
      }
    },
    [
      isZeroAmountDue,
      order,
      disableOrderActions,
      areCartItemsValid,
      paymentTypes,
      payExactCashAmount,
      netInfo.isConnected,
      payExactTillAmount,
      showNotification,
      translate,
    ],
  );

  const onPressCustomPay = useCallback(() => {
    const callEvent = areCartItemsValid('order.incorrectOrder');
    if (callEvent && !isZeroAmountDue(order)) {
      const onPressCashPay = (amount: number) => {
        onPressPayAmount(getPaymentTypeByName('Cash'), amount);
      };

      showModal(
        <CashPaymentModal
          amountDue={processingPaymentAmount}
          receivingAmount={advancedKeypadValue || processingPaymentAmount}
          onSubmit={onPressCashPay}
          enableRoundOff={enableRoundOff}
          roundOffValue={roundOffValue}
          isOrderOnHold={isOrderOnHold}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [
    areCartItemsValid,
    isZeroAmountDue,
    order,
    showModal,
    processingPaymentAmount,
    advancedKeypadValue,
    enableRoundOff,
    roundOffValue,
    closeModal,
    onPressPayAmount,
    getPaymentTypeByName,
    isOrderOnHold,
  ]);

  const onPressAddNewMoneyEvent = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.ALLOW_MONEY_MOVEMENTS }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    showModal(
      <AddMoneyEvent
        printMoneyMovementReceipt={printMoneyMovementReceipt}
        openCashDrawer={openCashDrawer}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [canI, showModal, openCashDrawer, closeModal, printMoneyMovementReceipt]);

  const switchPricingGroup = useCallback(
    value => {
      if (value) {
        if (value === FunctionMapActions.PICK_SCHEDULED_PRICE_LIST) {
          storage.removeItem(DEVICE_PRICING_GROUP_ID);
          storage.removeItem(USER_EXPLICIT_SELECT_PRICING_GROUP);
          setPricingGroup('');
          closeModal();
          return;
        }
        storage.setItem(USER_EXPLICIT_SELECT_PRICING_GROUP, true);
        storage.setItem(DEVICE_PRICING_GROUP_ID, value);
        setPricingGroup(value);
        closeModal();
      }
    },
    [closeModal, setPricingGroup],
  );

  const onPressSwitchPricingGroup = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.SWITCH_PRICING_GROUP }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    showModal(
      <PriceListModal
        value={pricingGroup}
        onSelect={switchPricingGroup}
        title={translate('order.switchPricingGroup')}
        options={pricingGroupOptions}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [
    canI,
    showModal,
    pricingGroup,
    switchPricingGroup,
    translate,
    pricingGroupOptions,
    closeModal,
  ]);

  const onPressSwitchCourse = useCallback(() => {
    if (!selectedCartItem?.item) {
      showNotification({
        message: translate('backOfficeProducts.switchCourseWarning'),
        error: true,
      });
      return;
    }
    const selectingItem = order?.orderItems.find(
      item => item.id === selectedCartItem?.item,
    ) as OrderItem;

    showModal(
      <SwitchCourse
        value={selectingItem?.course?.id || DEFAULT_ENTITY_ID}
        onSelect={value => {
          onSwitchCourseItem(selectedCartItem?.item as string, value);
          closeModal();
        }}
        title={translate('backOfficeProducts.switchCourse')}
        options={courseOptions}
        item={selectingItem}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [
    selectedCartItem?.item,
    order?.orderItems,
    showModal,
    translate,
    courseOptions,
    closeModal,
    showNotification,
    onSwitchCourseItem,
  ]);

  const onPressItemAvailability = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.ITEM_AVAILABILITY }], {
      prompt: true,
    });

    if (!hasAccess) {
      return;
    }
    showModal(
      <ItemAvailability
        pages={pages}
        productsInventory={storeInventoryRef.current}
        onSave={inventory => {
          storeInventoryRef.current = inventory;
        }}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [canI, showModal, closeModal, pages]);

  const switchMenu = useCallback(
    (value: string) => {
      if (value) {
        const menu = menus.find(menu => menu.id === value);
        if (menu) {
          setSession({
            ...session,
            deviceProfile: {
              ...session.deviceProfile,
              menu: {
                ...session.deviceProfile?.menu,
                id: value,
                name: menu.name,
              } as Catalogue,
            },
          });
        }
        closeModal();
      }
    },
    [menus, closeModal, setSession, session],
  );

  const menuOptions = useMemo(() => {
    const options = map(menus, menu => ({
      label: menu.name,
      value: menu.id,
    }));
    return options;
  }, [menus]);

  const onPressSwitchMenu = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.SWITCH_MENU }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    showModal(
      <PickerModal
        value={menuId}
        onSelect={switchMenu}
        title={translate('order.switchMenu')}
        options={menuOptions}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [canI, showModal, menuId, switchMenu, translate, menuOptions, closeModal]);

  const onPressLogout = useCallback(async () => {
    const hasAccess = canI([{ onResource: Resource.ALLOW_SYSTEM_LOGOUT }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    if (netInfo.isConnected) {
      await logout();
    } else {
      showNotification({
        message: translate('order.offlineWarning'),
        error: true,
      });
    }
  }, [canI, logout, netInfo.isConnected, showNotification, translate]);

  const onRedeemReward = useCallback<UseFunctionMapsProps['onRewardRedeem']>(
    (...args) => {
      closeModal();
      onRewardRedeem && onRewardRedeem(...args);
    },
    [closeModal, onRewardRedeem],
  );

  const onEnrollLoyalty = useCallback(async () => {
    if (!order?.customer) {
      return;
    }

    if (isNewLoyaltyEnabled) {
      const member = await enrollMemberLoyalty(
        order.customer.id,
        EnrollmentSource.POS,
      );
      if (!member) return;
      updateCart<UpdateCustomerEvent>(OrderAction.ORDER_UPDATE_CUSTOMER, {
        loyaltyMember: true,
        isLoyaltyApplied: false,
      });
      updateCustomerCache(member);
    } else if (isLegacyLoyaltyEnabled) {
      await enrollCustomerLoyalty({
        customerId: order.customer.id,
        loyaltyEnrolmentSource: LoyaltyEnrolmentSource.POS,
      });
      updateCart<UpdateCustomerEvent>(OrderAction.ORDER_UPDATE_CUSTOMER, {
        loyaltyMember: true,
        isLoyaltyApplied: true,
      });
    }
    closeModal();
  }, [
    closeModal,
    enrollCustomerLoyalty,
    order?.customer,
    isLegacyLoyaltyEnabled,
    isNewLoyaltyEnabled,
    enrollMemberLoyalty,
    updateCustomerCache,
    updateCart,
  ]);

  const onPressShowRewards = useCallback(async () => {
    if (!order?.customer) {
      return showNotification({
        error: true,
        message: translate('customerLoyalty.requireAddCustomer'),
      });
    }
    showModal(
      <CustomerRewardModal
        customer={assignedCustomer as Customer}
        onRedeem={onRedeemReward}
        onEnrol={onEnrollLoyalty}
        enrolling={enrolling}
        rewardRules={rewardRules}
        loyaltySettings={loyaltySettings}
      />,
    );
  }, [
    order?.customer,
    showModal,
    assignedCustomer,
    showNotification,
    translate,
    onRedeemReward,
    loyaltySettings,
    rewardRules,
    onEnrollLoyalty,
    enrolling,
  ]);

  const printPreviousReceipt = useCallback(() => {
    const lastCompletedOrder = getLatestCompletedOrderByDevice(
      session.device?.id,
    );
    if (lastCompletedOrder) {
      updateCart(OrderAction.ORDER_PRINT);

      printBill(lastCompletedOrder);
    }
  }, [
    getLatestCompletedOrderByDevice,
    printBill,
    session.device?.id,
    updateCart,
  ]);

  const resendToKitchen = useCallback(() => {
    let selectedOrderItems: OrderItem[] = [];
    if (order) {
      if (selectedCartItem) {
        const selectedOrderItem = orderItems.find(
          item => item.id == selectedCartItem.item,
        );
        selectedOrderItems.push(selectedOrderItem as OrderItem);
      } else {
        selectedOrderItems = orderItems as OrderItem[];
      }

      reprintKitchenDocket && reprintKitchenDocket(order, selectedOrderItems);
    }
  }, [order, orderItems, reprintKitchenDocket, selectedCartItem]);

  const holdPrint = useCallback(() => {
    if (order?.status == OrderStatus.CREATED) {
      updateCart(OrderAction.ORDER_HOLD);
    } else {
      // show error notification
      showNotification({
        message: translate('order.holdOrderWarning'),
        error: true,
      });
    }
  }, [order, updateCart, showNotification, translate]);

  const onOpenCashDrawer = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.OPEN_CASH_DRAWER }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    openCashDrawer();
  }, [canI, openCashDrawer]);

  const isFunctionMapAllowed = useCallback(
    (action = '') => {
      if (
        action === FunctionMapActions.ITEM_AVAILABILITY &&
        order?.orderItems.length
      ) {
        showNotification({
          message: translate('order.manageFunctionWarning'),
          error: true,
        });
        return false;
      } else return true;
    },
    [order?.orderItems.length, showNotification, translate],
  );

  const onSelectAdjustment = useCallback(
    (id?: string, appliedAdjustments?: Adjustment[]) => {
      if (!id) return;
      const adjustments = session.currentVenue?.adjustments;
      const isAlreadyApplied = appliedAdjustments?.find(
        adjustment => adjustment?.id === id,
      );

      setLocalAppliedAdjustments(prevAppliedAdjustments => {
        const now = Date.now();

        let updatedAppliedAdjustments;
        if (isAlreadyApplied && isAlreadyApplied.id) {
          updatedAppliedAdjustments = (prevAppliedAdjustments || []).filter(
            adjustment => adjustment?.id !== isAlreadyApplied.id,
          );
        } else {
          if (id === CUSTOM_ADJUSTMENT_ID) {
            onPressUpdatePriceMap(
              SET_PRICE_OPTIONS.SURCHARGE_PERCENT,
              undefined,
              true,
            );
          }
          const selectedAdjustment = adjustments?.find(
            adjustment => adjustment?.id === id,
          );
          updatedAppliedAdjustments = selectedAdjustment
            ? [
                ...(prevAppliedAdjustments || []),
                { ...selectedAdjustment, timestamp: now },
              ]
            : prevAppliedAdjustments;
        }
        setLocalSurcharges(updatedAppliedAdjustments);
        return updatedAppliedAdjustments;
      });
    },
    [session, onPressUpdatePriceMap, setLocalSurcharges],
  );

  const openScheduleAdjustmentModal = useCallback(
    (callback?: () => void) => {
      if (callback) {
        callback();
      }

      const adjustments = session.currentVenue?.adjustments;
      const filteredAdjustments = adjustments?.filter(
        adjustment =>
          !adjustment.allowOnPaymentType &&
          adjustment.adjustmentType == AdjustmentType.SURCHARGE,
      );
      const isAllowAdjustment = checkAllowAdjustmentPermission();
      if (!isAllowAdjustment) return;

      showModal(
        <ApplySurcharge
          adjustments={filteredAdjustments}
          localAppliedAdjustments={localAppliedAdjustments}
          onSelectAdjustment={onSelectAdjustment}
          onDismiss={closeModal}
        />,
      );
    },
    [
      checkAllowAdjustmentPermission,
      closeModal,
      localAppliedAdjustments,
      onSelectAdjustment,
      session.currentVenue?.adjustments,
      showModal,
    ],
  );

  const actionDispatcher = useCallback(
    async (
      functionName: string | undefined,
      params: string | undefined,
      adjustment?: Adjustment,
    ) => {
      if (!isFunctionMapAllowed(functionName)) return;
      const item = orderItems.find(
        x => x.id === selectedCartItem?.item,
      ) as OrderItem;
      escapeDiscardModal.current = true;
      const promptOrderName =
        session.deviceProfile?.orderIdentifier ===
          OrderIdentifier.CUSTOMER_NAME &&
        order?.status === OrderStatus.CREATED;
      const isPickUp =
        orderType?.code &&
        ![OrderTypeCode.DELIVERY, OrderTypeCode.DINE_IN].includes(
          orderType.code as OrderTypeCode,
        );

      switch (functionName) {
        case FunctionMapActions.VOID_SALE:
          if (item) escapeDiscardModal.current = false;
          onPressVoidSale();
          break;
        case FunctionMapActions.OPEN_TABLE:
          onPressOpenTable();
          break;
        case FunctionMapActions.ADD_NOTE:
          onPressUpdateOrderNotes();
          break;
        case FunctionMapActions.ADD_VOUCHER:
          if (netInfo.isConnected) {
            onAddVoucher();
          } else {
            showNotification({
              message: translate('order.offlineWarning'),
              error: true,
            });
          }
          break;
        case FunctionMapActions.PAY:
          if (!checkAllowTakePaymentPermission()) return;
          checkForUnfiredItems(() => {
            const exactPayCallback = () => onPressPayExactAmount(params);
            if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
              // FIXME: Removed auto payment initiation from here,
              // Because of modal chaining payment hook is getting into inconsistent states.
              // Function initiating final payment in TakeOrder -> "onFinishSelectTableAndGuest"
              openTableNumberModal();
            } else if (promptOrderName && isPickUp) {
              updateOrderNameModal(exactPayCallback);
            } else exactPayCallback();
          });
          break;
        case FunctionMapActions.CASH_PAY:
          if (!checkAllowTakePaymentPermission()) return;
          if (
            advancedKeypadValue &&
            processingPaymentAmount &&
            advancedKeypadValue < processingPaymentAmount
          ) {
            showNotification({
              message: translate('payment.amountCannotBeLessThanRemaining'),
              error: true,
            });
            return;
          }
          checkForUnfiredItems(() => {
            if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
              openTableNumberModal(onPressCustomPay);
            } else if (promptOrderName && isPickUp) {
              updateOrderNameModal(onPressCustomPay);
            } else onPressCustomPay();
          });
          break;
        case FunctionMapActions.ADD_NEW_CUSTOMER:
          onPressAddNewCustomer();
          break;
        case FunctionMapActions.BACK:
          setFunctionMap('');
          break;
        case FunctionMapActions.MONEY_IN_OUT:
          onPressAddNewMoneyEvent();
          break;
        case FunctionMapActions.END_SHIFT:
          onPressEndShift();
          break;
        case FunctionMapActions.PRINT_SHIFT_SUMMARY:
          onPrintShift();
          break;
        case FunctionMapActions.SWITCH_PRICING_GRP:
          onPressSwitchPricingGroup();
          break;
        case FunctionMapActions.SWITCH_COURSE:
          onPressSwitchCourse();
          break;
        case FunctionMapActions.ORDER_RECEIPT:
          showSendOrderReceiptModal();
          break;
        case FunctionMapActions.OPEN_DRAWER:
          onOpenCashDrawer();
          break;
        case FunctionMapActions.SCHEDULED_ADJUSTMENTS:
          openScheduleAdjustmentModal();
          break;
        case FunctionMapActions.PRINT_PREV_RECEIPT:
          printPreviousReceipt();
          break;
        case FunctionMapActions.RESEND_TO_KITCHEN:
          resendToKitchen();
          break;
        case FunctionMapActions.HOLD_PRINT:
          escapeDiscardModal.current = false;
          holdPrint();
          break;
        case FunctionMapActions.REMOVE_DISCOUNTS:
        case FunctionMapActions.REMOVE_SURCHARGES:
          removeOrderAdjustments(
            functionName === FunctionMapActions.REMOVE_DISCOUNTS
              ? AdjustmentType.DISCOUNT
              : AdjustmentType.SURCHARGE,
          );
          break;

        case FunctionMapActions.DISCOUNT_PERCENT:
          escapeDiscardModal.current = false;
          if (params) {
            onApplyDiscount(
              AdjustmentUnit.PERCENTAGE,
              params,
              item,
              adjustment,
            );
          }
          break;
        case FunctionMapActions.DISCOUNT_PRICE:
          escapeDiscardModal.current = false;
          if (params) {
            onApplyDiscount(AdjustmentUnit.FLAT, params, item, adjustment);
          }
          break;
        case FunctionMapActions.SURCHARGE_PRICE:
          escapeDiscardModal.current = false;
          if (params) {
            onApplySurcharge(AdjustmentUnit.FLAT, params, item, adjustment);
          }
          break;
        case FunctionMapActions.SURCHARGE_PERCENT:
          escapeDiscardModal.current = false;
          if (params) {
            onApplySurcharge(
              AdjustmentUnit.PERCENTAGE,
              params,
              item,
              adjustment,
            );
          }
          break;
        case FunctionMapActions.UPDATE_PRICE:
          onPressUpdatePriceMap(params, item);
          break;
        case FunctionMapActions.SYSTEM_LOGOUT:
          onPressLogout();
          break;
        case FunctionMapActions.SWITCH_MENU:
          onPressSwitchMenu();
          break;
        case FunctionMapActions.ITEM_AVAILABILITY:
          onPressItemAvailability();
          break;
        case FunctionMapActions.SHOW_REWARDS:
          onPressShowRewards();
          break;
        case FunctionMapActions.TRANSFER_ITEMS:
          onPressTransferItems();
          break;
      }
    },
    [
      isFunctionMapAllowed,
      orderItems,
      escapeDiscardModal,
      session.deviceProfile?.orderIdentifier,
      order?.status,
      order?.table,
      orderType?.code,
      selectedCartItem?.item,
      onPressVoidSale,
      onPressOpenTable,
      onPressUpdateOrderNotes,
      netInfo.isConnected,
      checkAllowTakePaymentPermission,
      checkForUnfiredItems,
      advancedKeypadValue,
      processingPaymentAmount,
      onPressAddNewCustomer,
      onPressAddNewMoneyEvent,
      onPressEndShift,
      onPrintShift,
      onPressSwitchPricingGroup,
      onPressSwitchCourse,
      showSendOrderReceiptModal,
      onOpenCashDrawer,
      openScheduleAdjustmentModal,
      printPreviousReceipt,
      resendToKitchen,
      holdPrint,
      removeOrderAdjustments,
      onPressUpdatePriceMap,
      onPressLogout,
      onPressSwitchMenu,
      onPressItemAvailability,
      onPressShowRewards,
      onAddVoucher,
      showNotification,
      translate,
      onPressPayExactAmount,
      openTableNumberModal,
      updateOrderNameModal,
      onPressCustomPay,
      onApplyDiscount,
      onApplySurcharge,
      onPressTransferItems,
    ],
  );

  const hasAccessToAnyManagerFunctions = useCallback(() => {
    return (
      canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }]) ||
      canI([{ onResource: Resource.SWITCH_MENU }]) ||
      canI([{ onResource: Resource.ALLOW_SYSTEM_LOGOUT }]) ||
      canI([{ onResource: Resource.ITEM_AVAILABILITY }]) ||
      canI([{ onResource: Resource.ALLOW_MONEY_MOVEMENTS }]) ||
      canI([{ onResource: Resource.OPEN_CASH_DRAWER }]) ||
      canI([{ onResource: Resource.SWITCH_PRICING_GROUP }])
    );
  }, [canI]);

  const isStaticFixedAdjustment = (action: string) => {
    return [
      FunctionMapActions.DISCOUNT_PRICE,
      FunctionMapActions.DISCOUNT_PERCENT,
      FunctionMapActions.SURCHARGE_PRICE,
      FunctionMapActions.SURCHARGE_PERCENT,
    ].includes(action as FunctionMapActions);
  };

  const getDispatcherAction = (
    adjustmentType: AdjustmentType | undefined,
    adjustmentUnit: string,
  ) => {
    let action = '';

    if (adjustmentType === AdjustmentType.DISCOUNT) {
      action =
        adjustmentUnit === AdjustmentUnit.FLAT
          ? FunctionMapActions.DISCOUNT_PRICE
          : FunctionMapActions.DISCOUNT_PERCENT;
    } else {
      action =
        adjustmentUnit === AdjustmentUnit.FLAT
          ? FunctionMapActions.SURCHARGE_PRICE
          : FunctionMapActions.SURCHARGE_PERCENT;
    }

    return action;
  };

  const getAdjustments = useCallback(
    (adjustments: Adjustment[], name: string | undefined) => {
      let adjustmentsWithoutAllowOnPayment: Adjustment[] = [];
      if (name?.toUpperCase() === ADJUSTMENT_TITLE.DISCOUNTS) {
        adjustmentsWithoutAllowOnPayment = adjustments.filter(
          a =>
            a.adjustmentType === AdjustmentType.DISCOUNT &&
            !a.allowOnPaymentType,
        );
      } else {
        adjustmentsWithoutAllowOnPayment = adjustments.filter(
          a =>
            a.adjustmentType === AdjustmentType.SURCHARGE &&
            !a.allowOnPaymentType,
        );
      }
      return adjustmentsWithoutAllowOnPayment
        .map(adjustment => {
          const action: string = getDispatcherAction(
            adjustment.adjustmentType,
            adjustment.adjustmentUnit,
          );
          const adjustmentAmount = String(adjustment.amount).replace('-', '');
          let adjustmentName = adjustment.name || '';
          const isMigratedStaticAdjustment =
            ADJUSTMENT_STATIC_NAME.includes(adjustmentName);
          if (!isMigratedStaticAdjustment) {
            const adjustmentValue =
              adjustment.adjustmentUnit === AdjustmentUnit.PERCENTAGE
                ? `${adjustmentAmount}%`
                : formatCurrency(+adjustmentAmount);
            adjustmentName += `(${adjustmentValue})`;
          }

          return {
            ...adjustment,
            name: adjustmentName,
            onPress: () => {
              selectedAdjustmentRef.current.name = !isMigratedStaticAdjustment
                ? adjustment.name || ''
                : '';
              selectedAdjustmentRef.current.id = adjustment.id || '';
              actionDispatcher(action, String(adjustment.amount), adjustment);
            },
          };
        })
        .sort((a, b) => (a.order || 0) - (b.order || 0));
    },
    [actionDispatcher, formatCurrency],
  );

  const actionMap = useMemo(() => {
    let actionsArray: {
      id: string;
      name: string;
      action?: string;
      params?: string;
      isGroup?: boolean;
      groupId?: string;
      color?: string;
      order?: number;
      feature?: string;
      featureContext?: string;
      onPress?: () => void;
    }[] = [];
    if (session.deviceProfile && session.deviceProfile.functionMap) {
      const actionMaps =
        session.deviceProfile.functionMap?.functionActions || [];
      const actions = translateFunctionMaps(
        actionMaps,
        translate,
        locale?.languageTag,
      );
      if (functionMap == '') {
        actionsArray = actions.filter(action => !action.groupId);
        actionsArray = actionsArray.sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        );
      } else {
        actionsArray = actions.filter(
          action =>
            action.groupId &&
            action.groupId == functionMap &&
            !isStaticFixedAdjustment(action.action ?? ''),
        );
        actionsArray = actionsArray.map(action => {
          if (
            action.action === FunctionMapActions.DISCOUNT_PRICE ||
            action.action === FunctionMapActions.SURCHARGE_PRICE
          ) {
            const formattedLabel = replaceFunctionMapCurrency(
              action.name,
              appendCurrency,
            );
            return {
              ...action,
              name: formattedLabel,
            };
          }
          return action;
        });
        actionsArray = actionsArray.map(action => {
          if (
            action.action === FunctionMapActions.UPDATE_PRICE &&
            (action.params === SET_PRICE_OPTIONS.SURCHARGE_PRICE ||
              action.params === SET_PRICE_OPTIONS.DISCOUNT_PRICE)
          ) {
            return {
              ...action,
              name: action.name.replace('$', currencySymbol),
            };
          }
          return action;
        });
        const backButton = {
          id: 'back-button',
          name: 'BACK',
          isGroup: false,
          color: '#fff',
          action: FunctionMapActions.BACK,
        };
        actionsArray = actionsArray.sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        );
        actionsArray = [backButton, ...actionsArray];
      }
      actionsArray = actionsArray.map(action => {
        if (action) {
          return {
            ...action,
            onPress: (): void => {
              if (action.isGroup) {
                if (
                  action.name ===
                    FUNCTION_MAPS_FOR_ORDER_COMPLETED.functionMapNames[1] &&
                  order?.orderItems?.length !== 0
                ) {
                  showNotification({
                    message: translate('order.manageFunctionWarning'),
                    error: true,
                  });
                } else if (
                  action.name.toUpperCase() === ADJUSTMENT_TITLE.DISCOUNTS ||
                  action.name.toUpperCase() === ADJUSTMENT_TITLE.SURCHARGES
                ) {
                  if (!checkAllowAdjustmentPermission()) return;
                  setFunctionMap(action.id);
                } else if (action.name.toUpperCase() === 'MANAGER FUNCTIONS') {
                  if (!hasAccessToAnyManagerFunctions()) {
                    showModal(
                      <PermissionsModal
                        title={translate('authorization.notAuthorizedTitle')}
                        message={translate('authorization.cannotPerformAction')}
                      />,
                    );
                    return;
                  }
                  setFunctionMap(action.id);
                } else {
                  setFunctionMap(action.id);
                }
              } else {
                actionDispatcher(action.action, action.params);
              }
            },
          };
        } else {
          return action;
        }
      });
      if (isOrderComplete) {
        actionsArray = actionsArray.filter(action => {
          if (
            FUNCTION_MAPS_FOR_ORDER_COMPLETED.functionMapNames.includes(
              action.name,
            )
          )
            return true;
          else return false;
        });
      }
      const selectedFunction = actions.find(
        action => action.id === functionMap,
      );
      let adjustments: Adjustment[] = [];
      if (
        selectedFunction?.name.toUpperCase() === ADJUSTMENT_TITLE.DISCOUNTS ||
        selectedFunction?.name.toUpperCase() === ADJUSTMENT_TITLE.SURCHARGES
      ) {
        adjustments = getAdjustments(
          session.currentVenue?.adjustments || [],
          selectedFunction.name,
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        actionsArray.splice(1, 0, ...(adjustments as any));
      }

      return [...actionsArray];
    } else {
      return [];
    }
  }, [
    session.deviceProfile,
    session.currentVenue,
    functionMap,
    isOrderComplete,
    appendCurrency,
    currencySymbol,
    order?.orderItems?.length,
    showNotification,
    translate,
    checkAllowAdjustmentPermission,
    hasAccessToAnyManagerFunctions,
    showModal,
    actionDispatcher,
    locale?.languageTag,
    getAdjustments,
  ]);

  return actionMap;
};

export default useFunctionMaps;
