import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const rowTextStyle: TextStyle = {
  fontSize: 14,
  lineHeight: 20,
  color: theme.colors.black1,
  fontFamily: theme.fonts.regular,
  fontVariant: ['tabular-nums'],
};

const styles = StyleSheet.create({
  filters: {
    columnGap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  dropdown: {
    width: 200,
  },
  cellName: {
    width: 200,
  },
  cellPhone: {
    width: 170,
    marginLeft: 10,
  },
  cellEmail: {
    flex: 1,
    marginLeft: 10,
  },
  cellPoints: {
    width: 74,
    textAlign: 'right',
  },
  rowText: {
    ...rowTextStyle,
  },
  rowName: {
    ...rowTextStyle,
    marginLeft: 12,
  },
  rowPoints: {
    ...rowTextStyle,
    textAlign: 'right',
    color: theme.colors.accent,
  },
  loading: {
    height: 200,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
});

export default styles;
