import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Role } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useNotification } from '../../../../hooks/Notification';
import { useRoles } from '../../../../hooks/app/users/useRoles';
import theme from '../../../../common/default-theme';
import styles from './ManageRoles.styles';
import Icon from '../../../../components/Icon/Icon';
import Search from '../../../../components/Shared/Search/Search';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';

interface RoleRowProps {
  role: Role;
}

export const RoleRow: React.FC<RoleRowProps> = ({ role }: RoleRowProps) => {
  const navigation = useNavigation();

  const hasUsersAssigned = role.usersCount && role.usersCount > 0;

  const navigateToRoleDetails = useCallback(() => {
    navigation.navigate('JobRoleSettings', {
      roleId: role.id,
      title: role.name,
    });
  }, [navigation, role.id, role.name]);

  return (
    <TouchableOpacity
      key={role.id}
      onPress={navigateToRoleDetails}
      style={[theme.tables.row, styles.roleRow]}
      testID="roleRow"
    >
      <Text testID="roleName" style={styles.cellRoleName}>
        {role.name}
      </Text>
      <Text testID="permissionsCount" style={styles.cellCount}>
        {role.permissions.length}
      </Text>
      <Text
        testID="usersCount"
        style={[
          styles.cellCount,
          {
            color: hasUsersAssigned ? theme.colors.black1 : theme.colors.grey4,
          },
        ]}
      >
        {role.usersCount}
      </Text>
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={theme.colors.grey4} />
      </View>
    </TouchableOpacity>
  );
};

export const ManageRoles: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { roles, error, loading, fetchRoles } = useRoles();

  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    if (isFocused) {
      fetchRoles({ includeSystemRoles: true, includeUserCount: true });
    }
  }, [fetchRoles, isFocused]);

  const onPressCreateRole = useCallback(() => {
    navigation.navigate('JobRoleSettings', {
      roleId: undefined,
    });
  }, [navigation]);

  const onSearchTextChange = useCallback((text: string) => {
    setSearchString(text);
  }, []);

  const sortedList = useMemo(() => {
    return roles.sort((a, b) => a.name.localeCompare(b.name));
  }, [roles]);

  const filteredList = useMemo(() => {
    return sortedList.filter(role =>
      role.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [sortedList, searchString]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter={true}
      title={translate('navigation.jobRoles', {
        appName: translate('appName'),
      })}
    >
      <Section
        layoutWidth="medium"
        title={translate('backofficeManageRoles.manageRoles')}
        subtitle={translate('backofficeManageRoles.manageRolesDescription')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-roles"
            value={searchString}
            onChangeText={onSearchTextChange}
            placeholder={translate('backofficeManageRoles.searchRoles')}
            containerStyle={styles.search}
          />
          <CreateButton onPress={onPressCreateRole} />
        </View>
        <View testID="table" style={styles.table}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('backofficeManageRoles.roleName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerCount]}>
              {translate('backofficeManageRoles.permissionsCount')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerUsers]}>
              {translate('backofficeManageRoles.userCount')}
            </Text>
          </View>
          {filteredList.map((role: Role, i: number) => (
            <RoleRow role={role} key={i} />
          ))}
        </View>
      </Section>
    </ScreenLayout>
  );
};
