import { BehaviorSubject } from 'rxjs';
import { OrderAction } from '@oolio-group/domain';

export interface RefetchOrderState {
  timestamp: number;
}

export interface CurrentOrderActionState {
  orderId: string;
  lastOrderAction?: OrderAction;
  lastEventId?: string;
  timestamp: number;
  isSyncComplete: boolean;
}

export const refetchOrderObservable = new BehaviorSubject<RefetchOrderState>({
  timestamp: 0,
});

export const currentOrderActionObservable = new BehaviorSubject<
  CurrentOrderActionState | undefined
>(undefined);
