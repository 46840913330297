import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import { DEFAULT_ENTITY_ID } from '@oolio-group/domain';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useProductTypes } from '../../../../hooks/app/useProductTypes';
import { differenceBy, orderBy, pick } from 'lodash';
import theme from '../../../../common/default-theme';
import styles from '../Reporting.styles';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import InputText from '../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

interface ReporingGroupRowProps {
  id: string;
  name: string;
  onChange: (id: string, value: string) => void;
  onDeleteProductType: (id: string) => void;
  onPressCreate: () => void;
}

const ReportingGroupRow: React.FC<ReporingGroupRowProps> = ({
  onDeleteProductType,
  onChange,
  id,
  name,
  onPressCreate,
}) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: name,
        })}
        onConfirm={() => {
          onDeleteProductType(id);
          closeModal();
        }}
      />,
    );
  }, [showModal, translate, name, onDeleteProductType, id, closeModal]);

  return (
    <View testID="product-type-row" style={theme.tables.row}>
      <InputText
        testID="product-type-name"
        value={name}
        placeholder={translate('backOfficeReportingGroups.newReportingGroup')}
        onChangeText={onChange.bind(null, id)}
        containerStyle={styles.cellName}
      />
      {id === DEFAULT_ENTITY_ID ? (
        <ButtonIcon type="positive" icon="plus" onPress={onPressCreate} />
      ) : (
        <ButtonIcon
          type="negativeLight"
          icon="trash-alt"
          onPress={onPressDelete}
        />
      )}
    </View>
  );
};

interface ReportingGroup {
  name: string;
  id: string;
  createAt: number;
  isChanged?: boolean;
}

const NewEmptyRow: ReportingGroup = {
  id: DEFAULT_ENTITY_ID,
  name: '',
  createAt: Date.now(),
};

export const ReportingGroups: React.FC = () => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<Record<string, ReportingGroup>>({});

  const {
    loading,
    error,
    productTypes: productTypesServer,
    getProductTypes,
    createProductTypes,
    updateProductTypes,
    deleteProductType,
  } = useProductTypes();

  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  useEffect(() => {
    if (productTypesServer) {
      setForm({ ...productTypesServer, [DEFAULT_ENTITY_ID]: NewEmptyRow });
    }
  }, [productTypesServer]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChangeName = useCallback((id: string, value: string): void => {
    setForm(form => ({
      ...form,
      [id]: {
        ...form[id],
        name: value,
      },
    }));
  }, []);

  const changedData = useMemo(() => {
    const originalData = Object.values(productTypesServer);
    const updatedData = Object.values(form).filter(
      p => p.id !== DEFAULT_ENTITY_ID,
    );
    return differenceBy(updatedData, originalData, 'name');
  }, [form, productTypesServer]);

  const onPressSave = useCallback((): void => {
    if (changedData.some(productType => !productType.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeReportingGroups.enterReportingGroupName'),
      });
      return;
    }
    updateProductTypes(changedData.map(p => pick(p, ['id', 'name'])));
  }, [changedData, showNotification, translate, updateProductTypes]);

  const onCreateProductType = useCallback(() => {
    const creatingProductType = form[DEFAULT_ENTITY_ID];
    if (!creatingProductType.name) {
      showNotification({
        error: true,
        message: translate('backOfficeReportingGroups.enterReportingGroupName'),
      });
      return;
    }
    createProductTypes([{ name: creatingProductType.name }]);
  }, [createProductTypes, form, showNotification, translate]);

  const sortedProductTypes = useMemo(
    () => orderBy(Object.values(form), ['createdAt'], ['asc']),
    [form],
  );

  return (
    <ScreenLayout
      title="Reporting Groups | Oolio"
      loading={loading}
      onSave={onPressSave}
      hideFooter={!changedData.length}
    >
      <Section
        layoutWidth="small"
        title={translate('backOfficeReportingGroups.reportingGroups')}
        subtitle={translate(
          'backOfficeReportingGroups.reportingGroupDescription',
        )}
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('backOfficeReportingGroups.reportingGroup')}
            </Text>
          </View>
          <View>
            {sortedProductTypes.map(item => (
              <ReportingGroupRow
                key={item.id}
                id={item.id}
                name={item.name}
                onChange={onChangeName}
                onDeleteProductType={deleteProductType}
                onPressCreate={onCreateProductType}
              />
            ))}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default ReportingGroups;
