import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  useTranslation,
  getFullFormattedPhoneNumber,
  getCurrencySymbol,
  parsePhoneNumber,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import {
  Customer,
  FeatureIDs,
  UpdateCustomerRequest,
} from '@oolio-group/domain';
import { DEFAULT_COUNTRY_CODE, FEATURES } from '../../../../../../constants';
import { Operation } from '../../../../../../types/Operation';
import { useNotification } from '../../../../../../hooks/Notification';
import { useCustomers } from '../../../../../../hooks/orders/useCustomers';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@oolio-group/rn-use-modal';
import { useSession } from '../../../../../../hooks/app/useSession';
import { countries } from 'countries-list';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../../../../components/Shared/Inputs/InputEmail';
import InputToggle from '../../../../../../components/Shared/Inputs/InputToggle';
import { isValidEmail } from '../../../../../../utils/validator';
import { useCheckFeatureEnabled } from '../../../../../../hooks/app/features/useCheckFeatureEnabled';
import { analyticsService } from '../../../../../../analytics/AnalyticsService';
import SelectDate from '../../../../../../components/Shared/Select/SelectDate';
import AddressForm from '../../../../../../components/Shared/Forms/AddressForm';

import { EnrollmentSource } from '@oolio-group/loyalty-sdk';

interface MenuRowProps {
  onChange: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy: (id: string) => void;
  customerInfo?: Customer;
  action: string;
  checkEmailExists: (email?: string, customerId?: string) => boolean;
}
interface ICustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  email: string;
  city: string;
  suburb?: string;
  postalCode: string;
  country: string;
  phoneCountry: string;
  phone: string;
  maxOrderLimit: number;
  maxBalanceLimit: number;
  currentBalance: number;
  accountPayment: boolean;
  state: string;
  loyaltyMember?: boolean;
  dob?: number;
  code?: string;
}

export const CustomerDetails: React.FC<MenuRowProps> = ({}: MenuRowProps) => {
  const [paymentEnabled, setPaymentEnable] = useState(false);
  const [accountPaymentEnabled, setAccountPayment] = useState(false);
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const [session] = useSession();
  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};
  const [isOolioLoyaltyFeatureEnabled, setOolioLoyaltyFeatureFlag] =
    useState<boolean>(false);

  useEffect(() => {
    (async function () {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.OOLIO_LOYALTY,
      );
      setOolioLoyaltyFeatureFlag(isEnabled);
    })();
  }, []);

  const routeParams = route.params as {
    customer: Customer;
  };
  const { customer: customerProp } = routeParams;

  const phoneCountry =
    parsePhoneNumber(customerProp?.phone ?? '')?.countryCode || country;

  const [form, setForm] = useState<ICustomerForm>({
    id: '',
    firstName: '',
    lastName: '',
    line1: '',
    line2: '',
    email: '',
    city: '',
    suburb: '',
    postalCode: '',
    country,
    phoneCountry: phoneCountry,
    phone: '',
    maxOrderLimit: 0,
    maxBalanceLimit: 0,
    currentBalance: 0,
    accountPayment: false,
    state: '',
    loyaltyMember: false,
    dob: 0,
    code: '',
  });
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCustomerAccountEnabled = isFeatureEnabled(
    FeatureIDs.CUSTOMER_ACCOUNT,
  );

  const action = useRef('');
  const customerInfo = customerProp;
  const {
    updateCustomer,
    loading,
    error,
    deleteCustomer,
    getCustomerById,
    customerMaps,
    enrollMemberLoyalty,
  } = useCustomers();

  const customer = customerMaps[customerProp.id];
  const removeCustomer = useCallback(
    async (customerId: string) => {
      action.current = Operation.DELETE;
      await deleteCustomer(customerId);
      navigation.navigate('ManageCustomers');
    },
    [deleteCustomer, navigation],
  );

  useEffect((): void => {
    getCustomerById(customerProp.id, true);
  }, [customerProp.id, getCustomerById]);

  const currencyCode = countries[country]?.currency;

  useEffect((): void => {
    if (customerProp?.customerAccountDetails?.accountPayment === true) {
      setPaymentEnable(true);
      setAccountPayment(true);
    } else {
      setPaymentEnable(false);
      setAccountPayment(false);
    }
  }, [customerProp?.customerAccountDetails?.accountPayment]);

  const togglePayment = useCallback((): void => {
    setPaymentEnable(prevCheck => !prevCheck);
    setAccountPayment(prevCheck => !prevCheck);
  }, []);

  useEffect(() => {
    if (customer) {
      const {
        id,
        firstName,
        lastName,
        email,
        preferredAddress,
        phoneNumber,
        loyaltyMember,
        dob,
        code,
      } = customer;
      setForm({
        id,
        firstName,
        lastName,
        line1: (preferredAddress && preferredAddress.line1) || '',
        line2: (preferredAddress && preferredAddress.line2) || '',
        city: (preferredAddress && preferredAddress.city) || '',
        suburb: (preferredAddress && preferredAddress.suburb) || '',
        state: (preferredAddress && preferredAddress.state) || '',
        postalCode: (preferredAddress && preferredAddress.postalCode) || '',
        country: preferredAddress?.isoCountryCode || country,
        phoneCountry: phoneCountry,
        phone: phoneNumber || '',
        email,
        maxOrderLimit: customer?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customer?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customer?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customer?.customerAccountDetails
          ?.accountPayment as boolean,
        loyaltyMember,
        dob,
        code,
      });
    }
  }, [customer, country, phoneCountry]);

  useEffect(() => {
    if (customerInfo && customerInfo.preferredAddress) {
      const { isoCountryCode, ...address } = customerInfo.preferredAddress;
      setForm({
        id: customerInfo.id,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        suburb: address.suburb,
        state: address.state,
        postalCode: address.postalCode,
        country: isoCountryCode ? isoCountryCode : DEFAULT_COUNTRY_CODE,
        phoneCountry: phoneCountry,
        phone: customerInfo.phone,
        email: customerInfo.email,
        maxOrderLimit: customerInfo?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customerInfo?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customerInfo?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customerInfo?.customerAccountDetails
          ?.accountPayment as boolean,
        loyaltyMember: customerInfo?.loyaltyMember,
        dob: customerInfo?.dob,
        code: customerInfo?.code,
      });
    }
  }, [action, customerInfo, phoneCountry]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  const onChangeFormInput = useCallback(
    (prop: string, value: string | number | boolean) => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    },
    [],
  );

  const onDelete = useCallback(
    (customerId: string, customerName: string): void => {
      const customer = customerMaps[customerId];
      if (
        customer.customerAccountDetails?.accountPayment &&
        customer.customerAccountDetails?.currentBalance
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeCustomers.customerDeleteWarning'),
        });
        return;
      }
      showModal(
        <ConfirmationModal
          title={translate('backOfficeCustomers.deletePopUpPromptHeader')}
          message={
            isOolioLoyaltyFeatureEnabled
              ? translate('backOfficeCustomers.deletePopUpPromptBody', {
                  customerName,
                })
              : translate('backOfficeCustomers.legacyDeletePopUpPromptBody', {
                  customerName,
                })
          }
          onConfirm={() => {
            closeModal();
            removeCustomer(customerId);
          }}
          {...(isOolioLoyaltyFeatureEnabled && {
            preConfirm: {
              label: translate('backOfficeCustomers.acknowledgmentLabel'),
            },
          })}
        />,
      );
    },
    [
      showModal,
      translate,
      closeModal,
      removeCustomer,
      customerMaps,
      showNotification,
      isOolioLoyaltyFeatureEnabled,
    ],
  );

  const onSaveCustomer = useCallback(async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      phoneCountry,
      loyaltyMember,
      dob,
    } = form;
    if (isOolioLoyaltyFeatureEnabled) {
      if (
        loyaltyMember &&
        !isValidPhoneNumber(getFullFormattedPhoneNumber(phoneCountry, phone))
      ) {
        showNotification({
          error: true,
          message: translate('customer.invalidPhoneMessage'),
        });
        return;
      }
    } else {
      if (
        !!phone &&
        !isValidPhoneNumber(getFullFormattedPhoneNumber(phoneCountry, phone))
      ) {
        showNotification({
          error: true,
          message: translate('customer.invalidPhoneMessage'),
        });
        return;
      }
    }
    if (!!email && !isValidEmail(email)) {
      showNotification({
        error: true,
        message: translate('customer.invalidEmailMessage'),
      });
      return;
    }
    const preferredAddress = {
      line1: form.line1,
      line2: form.line2,
      city: form.city,
      state: form.state,
      suburb: form.suburb,
      postalCode: form.postalCode,
      isoCountryCode: form.country,
    };
    const customerAccountDetails = {
      maxOrderLimit: Number(form.maxOrderLimit),
      maxBalanceLimit: Number(form.maxBalanceLimit),
      currentBalance: Number(form.currentBalance),
      accountPayment: paymentEnabled,
    };
    const customerInput = {
      id: form.id,
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(phoneCountry, phone),
      phoneNumber: phone.replace(/^0+/, ''),
      preferredAddress,
      customerAccountDetails,
      dob,
    } as UpdateCustomerRequest;
    await updateCustomer(customerInput);
    if (customerInfo.id && loyaltyMember) {
      if (customer && customer?.loyaltyMember !== loyaltyMember) {
        await enrollMemberLoyalty(
          customerInfo.id,
          EnrollmentSource.BACK_OFFICE,
        );
      }
    }
  }, [
    form,
    paymentEnabled,
    showNotification,
    translate,
    updateCustomer,
    customerInfo.id,
    customer,
    enrollMemberLoyalty,
    isOolioLoyaltyFeatureEnabled,
  ]);

  function handleDateChange(fieldName, date) {
    const dob = new Date(date).getTime();
    onChangeFormInput(fieldName, dob);
  }
  return (
    <ScreenLayout
      loading={loading}
      title="Customers | Oolio"
      onSave={onSaveCustomer}
      onDelete={() =>
        onDelete(
          customer?.id || '',
          `${customer?.firstName} ${customer?.lastName}` || '',
        )
      }
      onDeleteLabel={
        isOolioLoyaltyFeatureEnabled && translate('button.deleteCustomer')
      }
    >
      <Section title={translate('form.customerDetails')} layoutWidth="small">
        <View style={theme.forms.row}>
          <InputText
            testID="input-firstName"
            title={translate('form.firstName')}
            value={form?.firstName}
            placeholder={translate('form.firstName')}
            onChangeText={text => {
              onChangeFormInput('firstName', text);
            }}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-lastName"
            title={translate('form.lastName')}
            value={form?.lastName}
            placeholder={translate('form.lastName')}
            onChangeText={text => {
              onChangeFormInput('lastName', text);
            }}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputPhone
            testID="input-phone"
            title={translate('form.phone')}
            value={form?.phone}
            defaultCountry={form?.phoneCountry}
            onChangeText={onChangeFormInput.bind(null, 'phone')}
            onPressCountry={onChangeFormInput.bind(null, 'phoneCountry')}
            containerStyle={theme.forms.inputHalf}
            errorMessage={
              isOolioLoyaltyFeatureEnabled
                ? form?.loyaltyMember &&
                  (!form.phone ||
                    !isValidPhoneNumber(
                      getFullFormattedPhoneNumber(
                        form?.phoneCountry,
                        form.phone,
                      ),
                    ))
                  ? translate('customer.invalidPhoneMessage')
                  : undefined
                : undefined
            }
          />
          <InputEmail
            testID="input-email"
            title={translate('form.email')}
            value={form?.email}
            placeholder={translate('form.email')}
            onChangeText={onChangeFormInput.bind(null, 'email')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        {isOolioLoyaltyFeatureEnabled && (
          <View style={theme.forms.row}>
            <InputText
              testID="input-memberId"
              title={translate('form.memberId')}
              value={form?.code}
              editable={false}
              placeholder={translate('form.memberId')}
              containerStyle={theme.forms.inputHalf}
            />
            <SelectDate
              testID="input-dob"
              title={translate('form.dob')}
              valueDate={form.dob ? new Date(form.dob) : undefined}
              onChangeDate={value => handleDateChange('dob', value)}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        )}

        <AddressForm
          onChangeAddress={onChangeFormInput}
          value={{
            line1: form?.line1 || '',
            line2: form?.line2 || '',
            city: form?.city,
            suburb: form?.suburb || '',
            state: form?.state || '',
            postalCode: form?.postalCode || '',
            country: form?.country || '',
          }}
        />
      </Section>
      {isCustomerAccountEnabled && (
        <Section layoutWidth="small" title={translate('form.accountDetails')}>
          <View style={theme.forms.row}>
            <InputToggle
              type="switch"
              testID="toggle-payment"
              onToggle={togglePayment}
              isToggled={paymentEnabled}
              title={translate('form.enableAccountPayment')}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
          <View style={theme.forms.row}>
            <InputText
              editable={false}
              testID="input-currentBalance"
              title={translate('form.totalOutstanding')}
              value={
                form?.currentBalance ? form?.currentBalance.toString() : '0.00'
              }
              placeholder={translate('form.totalOutstanding')}
              onChangeText={onChangeFormInput.bind(null, 'currentBalance')}
              label={getCurrencySymbol(currencyCode)}
              containerStyle={theme.forms.inputHalf}
            />
            <InputText
              testID="input-state"
              title={translate('form.maxOrderLimit')}
              value={
                form.maxOrderLimit ? form.maxOrderLimit.toString() : undefined
              }
              placeholder={translate('form.maxOrderLimit')}
              onChangeText={onChangeFormInput.bind(null, 'maxOrderLimit')}
              label={getCurrencySymbol(currencyCode)}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
          <View style={theme.forms.row}>
            {accountPaymentEnabled && (
              <InputText
                testID="input-maxBalanceLimit"
                title={translate('form.maxCreditLimit')}
                value={
                  form?.maxBalanceLimit
                    ? form?.maxBalanceLimit.toString()
                    : undefined
                }
                placeholder={translate('form.maxCreditLimit')}
                onChangeText={onChangeFormInput.bind(null, 'maxBalanceLimit')}
                label={getCurrencySymbol(currencyCode)}
                containerStyle={theme.forms.inputHalf}
              />
            )}
          </View>
        </Section>
      )}
      {isOolioLoyaltyFeatureEnabled && !customer?.loyaltyMember && (
        <Section
          layoutWidth="small"
          title={translate('backOfficeCustomers.tabNames.loyalty')}
        >
          <InputToggle
            testID="toggle-loyaltyStatus"
            isToggled={form?.loyaltyMember || false}
            onToggle={onChangeFormInput.bind(
              null,
              'loyaltyMember',
              !form?.loyaltyMember,
            )}
            type="switch"
            title={translate('form.enrolToLoyalty')}
            subtitle={translate('form.enrolToLoyaltySubtitle')}
            containerStyle={theme.forms.inputFluid}
          />
        </Section>
      )}
    </ScreenLayout>
  );
};
