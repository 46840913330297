import { useSyncOrderEvents } from '../app/useSyncOrderEvents';
import {
  computeOrderState,
  generateDependentEvents,
  OrderItemsToTransfer,
} from '@oolio-group/order-helper';
import {
  App,
  AssignTableEvent,
  InitiateOrderEvent,
  Order,
  OrderAction,
  OrderEvent,
  OrderTransferItemsEvent,
  OrderTypeCode,
  Table,
} from '@oolio-group/domain';
import { useSession } from '../app/useSession';
import { userUtility } from '../../state/userUtility';
import { stripProperties } from '@oolio-group/client-utils';
import { keyBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { ORDERS_SAVE } from '../app/orders/graphql';
import { useOrders } from '../app/orders/useOrders';
import { useOrderNumber } from './useOrderNumber';
import { useTokenNumber } from './useTokenNumber';
import { useSalesChannels } from '../app/salesChannels/useSalesChannels';
import { useCallback, useMemo, useRef } from 'react';
import { TransferItem } from '../../components/POS/Modals/TransferPreview/TransferPreview';
import { generateOrderEvent } from '../../utils/orderEventHelper';
import { v4 as uuidv4 } from 'uuid';

interface useOrderTransferItemsInterface {
  transferOrderItems: ({
    sourceOrderItems,
    targetOrderId,
    targetTable,
  }: TransferOrderItemsResult) => Promise<void>;
}

interface TransferOrderItemsResult {
  sourceOrderItems: TransferItem[];
  targetTable?: Table;
  targetOrderId?: string;
  postTransfer?: (updatedOrders: Order[]) => void;
}

export function useOrderTransferItems(): useOrderTransferItemsInterface {
  const { getOrderFromCache } = useOrders();
  const { syncOrderEvents } = useSyncOrderEvents();
  const [session] = useSession();
  const { generate: generateOrderNumber } = useOrderNumber();
  const { getTokenNumber } = useTokenNumber();
  const { inStoreSaleChannel } = useSalesChannels();
  const postTransferRef = useRef<(updatedOrders: Order[]) => void>();
  const [cacheSourceAndTargetOrders] = useMutation(ORDERS_SAVE, {
    onCompleted: ({ saveOrders: sourceAndTargetOrders }) => {
      postTransferRef.current?.(sourceAndTargetOrders);
    },
  });

  const eventSourceAttributes = useMemo(
    () => ({
      organizationId: session.currentOrganization?.id,
      venueId: session.currentVenue?.id,
      deviceId: session.device?.id,
      storeId: session.currentStore?.id,
      triggeredBy: userUtility.posUser?.id || userUtility?.recentUserId,
      source: App.POS_APP,
    }),
    [session],
  );

  const generateInitiateOrderEvent = useCallback(
    async (orderTypeId = '') => {
      return generateOrderEvent<InitiateOrderEvent>(
        OrderAction.ORDER_INITIATE,
        eventSourceAttributes,
        {
          orderTypeId,
          orderNumber: await generateOrderNumber(),
          salesChannelId: inStoreSaleChannel?.id,
          tokenNumber: await getTokenNumber(),
          triggeredUserName: userUtility.posUser?.name,
          triggeredDeviceName: session.device?.name,
        },
      );
    },
    [
      eventSourceAttributes,
      generateOrderNumber,
      getTokenNumber,
      inStoreSaleChannel?.id,
      session.device?.name,
    ],
  );

  const generateAssignTableEvent = useCallback(
    (order: Order, table: Table) =>
      generateOrderEvent<AssignTableEvent>(
        OrderAction.ORDER_ASSIGN_TABLE,
        eventSourceAttributes,
        {
          previous: order?.prevEventId,
          orderId: order.id,
          tableId: table.id,
          tableName: table.name,
          sectionId: table.section?.id,
          sectionName: table?.section?.name,
        },
      ),
    [eventSourceAttributes],
  );

  const generateSaveOrderEvent = useCallback(
    (order: Order) =>
      generateOrderEvent(OrderAction.ORDER_SAVE, eventSourceAttributes, {
        previous: order?.prevEventId,
        orderId: order.id,
      }),
    [eventSourceAttributes],
  );

  const generateTransferItemsEvent = useCallback(
    (order: Order, sourceOrderItems: OrderItemsToTransfer[]) =>
      generateOrderEvent<OrderTransferItemsEvent>(
        OrderAction.ORDER_TRANSFER_ITEMS,
        eventSourceAttributes,
        {
          previous: order?.prevEventId,
          orderId: order.id,
          transferItemsTo: {
            orderId: order.id,
            tableId: order.table.id,
          },
          transferItemsFrom: sourceOrderItems,
          transferredByName: userUtility.posUser?.name || '',
        },
      ) as OrderTransferItemsEvent,
    [eventSourceAttributes],
  );

  const ensureTargetOrderExists = useCallback(
    async (
      events: OrderEvent[],
      targetOrderId: string,
      targetTable?: Table,
    ): Promise<Order> => {
      let targetOrder = getOrderFromCache(targetOrderId);
      if (targetOrder) return targetOrder;

      const dineInOrderTypeId = session.deviceProfile?.orderTypes?.find(
        orderType => orderType.code === OrderTypeCode.DINE_IN,
      )?.id;

      const initiateOrderEvent = await generateInitiateOrderEvent(
        dineInOrderTypeId,
      );
      targetOrder = computeOrderState([initiateOrderEvent]);
      events.push(initiateOrderEvent);

      if (targetTable) {
        const tableAssignEvent = generateAssignTableEvent(
          targetOrder,
          targetTable,
        );
        targetOrder = computeOrderState([tableAssignEvent], targetOrder);
        events.push(tableAssignEvent);
      }

      const saveOrderEvent = generateSaveOrderEvent(targetOrder);
      targetOrder = computeOrderState([saveOrderEvent], targetOrder);
      events.push(saveOrderEvent);

      return targetOrder;
    },
    [
      generateSaveOrderEvent,
      generateAssignTableEvent,
      generateInitiateOrderEvent,
      session.deviceProfile?.orderTypes,
      getOrderFromCache,
    ],
  );

  const processTargetOrder = useCallback(
    async (
      events: OrderEvent[],
      targetOrder: Order,
      sourceOrderItems: TransferItem[],
    ): Promise<{
      updatedTargetOrder: Order;
      transferItemsEvent: OrderTransferItemsEvent;
    }> => {
      const transferItemsEvent = generateTransferItemsEvent(
        targetOrder,
        sourceOrderItems.map(source => ({
          orderId: source.orderId,
          tableId: source.tableId,
          orderItems: source.orderItems.map(item => ({
            ...item,
            newTransferItemId: uuidv4(),
          })),
        })),
      );
      targetOrder = computeOrderState([transferItemsEvent], targetOrder);
      events.push(transferItemsEvent);

      const saveOrderEvent = generateSaveOrderEvent(targetOrder);
      targetOrder = computeOrderState([saveOrderEvent], targetOrder);
      events.push(saveOrderEvent);

      return {
        updatedTargetOrder: targetOrder,
        transferItemsEvent,
      };
    },
    [generateSaveOrderEvent, generateTransferItemsEvent],
  );

  const processSourceOrders = useCallback(
    async (
      events: OrderEvent[],
      sourceOrderItems: TransferItem[],
      transferItemsEvent: OrderEvent,
    ): Promise<Order[]> => {
      let sourceOrders = sourceOrderItems.map(source => {
        const order = getOrderFromCache(source.orderId);
        events.push(...source.pendingEvents);
        return computeOrderState(source.pendingEvents, order);
      });

      sourceOrders = sourceOrders.map(order => {
        const saveOrderEvent = generateSaveOrderEvent(order);
        events.push(saveOrderEvent);
        return computeOrderState([saveOrderEvent], order);
      });

      const mapSourceOrdersToIds = keyBy(sourceOrders, 'id');

      const transferredItemsEvent = generateDependentEvents(
        transferItemsEvent,
        mapSourceOrdersToIds,
      );
      sourceOrders = transferredItemsEvent.map(event => {
        const order = mapSourceOrdersToIds[event.orderId];
        events.push(event);
        return computeOrderState([event], order);
      });

      return sourceOrders;
    },
    [generateSaveOrderEvent, getOrderFromCache],
  );

  const transferOrderItems = useCallback(
    async ({
      sourceOrderItems = [],
      targetOrderId = '',
      targetTable,
      postTransfer,
    }: TransferOrderItemsResult): Promise<void> => {
      postTransferRef.current = postTransfer;
      const targetOrderEvents: OrderEvent[] = [];
      const sourceOrdersEvents: OrderEvent[] = [];

      const targetOrder = await ensureTargetOrderExists(
        targetOrderEvents,
        targetOrderId,
        targetTable,
      );

      const { updatedTargetOrder, transferItemsEvent } =
        await processTargetOrder(
          targetOrderEvents,
          targetOrder,
          sourceOrderItems,
        );

      const updatedSourceOrders = await processSourceOrders(
        sourceOrdersEvents,
        sourceOrderItems,
        transferItemsEvent,
      );

      cacheSourceAndTargetOrders({
        variables: { data: [...updatedSourceOrders, updatedTargetOrder] },
      });

      await syncOrderEvents(
        [...targetOrderEvents, ...sourceOrdersEvents].map(event =>
          stripProperties(event, '__typename'),
        ),
      );
    },
    [
      syncOrderEvents,
      cacheSourceAndTargetOrders,
      ensureTargetOrderExists,
      processSourceOrders,
      processTargetOrder,
    ],
  );

  return {
    transferOrderItems,
  };
}
