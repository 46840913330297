import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tileSize: {
    height: 70,
    flex: 1,
    flexGrow: 1,
    flexBasis: '18%',
  },
  tile: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.black,
  },
  back: {
    backgroundColor: theme.colors.white,
  },
  pagination: {
    marginTop: 4,
    marginLeft: 4,
  },
  tileText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
  },
  group: {
    position: 'absolute',
    width: 8,
    height: 8,
    borderRadius: 8,
    top: 6,
    right: 6,
    backgroundColor: theme.colors.white,
  },
  colour: {
    position: 'absolute',
    left: 4,
    right: 4,
    bottom: 4,
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.colors.accent,
  },
});

const positive = ['CARD PAY', 'CASH PAY', 'EXACT CASH'];
const focus = [
  'SWITCH MENU',
  'SWITCH COURSE',
  'HOLD PRINT',
  'Switch Price List',
  'TRANSFER',
];
const negative = [
  'VOID',
  'END SHIFT',
  'SYSTEM LOGOUT',
  'REMOVE ALL DISCOUNTS',
  'REMOVE ALL SURCHARGE',
];

export const getBarColor = (name: string): string => {
  if (positive.includes(name)) {
    return theme.colors.states.positive;
  } else if (focus.includes(name)) {
    return theme.colors.states.focus;
  } else if (negative.includes(name)) {
    return theme.colors.states.negative;
  } else {
    return theme.colors.states.neutral;
  }
};

export const getTileMargins = (position: number) => {
  const isSecondRow = position >= 5;
  const isFirstColumn = position === 0 || position === 5;

  return {
    marginTop: isSecondRow ? 4 : 0,
    marginLeft: isFirstColumn ? 0 : 4,
  };
};

export default styles;
