import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Connection,
  Customer,
  LoyaltyActivityLog,
  LoyaltyActivityType,
  LoyaltyEnrolmentSource,
} from '@oolio-group/domain';
import { View, Text, ActivityIndicator } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import {
  Activity,
  LoyaltyApi,
  LoyaltyProgram,
  MemberActivityAction,
  RewardRule,
} from '@oolio-group/loyalty-sdk';
import { useModal } from '@oolio-group/rn-use-modal';
import { formatToDigitsStr, getLoyaltyUnit } from '@oolio-group/client-utils';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Operation } from '../../../../../../types/Operation';
import { useCustomers } from '../../../../../../hooks/orders/useCustomers';
import { useLoyalty } from '../../../../../../hooks/app/loyalty/useLoyalty';
import { format } from 'date-fns';
import theme from '../../../../../../common/default-theme';
import styles from './CustomerLoyalty.styles';
import { Icon, IconProps } from '../../../../../../components/Icon/Icon';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import { tokenUtility } from '../../../../../../state/tokenUtility';
import { getSession } from '../../../../../../state/preferences';
import { analyticsService } from '../../../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../../../constants';
import { useStores } from '../../../../../../hooks/app/useStores';

interface CustomerForm {
  id: string;
  loyaltyPointsBalance: number;
}

const ACTIVITY_TYPES = [
  LoyaltyActivityType.EARNED_POINTS,
  LoyaltyActivityType.REDEEMED_REWARDS,
  LoyaltyActivityType.REFUNDED_POINTS,
  LoyaltyActivityType.REFUNDED_REWARDS,
];

const LOYALTY_ACTIVITY_TYPES = [
  MemberActivityAction.ADJUSTED,
  MemberActivityAction.DELISTED,
  MemberActivityAction.EARNED_POINTS,
  MemberActivityAction.ENROLLED,
  MemberActivityAction.REDEEMED_REWARDS,
  MemberActivityAction.REFUNDED_POINTS,
  MemberActivityAction.REFUNDED_REWARDS,
];

const LOG_PAGE_SIZE = 10;

export const CustomerLoyalty: React.FC = () => {
  const action = useRef('');
  const latestCursor = useRef('');
  const currentPageRef = useRef(0);
  const [loyaltyApi, setLoyaltyApi] = useState<LoyaltyApi>();
  const [program, setProgram] = useState<LoyaltyProgram>();
  const [availableRewards, setAvailableRewards] = useState<Array<RewardRule>>();
  const [isOolioLoyaltyFeatureEnabled, setOolioLoyaltyFeatureFlag] =
    useState<boolean>(false);
  const [memberActivity, setMemberActivity] = useState<Array<Activity>>();
  const route = useRoute();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { stores, getStores, loading: storesLoading } = useStores();
  const {
    loading,
    customerMaps,
    updateCustomer,
    delistLoyaltyCustomer,
    enrollCustomerLoyalty,
  } = useCustomers();
  const {
    rewardRules,
    loyaltySettings,
    getLoyaltyPrograms,
    paginatedLoyaltyActivityLogs,
    getPaginatedLoyaltyActivityLogs,
    loading: activityLoading,
  } = useLoyalty();

  const routeParams = route.params as {
    customer: Customer;
  };
  const { customer: customerInfo } = routeParams;

  useEffect(() => {
    if (!isOolioLoyaltyFeatureEnabled) return;
    (async () => {
      const session = await getSession();
      const api = new LoyaltyApi({
        token: tokenUtility.token ?? '',
        baseUrl: process.env.REACT_APP_LOYALTY_API_URL || '',
        organization: session?.currentOrganization?.id ?? '',
      });
      setLoyaltyApi(api);
    })();
  }, [isOolioLoyaltyFeatureEnabled]);

  useEffect(() => {
    if (!loyaltyApi || !customerInfo?.id) return;
    (async () => {
      const program = await loyaltyApi.getLoyaltyProgram();
      if (program) {
        setProgram(program);
      }
      const availableRewards = await loyaltyApi.getMemberAvailableRewards(
        customerInfo?.id,
      );
      if (rewardRules) {
        setAvailableRewards(availableRewards.availableRewards);
      }
      const memberActivity = await loyaltyApi.getMemberActivity(
        customerInfo?.id,
      );
      if (memberActivity) {
        setMemberActivity(memberActivity?.items.reverse());
      }
      getStores();
    })();
  }, [loyaltyApi, customerInfo.id, rewardRules, customerInfo, getStores]);

  const [currentPage, setCurrentPage] = useState(0);
  const [form, setForm] = useState<CustomerForm>({
    id: '',
    loyaltyPointsBalance: 0,
  });

  const [activityPages, setActivityPages] = useState<{
    [key: number]: Connection<LoyaltyActivityLog>;
  }>({});

  useEffect(() => {
    (async function () {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.OOLIO_LOYALTY,
      );
      setOolioLoyaltyFeatureFlag(isEnabled);
    })();
  }, []);

  useEffect(() => {
    if (rewardRules.length === 0) {
      getLoyaltyPrograms();
    }
  }, [getLoyaltyPrograms, rewardRules]);

  useEffect(() => {
    if (!Object.keys(activityPages).length) {
      // only trigger when activity pages is empty
      getPaginatedLoyaltyActivityLogs({
        variables: {
          first: LOG_PAGE_SIZE,
          after: latestCursor.current,
          filter: {
            customerId: customerInfo?.id,
          },
        },
      });
    }
  }, [getPaginatedLoyaltyActivityLogs, customerInfo?.id, activityPages]);

  useEffect(() => {
    if (paginatedLoyaltyActivityLogs) {
      setActivityPages(previousPages => {
        // edges need to be reversed to be in descending order
        return {
          ...previousPages,
          [currentPageRef.current]: {
            ...paginatedLoyaltyActivityLogs,
            edges: [...paginatedLoyaltyActivityLogs.edges].reverse(),
          },
        };
      });

      if (paginatedLoyaltyActivityLogs.pageInfo?.endCursor) {
        latestCursor.current = paginatedLoyaltyActivityLogs.pageInfo.endCursor;
      }
    }
  }, [paginatedLoyaltyActivityLogs]);

  const onPressNextPage = () => {
    const nextPage = currentPage + 1;
    if (
      !activityPages[nextPage] &&
      activityPages[currentPage]?.pageInfo.hasNextPage
    ) {
      // fetch new page
      getPaginatedLoyaltyActivityLogs({
        variables: {
          first: LOG_PAGE_SIZE,
          after: latestCursor.current,
          filter: {
            customerId: customerInfo.id,
          },
        },
      });
    }
    setCurrentPage(nextPage);
    currentPageRef.current = nextPage;
  };

  const onPressPrevPage = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    currentPageRef.current = prevPage;
  };

  const delistCustomer = useCallback(
    async (customerId: string) => {
      action.current = Operation.DELETE;
      await delistLoyaltyCustomer(customerId, isOolioLoyaltyFeatureEnabled);
      navigation.navigate('ManageCustomers');
    },
    [delistLoyaltyCustomer, navigation, isOolioLoyaltyFeatureEnabled],
  );

  useEffect(() => {
    if (customerInfo.id) {
      setForm({
        id: customerInfo.id,
        loyaltyPointsBalance: customerInfo.loyaltyPointsBalance as number,
      });
    }
  }, [customerInfo]);

  const onChangeFormInput = useCallback((prop: string, value: number) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onPressDelist = useCallback(
    (customerId: string, customerName: string): void => {
      showModal(
        <ConfirmationModal
          title={translate('backOfficeCustomers.delistPopUpPromptHeader')}
          message={
            isOolioLoyaltyFeatureEnabled
              ? translate('backOfficeCustomers.delistPopUpPromptBody', {
                  firstName: customerName,
                  pluralTerm: program?.pluralTerm ?? 'Points',
                })
              : translate('backOfficeCustomers.legacyDelistPopUpPromptBody', {
                  customerName,
                })
          }
          onConfirm={() => {
            closeModal();
            delistCustomer(customerId);
          }}
          {...(isOolioLoyaltyFeatureEnabled && {
            preConfirm: {
              label: translate('backOfficeCustomers.acknowledgmentLabel'),
            },
          })}
        />,
      );
    },
    [
      showModal,
      translate,
      closeModal,
      delistCustomer,
      program?.pluralTerm,
      isOolioLoyaltyFeatureEnabled,
    ],
  );

  const onPressSave = useCallback(async () => {
    if (
      !customerInfo?.loyaltyMember &&
      form.loyaltyPointsBalance > 0 &&
      (!customerInfo?.loyaltyPointsBalance ||
        customerInfo?.loyaltyPointsBalance == 0)
    ) {
      await enrollCustomerLoyalty({
        customerId: customerInfo.id,
        loyaltyEnrolmentSource: LoyaltyEnrolmentSource.BACK_OFFICE,
      });
    }

    const customerInput = {
      id: form.id,
      loyaltyPointsBalance: form.loyaltyPointsBalance as number,
    };

    updateCustomer(customerInput, { balanceUpdate: true });
  }, [
    customerInfo.id,
    customerInfo?.loyaltyMember,
    customerInfo?.loyaltyPointsBalance,
    enrollCustomerLoyalty,
    form?.id,
    form.loyaltyPointsBalance,
    updateCustomer,
  ]);

  useEffect(() => {
    if (customerMaps[customerInfo.id]) {
      navigation.setParams({ customer: customerMaps[customerInfo.id] });
    }
  }, [customerMaps, navigation, customerInfo.id]);

  const customerRewards = rewardRules.filter(
    reward =>
      reward.pointsRequired <= (customerInfo?.loyaltyPointsBalance || 0),
  );

  // added loyaltySettings as a fallback
  const loyaltyProgram = isOolioLoyaltyFeatureEnabled
    ? program || loyaltySettings
    : loyaltySettings;

  const loyaltyActivity = isOolioLoyaltyFeatureEnabled
    ? memberActivity
    : activityPages[currentPage]?.edges;

  //TODO: Remove this after old loyalty is discarded
  const getActivityIcon = useCallback(
    (activityType: LoyaltyActivityType): IconProps => {
      switch (activityType) {
        case LoyaltyActivityType.EARNED_POINTS:
          return {
            name: 'star',
            color: theme.colors.green,
          };
        case LoyaltyActivityType.REDEEMED_REWARDS:
          return {
            name: 'gift',
            color: theme.colors.blue,
          };
        case LoyaltyActivityType.ENROLLED:
          return {
            name: 'plus-circle',
            color: theme.colors.green,
          };
        case LoyaltyActivityType.DELISTED:
          return {
            name: 'minus-circle',
            color: theme.colors.red,
          };
        case LoyaltyActivityType.ADJUSTED:
          return {
            name: 'info-circle',
            color: theme.colors.orange,
          };
        case LoyaltyActivityType.REFUNDED_POINTS:
          return {
            name: 'info-circle',
            color: theme.colors.orange,
          };
        case LoyaltyActivityType.REFUNDED_REWARDS:
          return {
            name: 'info-circle',
            color: theme.colors.orange,
          };
        default:
          return {
            name: 'info-circle',
            color: theme.colors.orange,
          };
      }
    },
    [],
  );

  const loyaltyActivityIconMap = useMemo(() => {
    return {
      [MemberActivityAction.EARNED_POINTS]: {
        name: 'star',
        color: theme.colors.green,
      },
      [MemberActivityAction.REDEEMED_REWARDS]: {
        name: 'gift',
        color: theme.colors.blue,
      },
      [MemberActivityAction.ENROLLED]: {
        name: 'plus-circle',
        color: theme.colors.green,
      },
      [MemberActivityAction.DELISTED]: {
        name: 'minus-circle',
        color: theme.colors.red,
      },
      [MemberActivityAction.ADJUSTED]: {
        name: 'info-circle',
        color: theme.colors.orange,
      },
      [MemberActivityAction.REFUNDED_POINTS]: {
        name: 'info-circle',
        color: theme.colors.orange,
      },
      [MemberActivityAction.REFUNDED_REWARDS]: {
        name: 'info-circle',
        color: theme.colors.orange,
      },
    };
  }, []);

  const formatPoints = (points?: number) => {
    return (points || 0) >= 0 ? `+${points}` : points;
  };

  //TODO: Remove this after old loyalty is discarded
  const formatActivityDetail = useCallback(
    (activity: LoyaltyActivityLog) => {
      switch (activity.activityType) {
        case LoyaltyActivityType.ENROLLED:
          return translate('customerLoyaltyActivity.enrolled');
        case LoyaltyActivityType.DELISTED:
          return translate('customerLoyaltyActivity.delisted');
        case LoyaltyActivityType.ADJUSTED:
          return translate('customerLoyaltyActivity.adjusted', {
            points: formatPoints(activity.pointsChanged),
            unit: getLoyaltyUnit(activity.pointsChanged!, loyaltySettings),
          });
        case LoyaltyActivityType.EARNED_POINTS:
          return translate('customerLoyaltyActivity.earnedPoints', {
            points: formatPoints(activity.pointsChanged),
            unit: getLoyaltyUnit(activity.pointsChanged!, loyaltySettings),
          });
        case LoyaltyActivityType.REDEEMED_REWARDS:
          return translate('customerLoyaltyActivity.redeemedRewards', {
            reward: activity.reward?.rewardName,
          });
        case LoyaltyActivityType.REFUNDED_POINTS:
          return translate('customerLoyaltyActivity.refundedPoints', {
            points: formatPoints(activity.pointsChanged),
            unit: getLoyaltyUnit(activity.pointsChanged!, loyaltySettings),
          });
        default:
          return activity.activityType;
      }
    },
    [translate, loyaltySettings],
  );

  const formatMemberActivityDetail = useCallback(
    (activity: Activity) => {
      switch (activity.action) {
        case MemberActivityAction.ENROLLED:
          return translate('customerLoyaltyActivity.enrolled');
        case MemberActivityAction.DELISTED:
          return translate('customerLoyaltyActivity.delisted');
        case MemberActivityAction.ADJUSTED:
          return translate('customerLoyaltyActivity.adjusted', {
            points: formatPoints(activity?.balanceChange),
            unit: getLoyaltyUnit(activity.balanceChange!, loyaltyProgram),
          });
        case MemberActivityAction.EARNED_POINTS:
          return translate('customerLoyaltyActivity.earnedPoints', {
            points: formatPoints(activity.balanceChange),
            unit: getLoyaltyUnit(activity.balanceChange!, loyaltyProgram),
          });
        case MemberActivityAction.REFUNDED_POINTS:
          return translate('customerLoyaltyActivity.refundedPoints', {
            points: formatPoints(activity.balanceChange),
            unit: getLoyaltyUnit(activity.balanceChange!, loyaltyProgram),
          });
        case MemberActivityAction.REDEEMED_REWARDS:
          return translate('customerLoyaltyActivity.redeemedRewards', {
            reward: activity.payload.appliedReward.reward?.name,
          });
        case MemberActivityAction.REFUNDED_REWARDS:
          return translate('customerLoyaltyActivity.refundedRewards', {
            reward: activity.payload.appliedReward.reward?.name,
          });
      }
    },
    [translate, loyaltyProgram],
  );

  //TODO: Remove this after old loyalty is discarded
  const renderActivityLogRow = useCallback(
    (activity: LoyaltyActivityLog) => {
      return (
        <View
          testID="activity-row"
          key={activity.id}
          style={styles.rowActivity}
        >
          <Icon size={24} {...getActivityIcon(activity.activityType)} />
          <View style={styles.colActivity}>
            <Text>{formatActivityDetail(activity)}</Text>
            {activity.store &&
              ACTIVITY_TYPES.includes(activity?.activityType) && (
                <Text testID="activity-store" style={styles.textStore}>
                  {activity.store.name}
                </Text>
              )}
          </View>
          <Text>{format(activity.timestamp, 'dd/MM/yy hh:mm a')}</Text>
        </View>
      );
    },
    [getActivityIcon, formatActivityDetail],
  );

  const renderLoyaltyActivityLogRow = useCallback(
    (activity: Activity) => {
      return (
        <View
          testID="activity-row"
          key={activity.timestamp}
          style={styles.rowActivity}
        >
          <Icon size={24} {...loyaltyActivityIconMap[activity.action]} />
          <View style={styles.colActivity}>
            <Text>{formatMemberActivityDetail(activity)}</Text>
            {activity.locationId &&
              LOYALTY_ACTIVITY_TYPES.includes(activity?.action) && (
                <Text testID="activity-store" style={styles.textStore}>
                  {stores[activity.locationId]?.name}
                </Text>
              )}
          </View>
          <Text>{format(activity.timestamp, 'dd/MM/yy hh:mm a')}</Text>
        </View>
      );
    },
    [loyaltyActivityIconMap, formatMemberActivityDetail, stores],
  );

  const rewardsToDisplay = isOolioLoyaltyFeatureEnabled
    ? availableRewards
    : customerRewards;

  return (
    <ScreenLayout
      loading={loading}
      title="Customers | Oolio"
      onSave={onPressSave}
      onDelete={() => onPressDelist(customerInfo?.id, customerInfo?.firstName)}
      onDeleteLabel={
        isOolioLoyaltyFeatureEnabled
          ? translate('button.deleteMembership')
          : translate('button.delist')
      }
      onDeleteDisabled={!customerInfo?.loyaltyMember}
    >
      <Section>
        <View>
          <Text style={styles.pointsTitle}>
            {customerInfo.loyaltyPointsBalance}
          </Text>
          <Text style={styles.pointsSubtitle}>
            {translate('form.loyalty.pointsBalance', {
              unit: loyaltyProgram?.pluralTerm,
            })}
          </Text>
        </View>
      </Section>
      <Section title="Details">
        <View style={theme.forms.row}>
          <InputText
            testID="form-adjustPoints"
            title={translate('form.loyalty.adjustPoints', {
              unit: loyaltyProgram?.pluralTerm,
            })}
            value={form?.loyaltyPointsBalance?.toString()}
            placeholder={translate('form.loyalty.adjustPoints', {
              unit: loyaltyProgram?.pluralTerm,
            })}
            onChangeText={text => {
              onChangeFormInput(
                'loyaltyPointsBalance',
                Number(formatToDigitsStr(text, false)),
              );
            }}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="form-lifetimeLoyaltyPoints"
            editable={false}
            title={translate('form.loyalty.lifetimeLoyaltyPoints', {
              unit: loyaltyProgram?.pluralTerm,
            })}
            value={customerInfo.lifetimeLoyaltyPoints?.toString() || '0'}
            placeholder={translate('form.loyalty.lifetimeLoyaltyPoints', {
              unit: loyaltyProgram?.pluralTerm,
            })}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <InputText
            testID="input-memberSince"
            editable={false}
            title={translate('form.loyalty.memberSince')}
            value={
              (customerInfo.enrolledAt &&
                format(customerInfo?.enrolledAt, 'PPP')) ||
              'N/A'
            }
            placeholder={translate('form.loyalty.memberSince')}
            containerStyle={theme.forms.inputHalf}
          />
          <InputText
            testID="input-enrolledThrough"
            editable={false}
            title={translate('form.loyalty.enrolledThrough')}
            value={customerInfo.loyaltyEnrolmentSource}
            placeholder={translate('form.loyalty.enrolledThrough')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
      </Section>
      <Section title={translate('form.loyalty.headers.availableRewards')}>
        {rewardsToDisplay && rewardsToDisplay?.length > 0 ? (
          rewardsToDisplay &&
          rewardsToDisplay.map(reward => (
            <View key={reward.id} style={styles.rowReward}>
              <Icon size={24} name="star" color={theme.colors.blue} />
              <Text style={styles.textReward}>
                {`${
                  isOolioLoyaltyFeatureEnabled ? reward.name : reward.rewardName
                } (${reward.pointsRequired} ${getLoyaltyUnit(
                  reward.pointsRequired,
                  loyaltyProgram,
                )})`}
              </Text>
            </View>
          ))
        ) : (
          <View style={theme.tables.emptyView}>
            <Text testID="noRewards" style={theme.tables.emptyText}>
              {translate('customerLoyalty.NoRewardsAvailable')}
            </Text>
          </View>
        )}
      </Section>
      <Section title={translate('form.loyalty.headers.activityLog')}>
        <>
          <View style={theme.tables.header}>
            <Text style={theme.tables.headerText}>
              {translate('form.loyalty.headers.activity')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerTimestamp]}>
              {translate('form.loyalty.headers.timestamp')}
            </Text>
          </View>
          {activityLoading || storesLoading ? (
            <View style={theme.tables.emptyView}>
              <ActivityIndicator />
            </View>
          ) : loyaltyActivity?.length === 0 ? (
            <View style={theme.tables.emptyView}>
              <Text testID="empty-activities" style={theme.tables.emptyText}>
                {translate('customerLoyaltyActivity.noActivities')}
              </Text>
            </View>
          ) : !isOolioLoyaltyFeatureEnabled ? (
            activityPages[currentPage]?.edges?.map(edge =>
              renderActivityLogRow(edge.node),
            )
          ) : (
            memberActivity?.map(activity =>
              renderLoyaltyActivityLogRow(activity),
            )
          )}
          <View style={styles.pagination}>
            <View style={styles.btnPagination}>
              <ButtonIcon
                testID="prev-page-button"
                size={34}
                type="cancel"
                icon="arrow-left"
                onPress={onPressPrevPage}
                disabled={currentPage === 0}
              />
              <ButtonIcon
                testID="next-page-button"
                size={34}
                type="cancel"
                icon="arrow-right"
                onPress={onPressNextPage}
                disabled={
                  !(activityPages[currentPage]?.pageInfo?.hasNextPage ?? false)
                }
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={{ marginLeft: 6 }}
              />
            </View>
          </View>
        </>
      </Section>
    </ScreenLayout>
  );
};
