import { useLazyQuery } from '@apollo/client/react/hooks';
import {
  Page,
  Product,
  EntityType,
  ProductMaps,
  PageMaps,
  PageItemMaps,
  VariantMaps,
  Catalogue,
  CatalogueType,
  CatalogueItem,
} from '@oolio-group/domain';

import { useEffect, useMemo, useCallback, useState } from 'react';
import { GET_MENU_OPTIONS, GET_PRODUCTS } from './graphql';
import { parseApolloError } from '../../../utils/errorHandlers';
import { keyBy, sortBy } from 'lodash';
import { filterEntityByStore } from '@oolio-group/client-utils';
import fetch, { Headers } from 'cross-fetch';
import { useSession } from '../useSession';
import { REACT_APP_CATALOGUE_API_URL } from 'react-native-dotenv';
import { useNotification } from '../../Notification';
import { translate } from '@oolio-group/localization';
import { inventoryProductFragment, useProducts } from '../products/useProducts';
import { useApolloClient } from '@apollo/client/react/hooks';
import { tokenUtility } from '../../../state/tokenUtility';
import { getExpiresAfter } from '../../../state/preferences';
import { refreshTokenAccessToken } from '../../../utils/refreshTokenAccessToken';
import { useLogout } from '../useLogout';
import { catalogueUtility } from '../../../state/catalogueUtility';
import { distinctUntilChanged, pluck } from 'rxjs';

const getNestedPages = (page: Page, memos: PageMaps) => {
  if (!page?.id || memos[page?.id]) return {};
  const updatePageMaps = { ...memos, [page.id]: page };
  if (!page.pages?.length) return updatePageMaps;
  return page.pages.reduce((pageMaps, page) => {
    pageMaps = { ...pageMaps, ...getNestedPages(page, pageMaps) };
    return pageMaps;
  }, updatePageMaps);
};

const getAllPageMaps = (menuData?: Catalogue) => {
  return (menuData?.items || []).reduce((pageMaps, item) => {
    pageMaps = { ...pageMaps, ...getNestedPages(item.page, pageMaps) };
    return pageMaps;
  }, {} as PageMaps);
};

type CatalogFilterType = {
  store?: string;
  menuId?: string;
  // to save unecessary state update. this flag will help update state (catalogue,producMaps,variantMaps....) only when it was provide.
  subscribeToData?: boolean;
};
export interface UseCatalogueProps {
  productMaps: ProductMaps;
  variantMaps: VariantMaps;
  allPageItemMaps: PageItemMaps;
  allNestedPages: Page[];
  error: string | undefined;
  loading?: boolean;
  refetch: (forceUpdate?: boolean) => void;
  catalogue?: Catalogue;
  getAllMenuOptions: () => void;
  menuOptions: Catalogue[];
  sortedMenuItems: CatalogueItem[];
  getCatalogueById: () => void;
}
/**
 * Creates pricing dictionary
 * TODO: Remove this once catalog service is built
 * TODO: Create Catalog specific interfaces and data
 * Constraints: data needs to contain all pages including the ones nested in other pages
 * @param data pages list
 */
export function filterSellableProducts(products: Product[]): Product[] {
  return products.filter(product => product?.isSellable);
}

export function useCatalogue(
  catalogFilter?: CatalogFilterType,
): UseCatalogueProps {
  const [catalogueLoading, setCatalogueLoading] = useState(false);
  const [catalogueData, setCatalogueData] = useState<Catalogue | undefined>();
  const [session] = useSession();
  const { showNotification } = useNotification();
  const client = useApolloClient();
  const { logout } = useLogout();

  const { menuId, store, subscribeToData } = catalogFilter || {};

  const {
    getAllProducts: getProductsInventory,
    // all inventory will fetch from server and map with cached catalogue data
    products: productStoreInventoryMaps,
  } = useProducts(undefined, inventoryProductFragment);

  const [getMenusReq, getMenusRes] = useLazyQuery<{ catalogues: Catalogue[] }>(
    GET_MENU_OPTIONS,
    { fetchPolicy: 'cache-and-network' },
  );

  const getMenuById = useCallback(
    async (catalogueId: string, forceUpdate?: boolean) => {
      if ((tokenUtility.expiresAfter as number) < getExpiresAfter(0)) {
        await refreshTokenAccessToken().catch(logout);
      }
      try {
        catalogueUtility.onStartFetchingCatalogue();
        const catalogueUrl =
          REACT_APP_CATALOGUE_API_URL ||
          process.env['REACT_APP_CATALOGUE_API_URL'];
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', tokenUtility.token as string);
        headers.append(
          'organization',
          session?.currentOrganization?.id as string,
        );

        headers.append('device', session?.device?.id as string);
        // store need to for fetching the storeInventory
        headers.append('store', session?.currentStore?.id as string);
        const response = await fetch(catalogueUrl + '/catalogue', {
          method: 'POST',
          headers,
          body: JSON.stringify({
            id: catalogueId,
            forceUpdate,
          }),
        });
        const data = await response.json();
        if (response?.ok) {
          const catalogue = data?.catalogue as Catalogue;
          catalogueUtility.onFetchSuccess(catalogue);
          // update cache for products which used for printing purpose
          const pageMaps = getAllPageMaps(catalogue);
          const productMaps = Object.values(pageMaps).reduce((maps, page) => {
            const { products = [], variants = [] } = page;
            const allVariantProducts = variants
              .map(v => v.products || [])
              .flat();
            Object.assign(
              maps,
              keyBy(products.concat(allVariantProducts), 'id'),
            );
            return maps;
          }, {} as Record<string, Product>);

          client.cache.writeQuery<{ products: Product[] }>({
            query: GET_PRODUCTS,
            data: { products: Object.values(productMaps) },
          });
          return;
        }
        throw new Error(data?.message);
      } catch (error) {
        const errorMessage =
          (error as { message: string })?.message ||
          translate('menus.failedToFetchMenu');
        catalogueUtility.onFetchError(errorMessage);
        showNotification({
          error: true,
          message: errorMessage,
        });
      }
    },
    [
      logout,
      session?.currentOrganization?.id,
      session?.device?.id,
      session?.currentStore?.id,
      client.cache,
      showNotification,
    ],
  );

  const getProductWithInventory = useCallback(
    (product: Product) => ({
      ...product,
      ...productStoreInventoryMaps[product.id],
    }),
    [productStoreInventoryMaps],
  );

  const allPageMaps = useMemo(() => {
    const rawPageMaps = getAllPageMaps(catalogueData);
    // add store inventory in all Products
    return Object.values(rawPageMaps).reduce((maps, page) => {
      const { products = [], variants = [] } = page;
      page.products = products.map(getProductWithInventory);
      page.variants = variants.map(variant => {
        variant.products = (variant.products || []).map(
          getProductWithInventory,
        );
        return variant;
      });
      maps[page.id] = page;
      return maps;
    }, {} as PageMaps);
  }, [getProductWithInventory, catalogueData]);

  const allProductMaps = useMemo(() => {
    return Object.values(allPageMaps).reduce((productMaps, page) => {
      page.products?.forEach(product => {
        if (!productMaps[product.id]) productMaps[product.id] = product;
      });
      return productMaps;
    }, {} as ProductMaps);
  }, [allPageMaps]);

  const allVariantMaps = useMemo(() => {
    return Object.values(allPageMaps).reduce((variantMaps, page) => {
      page.variants?.forEach(variant => {
        if (!variantMaps[variant.id]) {
          const atleastOneSellableProduct = (variant?.products || []).find(
            x => x?.isSellable,
          );
          if (atleastOneSellableProduct?.id) {
            variantMaps[variant.id] = variant;
          }
        }
      });
      return variantMaps;
    }, {} as VariantMaps);
  }, [allPageMaps]);

  const filteredVariantMaps = useMemo(() => {
    const variantByStores = filterEntityByStore(
      Object.values(allVariantMaps),
      store,
    );
    return keyBy(variantByStores, 'id');
  }, [allVariantMaps, store]);

  const filteredProductMaps = useMemo(() => {
    const productsByStore = filterEntityByStore(
      Object.values(allProductMaps),
      store,
    );
    const validVariantProducts = Object.values(filteredVariantMaps).flatMap(
      variant => variant.products,
    );

    const allSellableProducts = [
      ...productsByStore,
      ...validVariantProducts,
    ].filter(product => product.isSellable);

    return keyBy(allSellableProducts, 'id');
  }, [allProductMaps, filteredVariantMaps, store]);

  const allPageItemMaps = useMemo(() => {
    return Object.assign(
      {},
      allPageMaps,
      filteredProductMaps,
      filteredVariantMaps,
    );
  }, [allPageMaps, filteredProductMaps, filteredVariantMaps]);

  const canShowPage = useCallback((page?: Page) => {
    const productItems = page?.items?.filter(
      item =>
        item.entityType === EntityType.Product ||
        item.entityType === EntityType.Variant,
    );
    const nestedPages = page?.pages;
    const doesAnyNestedPageHasItems = nestedPages?.some(
      page => page.items?.length,
    );
    return Boolean(productItems?.length || doesAnyNestedPageHasItems);
  }, []);

  const allNestedPages = useMemo(() => {
    return Object.values(allPageMaps)
      .filter(canShowPage)
      .map(page => {
        const { products = [], variants = [] } = page;
        return {
          ...page,
          products: filterEntityByStore(products, store),
          variants: filterEntityByStore(variants, store),
        };
      });
  }, [allPageMaps, canShowPage, store]);

  const sortedMenuItems = useMemo(() => {
    if (!catalogueData?.items) return [];
    return sortBy(catalogueData?.items, item => item.priority).filter(
      catalogueItem => canShowPage(catalogueItem.page),
    );
  }, [canShowPage, catalogueData?.items]);

  const refetch = useCallback(
    (forceUpdate?: boolean) => {
      if (menuId) {
        getMenuById(menuId, forceUpdate);
        getProductsInventory();
      }
    },
    [menuId, getProductsInventory, getMenuById],
  );

  const getAllMenuOptions = useCallback(() => {
    getMenusReq({});
  }, [getMenusReq]);

  const getCatalogueById = useCallback(() => {
    if (!menuId) return;
    getMenuById(menuId);
  }, [getMenuById, menuId]);

  const loading = catalogueLoading || getMenusRes.loading;
  const error = getMenusRes.error;

  const menuOptions = useMemo(() => {
    return filterEntityByStore(
      (getMenusRes.data?.catalogues || []).filter(
        menu => menu.catalogueType === CatalogueType.POS,
      ),
      catalogFilter?.store,
    );
  }, [catalogFilter?.store, getMenusRes.data?.catalogues]);

  useEffect(() => {
    if (!subscribeToData) return;
    getProductsInventory();
  }, [getProductsInventory, subscribeToData]);

  useEffect(() => {
    if (!subscribeToData) return;
    const subscription = catalogueUtility.getSubscriptionState$
      .pipe(
        distinctUntilChanged((pre, cur) => cur.data === pre.data),
        pluck('data'),
      )
      .subscribe(catalogue => {
        setCatalogueData(catalogue);
      });
    return () => subscription.unsubscribe?.();
  }, [subscribeToData]);

  useEffect(() => {
    if (!subscribeToData) return;
    const subscription = catalogueUtility.getSubscriptionState$
      .pipe(pluck('isFetching'))
      .subscribe(loading => {
        setCatalogueLoading(!!loading);
      });
    return () => subscription.unsubscribe?.();
  }, [subscribeToData]);

  return {
    allNestedPages,
    productMaps: filteredProductMaps,
    variantMaps: filteredVariantMaps,
    menuOptions,
    error: error ? parseApolloError(error) : undefined,
    loading,
    refetch,
    catalogue: catalogueData,
    allPageItemMaps,
    sortedMenuItems,
    getAllMenuOptions,
    getCatalogueById,
  };
}
