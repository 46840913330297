import React, { useEffect, useRef } from 'react';
import { useSyncOrderEvents } from './hooks/app/useSyncOrderEvents';
import { useNetInfo } from '@react-native-community/netinfo';
import { useAppState } from '@react-native-community/hooks';
import { AuthState } from './constants';

export interface OrderSyncEffectProps {
  authState: AuthState;
}

const OrderSyncEffect: React.FC<OrderSyncEffectProps> = ({
  authState,
}: OrderSyncEffectProps) => {
  const previousNetworkState = useRef<boolean>();
  const { syncAllOrderEvents } = useSyncOrderEvents();
  const netInfo = useNetInfo();
  const currentAppState = useAppState();

  useEffect(() => {
    if (
      authState === AuthState.LOGGED_IN &&
      currentAppState == 'active' &&
      !previousNetworkState.current &&
      netInfo.isConnected &&
      netInfo.isInternetReachable
    ) {
      syncAllOrderEvents();
    }
  }, [currentAppState, authState, netInfo, syncAllOrderEvents]);

  useEffect(() => {
    previousNetworkState.current =
      !!netInfo?.isConnected && !!netInfo?.isInternetReachable;
  }, [netInfo]);

  return <></>;
};

export default OrderSyncEffect;
