import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client/react/hooks';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CUSTOMER,
  ENROLL_CUSTOMER_LOYALTY,
  DELIST_LOYALTY_CUSTOMER,
  UPDATE_CUSTOMER_BALANCE_EVENT,
  GET_CUSTOMER_FRAGMENT,
  GET_PAGINATED_CUSTOMERS,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_PHONE_QUERY,
  GET_CUSTOMER_BY_EMAIL_QUERY,
} from '../../graphql/customer';
import { useState, useCallback, useMemo, useRef } from 'react';
import {
  CreateCustomerRequest,
  Customer,
  CustomerAccountDetails,
  OnAccountPayment,
  EnrollCustomerInput,
  OnAccountAction,
  OnAccountEvent,
  CustomerBalanceEventInput,
  OrderAction,
  PageInfo,
  Connection,
  UpdateCustomerRequest,
  CustomerGroup,
  Address,
} from '@oolio-group/domain';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { keyBy } from 'lodash';
import { useNotification } from '../Notification';
import { translate } from '@oolio-group/localization';
import {
  generateOnAccountEvent,
  computeCustomerBalanceState,
} from '@oolio-group/client-utils';
import { useSession } from '../app/useSession';
import {
  CurrentOrderActionState,
  currentOrderActionObservable,
} from '../app/orders/ordersObservableUtils';
import { EnrollmentSource, LoyaltyApi, Member } from '@oolio-group/loyalty-sdk';
import { tokenUtility } from '../../state/tokenUtility';

export const mapperCustomerModel = (customer: Customer) => {
  return {
    id: customer.id,
    firstName: customer.firstName || '',
    lastName: customer.lastName || '',
    name: customer.name || '',
    email: customer.email || null,
    phone: customer.phone,
    phoneNumber: customer.phoneNumber,
    preferredAddress: {
      line1: customer.preferredAddress?.line1 || null,
      line2: customer.preferredAddress?.line2 || null,
      city: customer.preferredAddress?.city || null,
      suburb: customer?.preferredAddress?.suburb || null,
      postalCode: customer.preferredAddress?.postalCode || null,
      state: customer.preferredAddress?.state || null,
      isoCountryCode: customer.preferredAddress?.isoCountryCode || null,
      __typename: 'Address',
    },
    customerAccountDetails: customer.customerAccountDetails || null,
    loyaltyMember: customer?.loyaltyMember || null,
    loyaltyPointsBalance: customer?.loyaltyPointsBalance ?? null,
    lifetimeLoyaltyPoints: customer?.lifetimeLoyaltyPoints ?? null,
    createdAt: customer?.createdAt || null,
    enrolledAt: customer?.enrolledAt || null,
    fetchedAt: customer?.fetchedAt || null,
    code: customer?.code || '',
    loyaltyEnrolmentSource: customer?.loyaltyEnrolmentSource || null,
    __typename: 'Customer',
  } as unknown as Customer;
};
export interface CustomerAddress {
  line1: string;
  line2?: string;
  city?: string;
  suburb: string;
  state?: string;
  postalCode: string;
  country?: string;
}
interface CustomerAccountType<T> {
  action: OnAccountAction;
  input?: Omit<T, keyof OnAccountEvent>;
  customerId?: string;
  orderId?: string;
  existingEvent?: OnAccountEvent;
}

export interface UpdateCustomerBalanceRequest {
  id: string;
  customerAccountDetails?: CustomerAccountDetails;
}

const IN_VALIDATE_CUSTOMER_TIME = 2 * 60 * 1000; //2minutes

const CUSTOMERS_LIMIT = 50;

export interface UseCustomersProps {
  paginatedCustomers: Array<Customer>;
  customers: Array<Customer>;
  customerMaps: Record<string, Customer>;
  addNewCustomer: (
    input: Partial<CreateCustomerRequest>,
  ) => Promise<Customer | undefined>;
  loading: boolean;
  error: string | undefined;
  getCustomerById: (id: string, isFetchNetworkOnly?: boolean) => void;
  deleteCustomer: (id: string) => Promise<void>;
  delistLoyaltyCustomer: (
    id: string,
    isOolioLoyaltyFeatureEnabled?: boolean,
  ) => Promise<void>;
  updateCustomer: (
    input: UpdateCustomerRequest,
    flag?: { balanceUpdate: boolean },
  ) => Promise<Customer | undefined>;
  updateCustomerBalance: (input: UpdateCustomerRequest) => Promise<void>;
  enrollCustomerLoyalty: (
    input: EnrollCustomerInput,
    earnedPoint?: number,
  ) => Promise<Customer>;
  enrollMemberLoyalty: (
    id: string,
    loyaltySource?: EnrollmentSource,
  ) => Promise<Customer | undefined>;
  resetCustomerBalanceState: () => void;
  updateCustomerBalanceEvent: <T extends OnAccountEvent>(
    customerAccountType: CustomerAccountType<T>,
  ) => void;
  customerBalanceState: OnAccountPayment;
  updateCustomerInfo: (input: Customer) => void;
  getPaginatedCustomers: (
    searchValue?: string,
    customerGroup?: CustomerGroup,
  ) => void;
  getCustomerByEmailOrPhone: (
    email?: string,
    phone?: string,
  ) => Promise<Customer | undefined>;
  onFetchMore: () => void;
  pageInfo?: PageInfo;
}

const initialCustomerBalanceState: OnAccountPayment = {
  amount: 0,
  roundOffDifference: 0,
  tip: 0,
  paymentSurcharge: 0,
};

export function useCustomers(): UseCustomersProps {
  const [paginatedCustomers, setPaginatedCustomers] = useState<Customer[]>([]);
  const lastPageInfoRef = useRef<PageInfo>({});
  const { showNotification } = useNotification();
  const [session] = useSession();
  const client = useApolloClient();
  const [customerBalanceState, setCustomerBalanceState] =
    useState<OnAccountPayment>(initialCustomerBalanceState);
  let loyaltyApi;

  if (session?.currentOrganization?.id) {
    loyaltyApi = new LoyaltyApi({
      token: tokenUtility.token ?? '',
      baseUrl: process.env.REACT_APP_LOYALTY_API_URL || '',
      organization: session?.currentOrganization?.id ?? '',
    });
  }

  const getCachedCustomers = useCallback(() => {
    const cachedData = client.cache.readQuery<{ customers: Customer[] }>({
      query: GET_CUSTOMERS,
      returnPartialData: true,
    });
    return cachedData?.customers || [];
  }, [client.cache]);

  const { data } = useQuery<{
    customers: Customer[];
  }>(GET_CUSTOMERS, {
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  const recentCachedCustomers = useMemo(
    () => (data?.customers || []) as Customer[],
    [data?.customers],
  );

  const updateCachedCustomers = useCallback(
    (customers: Customer[]) => {
      client.cache.writeQuery({
        query: GET_CUSTOMERS,
        data: {
          customers,
        },
      });
    },
    [client.cache],
  );

  const updateCustomerToCache = useCallback(
    (input?: Customer) => {
      const updateCustomerId = input?.id;
      if (!updateCustomerId) return;
      const mappedCustomer = mapperCustomerModel(input);
      const existingCustomers = getCachedCustomers().filter(
        item => item.id !== updateCustomerId,
      );

      const updatedCustomers = [mappedCustomer].concat(existingCustomers);
      updateCachedCustomers(updatedCustomers);
    },
    [getCachedCustomers, updateCachedCustomers],
  );

  const [syncCustomerBalanceEventReq] = useMutation(
    UPDATE_CUSTOMER_BALANCE_EVENT,
    {
      onError: noopHandler,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data.syncCustomerBalanceEvents) {
          currentOrderActionObservable.next({
            lastOrderAction: OrderAction.ORDER_SAVE,
          } as unknown as CurrentOrderActionState);
        }
      },
    },
  );

  const syncCustomerBalanceEvent = useCallback(
    (event: CustomerBalanceEventInput): void => {
      syncCustomerBalanceEventReq({
        variables: { input: [event] },
      });
    },
    [syncCustomerBalanceEventReq],
  );

  const [getPaginatedReq, getPaginatedRes] = useLazyQuery<{
    paginatedCustomers: Connection<Customer>;
  }>(GET_PAGINATED_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      const data = response.paginatedCustomers;
      const customers = (data.edges || []).map(edge => edge.node);
      setPaginatedCustomers(pre => [...pre, ...customers]);
      lastPageInfoRef.current = data.pageInfo;
    },
  });

  const [getCustomerByIdRequest, getCustomerByIdResponse] = useLazyQuery<{
    customerById: Customer;
  }>(GET_CUSTOMER, {
    fetchPolicy: 'no-cache',
    onError: noopHandler,
    onCompleted: response => {
      const fetchedCustomer = response.customerById;
      updateCustomerToCache(fetchedCustomer);
    },
  });

  const [createCustomerRequest, createCustomerResponse] = useMutation(
    CREATE_CUSTOMER,
    {
      onError: noopHandler,
      onCompleted: data => {
        if (data?.createCustomer.name) {
          showNotification({
            success: true,
            message: translate('backOfficeCustomers.successMessage', {
              customerName: data?.createCustomer.name.trim() ?? '',
            }),
          });
        } else {
          showNotification({
            success: true,
            message: translate('customer.successMessage'),
          });
        }
      },
    },
  );

  const [enrollCustomerLoyaltyRequest, enrollCustomerLoyaltyResponse] =
    useMutation<{
      enrollCustomerLoyalty: Customer;
    }>(ENROLL_CUSTOMER_LOYALTY, {
      onCompleted: () => {
        showNotification({
          message: translate('customerLoyalty.enrollLoyaltySuccessfully'),
          success: true,
        });
      },
    });

  const getPaginatedCustomers = useCallback(
    (searchValue?: string, customerGroup?: CustomerGroup) => {
      setPaginatedCustomers([]);
      let isEmail, isPhone;
      if (searchValue) {
        const phoneReg = /^\d+$/g;
        // when search value include @ will consider as an email
        isEmail = searchValue.includes('@');
        isPhone = phoneReg.test(searchValue);
        // remove first zero
        if (isPhone && searchValue[0] == '0') {
          searchValue = searchValue.slice(1);
        }
      }

      const filter = {
        ...(!isPhone && !isEmail && { name: searchValue }),
        ...(isPhone && { phone: searchValue }),
        ...(isEmail && { email: searchValue }),
        ...(customerGroup && { group: customerGroup }),
      };
      getPaginatedReq({
        variables: {
          limit: CUSTOMERS_LIMIT,
          after: '',
          filter,
        },
      });
    },
    [getPaginatedReq],
  );

  const onFetchMore = useCallback(() => {
    if (!lastPageInfoRef.current.hasNextPage) return;
    getPaginatedReq({
      variables: {
        ...getPaginatedRes?.variables,
        after: lastPageInfoRef?.current?.endCursor,
      },
    });
  }, [getPaginatedReq, getPaginatedRes?.variables]);

  const getCachedCustomer = useCallback(
    (id: string) => {
      const cachedCustomer = client.cache.readFragment<Customer>({
        id: `Customer:${id}`,
        fragment: GET_CUSTOMER_FRAGMENT,
      });
      return cachedCustomer;
    },
    [client.cache],
  );

  const getCustomerById = useCallback(
    (id: string, isFetchNetworkOnly?: boolean) => {
      const cachedCustomer = getCachedCustomer(id);
      const durationSinceLastFetched =
        Date.now() - (cachedCustomer?.fetchedAt || Date.now());

      // update the cached when lifetime of customer in client is more than setting time.
      if (
        !cachedCustomer ||
        isFetchNetworkOnly ||
        durationSinceLastFetched > IN_VALIDATE_CUSTOMER_TIME
      ) {
        getCustomerByIdRequest({
          variables: {
            id: id,
          },
        });
      }
    },
    [getCachedCustomer, getCustomerByIdRequest],
  );

  const getCustomerByEmailOrPhone = useCallback(
    async (email?: string, phone?: string): Promise<Customer | undefined> => {
      if (phone) {
        const response = await client.query<{ customer: Customer }>({
          query: GET_CUSTOMER_BY_PHONE_QUERY,
          variables: { phone },
          fetchPolicy: 'no-cache',
        });
        if (response?.data?.customer) {
          return response.data.customer as Customer;
        }
      }

      if (email) {
        const response = await client.query<{ customer: Customer }>({
          query: GET_CUSTOMER_BY_EMAIL_QUERY,
          variables: { email },
          fetchPolicy: 'no-cache',
        });
        if (response?.data?.customer) {
          return response.data.customer as Customer;
        }
      }
      return undefined;
    },
    [client],
  );

  const [deleteCustomerRequest, deleteCustomerResponse] = useMutation(
    DELETE_CUSTOMER,
    {
      onError: noopHandler,
      onCompleted: () => {
        showNotification({
          success: true,
          message: translate(
            'backOfficeCustomers.customerDeleteSuccessMessage',
          ),
        });
      },
    },
  );

  const [delistLoyaltyCustomerRequest, delistLoyaltyCustomerResponse] =
    useMutation(DELIST_LOYALTY_CUSTOMER, {
      onError: noopHandler,
    });

  const [updateCustomerRequest, updateCustomerResponse] = useMutation<{
    updateCustomer: Customer;
  }>(UPDATE_CUSTOMER, {
    onError: noopHandler,
  });

  const [updateCustomerBalanceRequest, updateCustomerBalanceResponse] =
    useMutation(UPDATE_CUSTOMER, {
      onError: noopHandler,
      onCompleted: response => {
        response.updateCustomer &&
          updateCustomerToCache(response.updateCustomer);
      },
    });

  /**
   * Create a new customer an returns the created customer object
   *
   * If customer creation failed it will return `undefined`
   */
  const addNewCustomer = useCallback(
    async (
      input: Partial<CreateCustomerRequest>,
    ): Promise<Customer | undefined> => {
      const response = await createCustomerRequest({
        variables: {
          input,
        },
      });
      if (response?.data && response?.data?.createCustomer) {
        updateCustomerToCache(response?.data?.createCustomer);
        return response.data.createCustomer;
      }
      return undefined;
    },
    [createCustomerRequest, updateCustomerToCache],
  );

  const enrollCustomerLoyalty = useCallback(
    async (
      input: Partial<EnrollCustomerInput>,
      earnedPoints?: number,
    ): Promise<Customer> => {
      const response = await enrollCustomerLoyaltyRequest({
        variables: {
          input,
        },
      });
      // when enroll after order completed, we need to update recentEarnPoints to cache,
      // as it was not calculate when making payment
      const enrolledCustomer = {
        ...(response?.data?.enrollCustomerLoyalty as Customer),
        ...(earnedPoints && {
          lifetimeLoyaltyPoints: earnedPoints,
          loyaltyPointsBalance: earnedPoints,
        }),
      };
      updateCustomerToCache(enrolledCustomer);
      return enrolledCustomer as Customer;
    },
    [enrollCustomerLoyaltyRequest, updateCustomerToCache],
  );

  const enrollMemberLoyalty = useCallback(
    async (
      id: string,
      loyaltySource?: EnrollmentSource,
    ): Promise<Customer | undefined> => {
      const response = await loyaltyApi?.enrollMember(id, true, loyaltySource);
      if (!response) return;
      updateCustomerToCache(mapMemberToCustomer(response));
      showNotification({
        success: true,
        message: translate('customer.enrollLoyaltySuccess'),
      });
      return mapMemberToCustomer(response);
    },
    [updateCustomerToCache, loyaltyApi, showNotification],
  );

  const deleteCustomer = useCallback(
    async (id: string): Promise<void> => {
      const response = await deleteCustomerRequest({
        variables: {
          id,
        },
      });

      if (response?.data?.deleteCustomer) {
        const existingCustomers = getCachedCustomers();
        const updatedCustomers = existingCustomers.filter(
          customer => customer?.id !== id,
        );
        updateCachedCustomers(updatedCustomers);
      }
    },
    [deleteCustomerRequest, getCachedCustomers, updateCachedCustomers],
  );

  const mapMemberToCustomer = (member: Member): Customer => {
    const customer: Customer = {
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      phone: member.phone,
      phoneNumber: member.phone.replace(/^\+\d{2}/, '').trim(),
      preferredAddress: member.preferredAddress as Address,
      loyaltyMember: member.enrolled,
      loyaltyPointsBalance: member.balance?.availableBalance,
      lifetimeLoyaltyPoints: member.balance?.lifeTimeBalance,
      createdAt: member.createdAt,
      enrolledAt: member.enrolledAt,
      loyaltyEnrolmentSource: member.enrolledThrough,
      dob: member.dob,
      code: member.code,
    };

    return customer;
  };

  const delistLoyaltyCustomer = useCallback(
    async (
      id: string,
      isOolioLoyaltyFeatureEnabled?: boolean,
    ): Promise<void> => {
      if (isOolioLoyaltyFeatureEnabled) {
        const response: Member = await loyaltyApi?.enrollMember(id, false);
        if (response) {
          showNotification({
            success: true,
            message: translate('backOfficeCustomers.delistSuccessMessage', {
              customerName: `${response?.firstName} ${response?.lastName}`,
            }),
          });
          updateCustomerToCache(mapMemberToCustomer(response));
        }
      } else {
        const response = await delistLoyaltyCustomerRequest({
          variables: {
            id,
          },
        });

        if (response?.data?.delistLoyaltyCustomer) {
          updateCustomerToCache(response?.data?.delistLoyaltyCustomer);
        }
      }
    },
    [
      delistLoyaltyCustomerRequest,
      updateCustomerToCache,
      loyaltyApi,
      showNotification,
    ],
  );

  const updateCustomer = useCallback(
    async (
      input: UpdateCustomerRequest,
      flag,
    ): Promise<Customer | undefined> => {
      const response = await updateCustomerRequest({
        variables: { input },
      });
      const updatedCustomer = response?.data?.updateCustomer;
      if (updatedCustomer) {
        updateCustomerToCache(updatedCustomer);
        if (!flag?.balanceUpdate) {
          showNotification({
            success: true,
            message: translate('backOfficeCustomers.editSuccessMessage', {
              customerName: updatedCustomer?.name,
            }),
          });
        } else {
          showNotification({
            success: true,
            message: translate('backOfficeCustomers.customerPointsUpdated'),
          });
        }
      }
      return updatedCustomer;
    },
    [showNotification, updateCustomerRequest, updateCustomerToCache],
  );

  const updateCustomerBalance = useCallback(
    async (input: UpdateCustomerBalanceRequest): Promise<void> => {
      await updateCustomerBalanceRequest({
        variables: { input },
      });
    },
    [updateCustomerBalanceRequest],
  );

  const resetCustomerBalanceState = useCallback(() => {
    setCustomerBalanceState(initialCustomerBalanceState);
  }, []);

  const updateCustomerBalanceEvent = useCallback(
    <T extends OnAccountEvent>({
      action,
      input,
      customerId,
      existingEvent,
      orderId,
    }: CustomerAccountType<T>) => {
      const event = generateOnAccountEvent(
        action,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: session.user?.id,
        },
        { ...input, customerId, orderId },
      );
      const customerBalance = computeCustomerBalanceState(
        [existingEvent ?? event],
        customerBalanceState,
      );
      // We don't need to sync processed event as it is sync from payment services itself
      !existingEvent && syncCustomerBalanceEvent(event);
      setCustomerBalanceState(customerBalance);
    },
    [
      customerBalanceState,
      session.currentOrganization?.id,
      session.currentStore?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.user?.id,
      syncCustomerBalanceEvent,
    ],
  );

  const RESPONSE_ENTITIES = [
    getCustomerByIdResponse,
    createCustomerResponse,
    deleteCustomerResponse,
    delistLoyaltyCustomerResponse,
    updateCustomerResponse,
    updateCustomerBalanceResponse,
    enrollCustomerLoyaltyResponse,
    getPaginatedRes,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const _error = getError(RESPONSE_ENTITIES);

  const customerMaps = useMemo(
    () => keyBy(recentCachedCustomers, 'id'),
    [recentCachedCustomers],
  );

  return {
    addNewCustomer,
    getCustomerById,
    deleteCustomer,
    delistLoyaltyCustomer,
    enrollMemberLoyalty,
    updateCustomer,
    updateCustomerBalance,
    loading,
    error: _error ? parseApolloError(_error) : undefined,
    paginatedCustomers,
    enrollCustomerLoyalty,
    customers: recentCachedCustomers,
    customerMaps,
    customerBalanceState,
    updateCustomerBalanceEvent,
    resetCustomerBalanceState,
    updateCustomerInfo: updateCustomerToCache,
    getPaginatedCustomers,
    onFetchMore,
    getCustomerByEmailOrPhone,
  };
}
