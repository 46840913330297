import { gql } from '@apollo/client';
import { FRAGMENTS } from './deviceProfiles';

export const FRAGMENT_ORGANIZATION_DETAILS = gql`
  fragment OrganizationDetails on Organization {
    id
    name
    businessIdentifier
    website
    email
    phone
    country
    currencyCode
    timezone
    contactName
    address {
      line1
      line2
      city
      suburb
      state
      postalCode
      country
    }
    logoUrl
    onboarding {
      areas {
        name
        sections {
          key
          checklist {
            name
            action
            status
          }
          order
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_DETAILS = gql`
  query organization($orgId: String!) {
    getOrganizationById(id: $orgId) {
      ...OrganizationDetails
    }
  }
  ${FRAGMENT_ORGANIZATION_DETAILS}
`;

export const UPDATE_ORG_BASIC_DETAILS = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput) {
    updateOrganization(input: $input) {
      ...OrganizationDetails
    }
  }
  ${FRAGMENT_ORGANIZATION_DETAILS}
`;

// TODO: what if the user is trying to change the email address or phone or organization, do we need to update the user attributes as well?
export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      name
    }
  }
`;

export const GET_VENUES_DETAILS = gql`
  query venuesDetails {
    venues {
      id
      name
      isActive
    }
  }
`;

export const GET_SECTIONS_AND_TABLES = gql`
  query getSectionsAndTables {
    sections {
      id
      name
      isActive
      tables {
        id
        name
        status
      }
    }
  }
`;

export const GET_TAXES_DETAILS = gql`
  query getTaxDetails {
    taxes {
      id
      name
      rate
      code
      taxes {
        id
        name
      }
    }
  }
`;

export const GET_FEES_DETAILS = gql`
  query getFeesDetails {
    fees {
      id
      name
      code
      rate
      rateType
    }
  }
`;

export const GET_PAYMENT_TYPES_QUERY = gql`
  query getPaymentTypes {
    paymentTypes {
      id
      value: id
      label: name
      name
      adjustmentPrompt
      requireCustomer
      quickPay
    }
  }
`;

export const GET_ORDER_TYPES_SALES_CHANNELS_QUERY = gql`
  query getOrderTypes {
    orderTypes {
      id
      name
      code
      isActive
    }
    salesChannels {
      id
      name
      isActive
      orderTypes {
        id
        name
      }
    }
  }
`;

export const GET_DEVICE_PROFILES_QUERY = gql`
  query deviceProfiles {
    deviceProfiles {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const DELETE_TAX_DETAILS = gql`
  mutation deleteTax($id: ID!) {
    deleteTax(id: $id)
  }
`;

export const DELETE_FEE_DETAILS = gql`
  mutation deleteFee($id: ID!) {
    deleteFee(id: $id)
  }
`;

export const DELETE_PAYMENT_TYPE = gql`
  mutation deleteFee($id: ID!) {
    deletePaymentType(id: $id)
  }
`;

export const DELETE_ORDER_TYPE = gql`
  mutation deleteOrderType($id: String!) {
    deleteOrderType(id: $id)
  }
`;

export const DELETE_SALES_CHANNEL = gql`
  mutation deleteSalesChannel($id: ID!) {
    deleteSalesChannel(id: $id)
  }
`;

export const FILTER_VENUES_BY_NAME = gql`
  query venueFilter($search: String!) {
    venues(filter: { name: $search }) {
      id
      name
      isActive
    }
  }
`;

export const GET_PAGES_QUERY = gql`
  query pages {
    pages {
      id
      name
      color
      products {
        id
      }
      variants {
        products {
          id
        }
      }
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation deletePage($id: ID!) {
    deletePage(id: $id)
  }
`;

export const UPDATE_VENUE_STATUS = gql`
  mutation updateVenue($id: ID!, $isActive: Boolean) {
    updateVenue(input: { id: $id, isActive: $isActive }) {
      id
      name
      isActive
    }
  }
`;

export const CREATE_VENUE = gql`
  mutation createVenue($input: CreateVenueInput!) {
    createVenue(input: $input) {
      id
      name
      isActive
    }
  }
`;

export const ACTIVATE_VENUE = gql`
  mutation activateVenue($input: ActivateVenueInput!) {
    activateVenue(input: $input) {
      id
      name
      isActive
    }
  }
`;

export const CREATE_TAX = gql`
  mutation createTax($input: CreateTaxInput) {
    createTax(input: $input) {
      id
      name
      code
      rate
    }
  }
`;

export const CREATE_PAYMENT_TYPE = gql`
  mutation createPaymentType($input: CreatePaymentInput) {
    createPaymentType(input: $input) {
      id
      name
      clearingAccount
      quickPay
      adjustmentPrompt
      requireCustomer
    }
  }
`;

export const CREATE_ORDER_TYPE = gql`
  mutation createOrderType($input: CreateOrderTypeInput) {
    createOrderType(input: $input) {
      id
      name
      code
    }
  }
`;

export const CREATE_SALES_CHANNEL = gql`
  mutation createSalesChannel($input: CreateSalesChannelInput) {
    createSalesChannel(input: $input) {
      id
      name
      isActive
    }
  }
`;

export const CREATE_FEE = gql`
  mutation createFee($input: CreateFeeInput) {
    createFee(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_FEES = gql`
  mutation updateFees($input: [UpdateFeeInput]) {
    updateFees(input: $input) {
      id
      name
      code
      rate
      rateType
    }
  }
`;

export const UPDATE_TAX = gql`
  mutation updateTaxes($input: [UpdateTaxInput]) {
    updateTaxes(input: $input) {
      id
      name
      rate
      code
    }
  }
`;

export const UPDATE_PAYMENT_TYPES = gql`
  mutation updatePaymentTypes($input: [UpdatePaymentInput]) {
    updatePaymentTypes(input: $input) {
      id
      name
      adjustmentPrompt
    }
  }
`;

export const UPDATE_SALES_CHANNEL = gql`
  mutation updateSalesChannels($input: [UpdateSalesChannelInput]) {
    updateSalesChannels(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_ORDER_TYPES_AND_SALES_CHANNEL = gql`
  mutation updateOrderTypes(
    $inputOrder: [UpdateOrderTypeInput]
    $inputSales: [UpdateSalesChannelInput]
  ) {
    updateOrderTypes(input: $inputOrder) {
      id
      name
      code
      isActive
    }
    updateSalesChannels(input: $inputSales) {
      id
      name
    }
  }
`;

export const CREATE_PAGES = gql`
  mutation createPages($input: [CreatePageInput]) {
    createPages(input: $input) {
      id
      name
      color
    }
  }
`;

export const CHANNELS_STORES_PAGES_QUERY = gql`
  query channelsVenuesPagesQuery {
    salesChannels {
      value: id
      label: name
      orderTypes {
        value: id
        label: name
      }
    }
    me {
      venues {
        value: id
        label: name
        stores {
          value: id
          label: name
        }
        features {
          name
          enabled
          featureId
          id
        }
      }
    }
    pages {
      value: id
      label: name
    }
    orderTypes {
      value: id
      label: name
    }
    me {
      stores {
        value: id
        label: name
        venue {
          id
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID_QUERY = gql`
  query productById($id: String!) {
    product(id: $id) {
      id
      name
      pages {
        id
      }
      salesChannels {
        id
      }
      venues {
        id
      }
    }
  }
`;

export const GET_PRODUCT_INFO_QUERY = gql`
  query productById($id: String!) {
    product(id: $id) {
      id
      name
      productType
      barcode
      sku
      isSellable
      measuredBy {
        unitType
        defaultSize
        units
      }
      description
      tags {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($input: UpdateProductInput) {
    updateProduct(input: $input) {
      id
      name
    }
  }
`;

export const GET_TAGS_QUERY = gql`
  query getTags($filter: TagFilterInput) {
    tags(filter: $filter) {
      id
      name
    }
  }
`;

export const ADD_TAG_MUTATION = gql`
  mutation createTag($input: CreateTagInput) {
    createTag(input: $input) {
      id
      name
      tagType
    }
  }
`;

export const GET_PRODUCT_OPTIONS_QUERY = gql`
  query getProductOptions($id: String!) {
    product(id: $id) {
      id
      name
      printProductionTicket
    }
  }
`;

export const GET_VARIANTS_OPTIONS_QUERY = gql`
  query getVariantsOptionsInfo($id: ID) {
    variant(id: $id) {
      id
      name
      options {
        id
        key
        values
      }
      products {
        id
        name
      }
    }
    options {
      id
      key
      values
    }
  }
`;

export const GET_DEVELOPER_TOOLS_SETTINGS = gql`
  query getOrgDeveloperSettings($id: String) {
    getOrgDeveloperSettings(id: $id) {
      virtualEmail
      virtualSms
    }
  }
`;

export const CREATE_OR_UPDATE_DEVELOPER_TOOLS_SETTINGS = gql`
  mutation createOrUpdateDeveloperToolsSettings(
    $settings: DeveloperToolsSettingsInput
  ) {
    createOrUpdateDeveloperToolsSettings(settings: $settings) {
      virtualEmail
      virtualSms
    }
  }
`;
