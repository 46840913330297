import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { FunctionMapActions } from '@oolio-group/domain';
import { Action } from '../../../Catalog/Catalog.type';
import { camelCase, capitalCase } from 'change-case';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import theme from '../../../../common/default-theme';
import styles, { getBarColor, getTileMargins } from './Functions.styles';
import Icon from '../../../Icon/Icon';
import PaginationTile from '../Paginaton/PaginationTile';

interface FunctionProps {
  functions: Action[];
}

interface EmptyFunctionTileProps {
  position: number;
}

const EmptyFunctionTile: React.FC<EmptyFunctionTileProps> = ({ position }) => {
  return (
    <View
      key={`empty-${position}`}
      style={[
        styles.tile,
        styles.tileSize,
        getTileMargins(position),
        { backgroundColor: theme.colors.grey3 },
      ]}
    />
  );
};

const Functions: React.FC<FunctionProps> = ({ functions }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = functions.length === 10 ? 10 : 9;
  const totalPages = Math.ceil(functions.length / pageSize);

  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = startIdx + pageSize;

  const pagedFunctions = useMemo(
    () => functions.slice(startIdx, endIdx),
    [functions, startIdx, endIdx],
  );
  const emptyTilesCount = useMemo(
    () => pageSize - pagedFunctions.length,
    [pageSize, pagedFunctions.length],
  );

  const onPressNext = () =>
    setCurrentPage(current => Math.min(current + 1, totalPages));
  const onPressBack = () => setCurrentPage(current => Math.max(current - 1, 1));

  const onPressFunction = useCallback((fn: Action) => {
    if (!fn.isGroup) {
      analyticsService.capture('function_pressed', {
        name: capitalCase(fn.name || 'Unknown'),
      });
    }
    setCurrentPage(1);
    fn.onPress();
  }, []);

  return (
    <View testID="functions" style={styles.container}>
      {pagedFunctions.map((fn, i) => {
        if (fn.action === FunctionMapActions.BACK) {
          return (
            <TouchableOpacity
              key={`fn-${i}`}
              testID="fn-back"
              onPress={() => fn.onPress()}
              style={[styles.tile, styles.tileSize, styles.back]}
            >
              <Icon name="arrow-left" size={24} color={theme.colors.dark} />
            </TouchableOpacity>
          );
        }

        return (
          <TouchableOpacity
            key={`${currentPage}-${i}`}
            testID={`fn-${camelCase(fn.name)}`}
            style={[styles.tile, styles.tileSize, getTileMargins(i)]}
            onPress={() => onPressFunction(fn)}
          >
            {fn.isGroup ? <View style={styles.group} /> : undefined}
            <Text numberOfLines={2} style={styles.tileText}>
              {fn.name}
            </Text>
            {!fn.isGroup ? (
              <View
                style={[
                  styles.colour,
                  { backgroundColor: getBarColor(fn.name) },
                ]}
              />
            ) : undefined}
          </TouchableOpacity>
        );
      })}
      {Array.from({ length: emptyTilesCount }, (_, i) => (
        <EmptyFunctionTile
          key={`empty-${i}`}
          position={pagedFunctions.length + i}
        />
      ))}
      {totalPages > 1 ? (
        <PaginationTile
          totalPages={totalPages}
          currentPage={currentPage}
          onPressNext={onPressNext}
          onPressBack={onPressBack}
          containerStyle={[styles.tileSize, styles.pagination]}
        />
      ) : functions.length !== 10 ? (
        <EmptyFunctionTile position={10} />
      ) : null}
    </View>
  );
};

export default Functions;
