import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import { OrderItem } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { addToValue } from '../../../../utils/keypadHelper';
import { MAX_KEYPAD_INPUT } from '../../../../types/Common';
import theme from '../../../../common/default-theme';
import styles from './KeypadModal.styles';
import Icon from '../../../Icon/Icon';
import Message from '../../../Office/Message/Message';
import ButtonIcon from '../../../Shared/TreatButton/ButtonIcon';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import CartProduct from '../../Cart/CartProduct/CartProduct';
import { useNotification } from '../../../../hooks/Notification';

export interface KeypadModalProps {
  title: string;
  initialValue?: string;
  mode: 'integer' | 'decimal' | 'currency' | 'percentage';
  remainingAmount?: number;
  product?: OrderItem;
  secondaryButton?: React.ReactNode;
  dismissOnConfirm?: boolean;
  onConfirm: (value: number) => void;
  onDismiss: () => void;
  maxValue?: number | string;
  minValue?: number | string;
}

const KeypadModal: FC<KeypadModalProps> = ({
  title,
  initialValue,
  mode,
  remainingAmount,
  product,
  secondaryButton,
  dismissOnConfirm = true,
  onConfirm,
  onDismiss,
  maxValue,
  minValue,
}) => {
  const isDirty = useRef<boolean>(false);

  const { translate } = useTranslation();
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();
  const { showNotification } = useNotification();

  const [inputValue, setInputValue] = useState('');

  const onPressKey = useCallback(
    (key: string): void => {
      if (isDirty.current === true) {
        setInputValue(value => {
          let prevValue = value;
          // Remove Leading 0's for Integers
          if (mode === 'integer' && value.startsWith('0')) {
            prevValue = prevValue.replace(/^0+/, '');
          }
          const newValue = addToValue(
            prevValue,
            key,
            decimalSeparator,
            MAX_KEYPAD_INPUT,
          );

          return maxValue && Number(newValue) > Number(maxValue)
            ? maxValue.toString()
            : newValue;
        });
      } else {
        setInputValue(
          maxValue && Number(key) > Number(maxValue)
            ? maxValue.toString()
            : key,
        );
        isDirty.current = true;
      }
    },
    [decimalSeparator, maxValue, mode],
  );

  const onPressConfirm = useCallback(() => {
    let finalValue: number;
    switch (mode) {
      case 'currency':
        finalValue = unFormatCurrency(inputValue);
        break;
      case 'percentage':
        finalValue = parseFloat(inputValue) / 100;
        break;
      default:
        finalValue = parseFloat(inputValue);
        break;
    }
    if (minValue && finalValue < parseFloat(minValue.toString())) {
      showNotification({
        error: true,
        message: translate('order.minInputError', { minValue }),
      });
      return;
    }
    onConfirm(finalValue);
    !!dismissOnConfirm && onDismiss();
  }, [
    mode,
    minValue,
    onConfirm,
    dismissOnConfirm,
    onDismiss,
    unFormatCurrency,
    inputValue,
    showNotification,
    translate,
  ]);

  const formattedInputValue = useMemo(() => {
    switch (mode) {
      case 'currency':
        return formatCurrency(unFormatCurrency(inputValue) || 0);
      case 'percentage':
        return inputValue + '%';
      default:
        return inputValue;
    }
  }, [inputValue, mode, formatCurrency, unFormatCurrency]);

  const onPressClear = useCallback(() => {
    setInputValue('0');
  }, []);

  function getKeypadButtons(): { value: string }[] {
    const options = [
      { value: '1' },
      { value: '2' },
      { value: '3' },
      { value: '4' },
      { value: '5' },
      { value: '6' },
      { value: '7' },
      { value: '8' },
      { value: '9' },
      { value: '0' },
    ];

    if (mode !== 'integer') {
      options.push({ value: decimalSeparator });
    }

    return options;
  }

  function getZeroButtonWidth(): string {
    if (mode === 'integer') {
      return '100%';
    } else {
      return '66%';
    }
  }

  useEffect(() => {
    if (initialValue) {
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const difference = useMemo(
    () => Number(inputValue) - (remainingAmount || 0),
    [inputValue, remainingAmount],
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity style={styles.icon} onPress={onDismiss}>
          <Icon name="times" size={20} color={theme.colors.dark} />
        </TouchableOpacity>
        <Text style={styles.headerText}>{title}</Text>
      </View>
      <View style={styles.content}>
        {product ? (
          // eslint-disable-next-line react-native/no-inline-styles
          <View style={{ marginBottom: 10 }}>
            <CartProduct testID="modal-product" orderItem={product} minimised />
          </View>
        ) : null}
        <View style={styles.input}>
          <Text testID="input-value" style={styles.inputText}>
            {formattedInputValue}
          </Text>
          <ButtonIcon
            testID="btn-clear"
            icon="times-circle"
            type="interface"
            onPress={onPressClear}
            containerStyle={styles.btnClear}
          />
        </View>
        {remainingAmount ? (
          <Message
            testID="message-remainingAmt"
            type={
              difference > 0
                ? 'negative'
                : difference === 0
                ? 'positive'
                : 'neutral'
            }
            message={
              difference > 0
                ? translate('payment.amountExceedsDue')
                : `${formatCurrency(Math.abs(difference))} of ${formatCurrency(
                    remainingAmount,
                  )} will remain`
            }
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ marginBottom: 10 }}
          />
        ) : null}
        <View style={styles.keypad}>
          {getKeypadButtons().map(({ value }) => (
            <TouchableOpacity
              key={`key-${value}`}
              testID={`key-${value}`}
              onPress={() => onPressKey(value || '0')}
              style={[
                styles.btnKeypad,
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  width: value === '0' ? getZeroButtonWidth() : '32%',
                } as ViewStyle,
              ]}
            >
              <Text style={styles.textKeypad}>{value}</Text>
            </TouchableOpacity>
          ))}
        </View>
        <TreatButton
          testID="btn-confirm"
          height={50}
          type="positive"
          label="Confirm"
          onPress={onPressConfirm}
          disabled={remainingAmount ? difference > 0 : undefined}
        />
        {secondaryButton ? secondaryButton : null}
      </View>
    </View>
  );
};

export default KeypadModal;
