import React, { useMemo, useCallback, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../../../hooks/app/useSession';
import { keyBy } from 'lodash';
import theme from '../../../../../../common/default-theme';
import SectionTabs from '../../../../../../components/POS/SectionTabs/SectionTabs';
import DraggableTableIcon from '../Tables/DraggableTableIcon';
import { Table } from '@oolio-group/domain';
import Icon from '../../../../../../components/Icon/Icon';
import { MemoListTableIcons } from '../SectionsView';
import SelectTableStyles from './SelectTableModal.styles';
import { TableAction } from '../floorViewObservables';
import { noopHandler } from '../../../../../../utils/errorHandlers';
import { sortTablesByName } from '../../../../../../utils/TableHelper';
import { useTablesData } from '../../../../../../hooks/app/tables/useTablesData';

interface SelectTableModalProps {
  onSelection: (table: Table, orderId?: string) => void;
  onClose?: () => void;
  canSelectSubTable?: boolean;
}

const SelectTableModal: React.FC<SelectTableModalProps> = ({
  onSelection,
  onClose,
  canSelectSubTable,
}: SelectTableModalProps) => {
  const { translate } = useTranslation();
  const styles = SelectTableStyles();
  const [{ deviceProfile }] = useSession();
  const sectionsOptions = useMemo(
    () =>
      deviceProfile?.sections?.map(x => ({
        label: x.name,
        value: x.id,
      })) ?? [],
    [deviceProfile?.sections],
  );
  const initialSelection = sectionsOptions?.[0]?.value ?? '';

  const [selectedSection, setSelectedSection] =
    useState<string>(initialSelection);

  const allSectionsMap = useMemo(
    () => keyBy(deviceProfile?.sections ?? [], 'id'),
    [deviceProfile?.sections],
  );

  const { tableStatusMap, tableOrdersMap } = useTablesData();

  const allTables = useMemo(
    () =>
      sortTablesByName(allSectionsMap[selectedSection]?.tables ?? []).map(
        table =>
          ({
            ...table,
            status: tableStatusMap[table.id],
            section: {
              id: selectedSection,
            },
          } as Table),
      ),
    [allSectionsMap, selectedSection, tableStatusMap],
  );

  const positionedTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top === 'number' &&
          typeof table.position?.left === 'number',
      ),
    [allTables],
  );

  const unPositionedTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top !== 'number' ||
          typeof table.position?.left !== 'number',
      ),
    [allTables],
  );
  const displayAsGrid = useMemo<boolean>(
    () => unPositionedTables.length === allTables.length,
    [allTables, unPositionedTables],
  );

  const onChangeSection = useCallback(sectionId => {
    setSelectedSection(sectionId);
  }, []);

  const onSelectTable = useCallback(
    (table: Table) => {
      const orders = tableOrdersMap[table.id] ?? [];
      const isMultiOrderTable = orders?.length > 1;
      if (isMultiOrderTable) return;

      table.section.name = allSectionsMap[selectedSection]?.name;
      const activeOrder = orders?.[0];
      onSelection(table, activeOrder?.id);
    },
    [allSectionsMap, onSelection, selectedSection, tableOrdersMap],
  );

  const onSelectSubTable = useCallback(
    (table: Table, orderIndex?: number) => {
      const orders = tableOrdersMap[table.id] ?? [];
      table.section.name = allSectionsMap[selectedSection]?.name;
      onSelection(table, orders[orderIndex ?? 0]?.id);
    },
    [allSectionsMap, onSelection, selectedSection, tableOrdersMap],
  );

  const renderTableInLayout = useCallback(
    (tableObj: Table, index: number) => {
      const table = { ...tableObj, status: tableStatusMap[tableObj.id] };
      const orders = tableOrdersMap[table.id] ?? [];

      return (
        <DraggableTableIcon
          key={table.id}
          onRequestClose={noopHandler}
          orders={orders}
          table={table}
          onPressSubTable={onSelectSubTable}
          index={index}
          onPressIn={noopHandler}
          onDragTable={noopHandler}
          onDragReleaseTable={noopHandler}
          isDraggingTable={false}
          onPressTable={onSelectTable}
          displayAsGrid={displayAsGrid}
          tableAction={
            canSelectSubTable ? TableAction.DEFAULT : TableAction.MERGE
          }
        />
      );
    },
    [
      tableStatusMap,
      tableOrdersMap,
      onSelectSubTable,
      onSelectTable,
      displayAsGrid,
      canSelectSubTable,
    ],
  );

  return (
    <View style={styles.backdrop}>
      <View style={styles.scaleDown}>
        <View style={styles.headerContainer}>
          <TouchableOpacity
            style={[styles.btnClose, styles.scaleUp]}
            onPress={onClose}
          >
            <Icon name={'times'} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={[styles.headerText, styles.scaleUp]}>
            {translate('tableFloorView.selectTable')}
          </Text>
        </View>
        <View style={styles.container}>
          <View style={styles.tableLayout}>
            <MemoListTableIcons
              list={positionedTables}
              renderer={renderTableInLayout}
            />
            <MemoListTableIcons
              list={unPositionedTables}
              renderer={renderTableInLayout}
            />
          </View>
          <SectionTabs
            tabs={sectionsOptions}
            testID="segments-orderScreens"
            selectedTab={selectedSection}
            onPress={onChangeSection}
            containerStyle={styles.scaleUp}
          />
        </View>
      </View>
    </View>
  );
};

export default SelectTableModal;
