import React, { useCallback, useMemo, useState } from 'react';
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { NavigationContextProps } from '../Navigation.types';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import styles from './NavigationContext.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../Icon/Icon';
import Search from '../../../Shared/Search/Search';

const NavigationContext: React.FC<NavigationContextProps> = ({
  user,
  switcher,
  onCollapse,
}) => {
  const { width: vw, height: vh } = theme.useResponsiveDimensions();

  const [searchText, setSearchText] = useState('');
  const [showSwitcher, setShowSwitcher] = useState(false);

  const disableSwitching = (switcher?.options || []).length <= 1;
  const colors = theme.colors.pools.allColours;
  const colorKeys = Object.keys(colors).filter(key => key !== 'deepPurple');

  const avatarColors = [
    colors[10].color,
    ...colorKeys.map(key => colors[key].color),
  ];

  const options = useMemo(() => {
    const list = switcher?.options || [];
    return list.filter(x =>
      x.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, switcher?.options]);

  const onPressSwitchOrg = (orgId: string) => {
    analyticsService.capture('navigation', {
      event: 'Switched Organisation',
    });
    switcher?.onSelectOption(orgId);
    setShowSwitcher(false);
  };

  const handlePressOutside = useCallback(() => {
    setShowSwitcher(false);
  }, []);

  return (
    <View style={styles.wrapper}>
      <View testID="org-context" style={styles.container}>
        <TouchableOpacity
          testID="btn-switchOrg"
          disabled={disableSwitching}
          onPress={() => setShowSwitcher(current => !current)}
          style={styles.organisation}
        >
          <View style={styles.avatar}>
            <Text style={styles.avatarText}>{user.org.charAt(0)}</Text>
          </View>
          <View style={styles.name}>
            <Text
              testID="current-org"
              style={styles.nameText}
              numberOfLines={1}
            >
              {user.org}
            </Text>
            {!disableSwitching ? (
              <View style={styles.nameIcon}>
                <Icon size={16} name="angle-down" color={theme.colors.grey6} />
              </View>
            ) : (
              <></>
            )}
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          testID="btn-expand"
          onPress={() => onCollapse(true)}
          style={styles.btnExpand}
        >
          <Icon name="angle-double-left" size={22} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
      {showSwitcher ? (
        <TouchableWithoutFeedback onPress={handlePressOutside}>
          <View style={[styles.overlay, { width: vw, height: vh }]}>
            <View style={styles.switcher}>
              <View style={styles.list}>
                <Search
                  testID="search-locations"
                  value={searchText}
                  onChangeText={setSearchText}
                  placeholder="Search locations..."
                />
                {/* eslint-disable-next-line react-native/no-inline-styles */}
                <ScrollView style={{ flex: 1 }}>
                  <View style={styles.locations}>
                    {options.length > 0 ? (
                      options.map((option, i) => {
                        return (
                          <TouchableOpacity
                            key={`option-${i}`}
                            testID={`org-${i}`}
                            style={styles.location}
                            onPress={() =>
                              onPressSwitchOrg(String(option.value))
                            }
                          >
                            <View
                              style={[
                                styles.avatar,
                                {
                                  backgroundColor:
                                    avatarColors[i % avatarColors.length],
                                },
                              ]}
                            >
                              <Text style={styles.avatarText}>
                                {option.label.charAt(0)}
                              </Text>
                            </View>
                            <View style={styles.locationName}>
                              <Text
                                numberOfLines={1}
                                style={styles.locationNameText}
                              >
                                {option.label}
                              </Text>
                              {option.value === switcher?.selectedOption ? (
                                <Icon
                                  size={20}
                                  name="check"
                                  color={theme.colors.green}
                                />
                              ) : (
                                <Icon
                                  size={20}
                                  name="angle-right"
                                  color={theme.colors.grey5}
                                />
                              )}
                            </View>
                          </TouchableOpacity>
                        );
                      })
                    ) : (
                      <View style={theme.tables.emptyView}>
                        <Text
                          style={theme.tables.emptyText}
                        >{`No organisations matching "${searchText}" were found.`}</Text>
                      </View>
                    )}
                  </View>
                </ScrollView>
              </View>
              <View style={styles.footer}>
                <Text testID="current-user" style={styles.footerText}>
                  {user.email}
                </Text>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      ) : (
        <></>
      )}
    </View>
  );
};

export default NavigationContext;
