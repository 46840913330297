import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  backdrop: {
    flex: 1,
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 320,
    padding: 6,
    backgroundColor: theme.colors.grey2,
    rowGap: 4,
  },
  header: {
    height: 44,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  btnClose: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    flex: 1,
    marginRight: 44,
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
  },
  content: {
    flex: 1,
    width: '100%',
    padding: 10,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
  },
  itemsContainer: {
    flex: 1,
    width: '100%',
  },
  scrollContainer: {
    flex: 1,
    width: '100%',
  },
  footer: {
    height: 44,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  footerText: {
    textAlign: 'center',
    fontFamily: theme.fonts.medium,
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.black1,
  },
  btnContainer: {
    width: '100%',
  },
});

export default styles;
