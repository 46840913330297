import { gql } from '@apollo/client';

const pricingGroupFragment = `
  id
  name
  isActive
  venues{
    id
  }
  orderTypes {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  schedule {
    dateRange {
      startDate
      endDate
    }
    timeBlocks {
      day
      isActive
      timeSlot {
        startTime
        endTime
      }
    }
  }
`;
const productModifiers = `
modifierGroups{
  id
  name
  isGrouped
  isRequired,
  priority
  products {
    id
    name
    price {
      amount
      currency
    }
    isDefault
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  modifiers {
    id
    name
    isDefault
    priority
    price {
      amount
      currency
    }
    alternateNames {
      locale
      display
      kitchen
      receipt
      description
    }
    pricingGroups {
      id
      name
      prices {
        id
        sellingPrice{
          amount
          currency
        }
        sellingTax {
          id
          name
          code
          rate
        }
      }
    }
  }
  selectionLimit {
    min
    max
  }
}`;
const productFragment = `
  id
  name
  isSellable
  isDefault
  isBeingTracked
  isAvailable
  isCombo
  storesInventory
  ${productModifiers}
  stores {
    id
  }
  optionValues {
    parentOptionId
    key
    value
  }
  minSellingPrice
  maxSellingPrice
  measuredBy {
    unitType
    defaultSize
    units
  }
  variablePricing
  variableQuantity
  printerProfiles {
    id
  }
  pricings {
    id
    taxInclusive
    sellingPrice {
      amount
      currency
    }
    costPrice {
      amount
      currency
    }
    sellingTax {
      id
      name
      code
      rate
    }
    pricingGroup {
      ${pricingGroupFragment}
    }
  }
  course {
    id
    name
    priority
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  barcode
`;

export const GET_PRODUCTS = gql`
query products {
  products {
    ${productFragment}
  }
}
`;

export const GET_MENU_OPTIONS = gql`
  query menusOptions {
    catalogues {
      id
      name
      catalogueType
      stores {
        id
        name
      }
    }
  }
`;

export const CATALOGUE_EVENT = gql`
  subscription updateCatalogueEvent($catalogueId: String!, $deviceId: String) {
    updateCatalogueEvent(catalogueId: $catalogueId, deviceId: $deviceId) {
      id
    }
  }
`;
