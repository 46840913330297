import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { SHIFT_INTERVALS_30_MIN } from '@oolio-group/client-utils';
import { useNotification } from '../../../../hooks/Notification';
import {
  CreateTradingPeriodInput,
  TradingPeriod,
  DateTime,
} from '@oolio-group/domain';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useReportSettings } from '../../../../hooks/app/useReportSettings';
import { pick } from 'lodash';
import theme from '../../../../common/default-theme';
import styles from '../Reporting.styles';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import InputText from '../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

interface TradingPeriodRowProps {
  index: number;
  tradingPeriod: TradingPeriod;
  onChangeShiftRow: (
    index: number,
    id: string,
    prop: string,
    value: string,
  ) => void;
  onDeleteTradingPeriod: (index: number, id: string) => void;
}

const START_TIMES = SHIFT_INTERVALS_30_MIN;
const START_TIME_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_TIMES);
const START_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const START_DAYS_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_DAYS);
const START_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const START_MONTHS_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_MONTHS);

function generateComboOptions(
  values: Array<string>,
): Array<{ label: string; value: string }> {
  return values.map(val => ({
    label: val,
    value: val,
  })) as Array<{ label: string; value: string }>;
}

const TradingPeriodRow: React.FC<TradingPeriodRowProps> = ({
  tradingPeriod,
  onDeleteTradingPeriod,
  onChangeShiftRow,
  index,
}: TradingPeriodRowProps) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (tradingPeriod.id === undefined) {
      onDeleteTradingPeriod(index, tradingPeriod.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: tradingPeriod.name,
          })}
          onConfirm={() => {
            onDeleteTradingPeriod(index, tradingPeriod.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeleteTradingPeriod,
    translate,
    tradingPeriod,
    index,
  ]);

  return (
    <View testID="report-setting-row" style={theme.tables.row}>
      <InputText
        testID="report-shift-name"
        value={tradingPeriod.name}
        placeholder={translate('backOfficeReportSettings.tradingPeriodName')}
        onChangeText={onChangeShiftRow.bind(
          null,
          index,
          tradingPeriod?.id,
          'name',
        )}
        containerStyle={styles.inputPeriodContainer}
      />
      <TreatPicker
        testID="report-shift-startTime"
        options={START_TIME_OPTIONS || []}
        selectedValue={tradingPeriod?.startTime}
        onValueChange={onChangeShiftRow.bind(
          null,
          index,
          tradingPeriod?.id,
          'startTime',
        )}
        containerStyle={styles.inputTimeContainer}
      />
      <ButtonIcon
        testID="btn-delete"
        type="negativeLight"
        icon="trash-alt"
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, TradingPeriod & { isChanged: boolean }>;

export const ReportSettings: React.FC = () => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [tradingPeriodForm, setTradingPeriodForm] = useState<FormState>({});
  const [datesForm, setDatesForm] = useState({
    startTime: '00:00',
    startDay: 'Sunday',
    startMonth: 'January',
  });
  const [newTradingPeriods, setNewTradingPeriods] = useState<
    CreateTradingPeriodInput[]
  >([]);

  const [newDateTime, setNewDateTime] = useState<DateTime>();

  const [valueChanged, setComboValueChanged] = useState(false);

  const [deletedTradingPeriodId, setDeletedTradingPeriodId] =
    useState<string>('');

  const {
    loading,
    error,
    tradingPeriods,
    dateTime,
    getDateTime,
    updateDateTime,
    getTradingPeriods,
    createTradingPeriods,
    createdTradingPeriodIds,
    updateTradingPeriods,
    updatedTradingPeriodIds,
    deleteTradingPeriod,
    deletedTradingPeriod,
    updatedDateTime,
  } = useReportSettings();

  const { closeModal } = useModal();

  useEffect(() => {
    getDateTime();
  }, [getDateTime]);

  useEffect(() => {
    if (dateTime) {
      setDatesForm(dateTime);
      setNewDateTime(dateTime);
    }
  }, [dateTime, setNewDateTime]);

  useEffect(() => {
    getTradingPeriods();
  }, [getTradingPeriods]);

  useEffect(() => {
    if (tradingPeriods) {
      setTradingPeriodForm(tradingPeriods as FormState);
    }
  }, [tradingPeriods]);

  useEffect(() => {
    if (deletedTradingPeriod && deletedTradingPeriodId) {
      delete tradingPeriodForm[deletedTradingPeriodId];
    }
  }, [deletedTradingPeriodId, deletedTradingPeriod, tradingPeriodForm]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeleteTradingPeriod = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentTradingPeriodsCount =
          Object.keys(tradingPeriodForm).length;
        const updatedTradingPeriods = newTradingPeriods;
        updatedTradingPeriods.splice(index - currentTradingPeriodsCount, 1);
        setNewTradingPeriods([...updatedTradingPeriods]);
      } else {
        setDeletedTradingPeriodId(id);
        deleteTradingPeriod(id);
      }
    },
    [deleteTradingPeriod, newTradingPeriods, tradingPeriodForm],
  );

  useEffect((): void => {
    if (
      updatedDateTime &&
      valueChanged &&
      datesForm.startTime === newDateTime?.startTime &&
      datesForm.startDay === newDateTime?.startDay &&
      datesForm.startMonth === newDateTime?.startMonth
    ) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeReportSettings.dateTimeUpdatedSuccessfully',
        ),
      });
      setComboValueChanged(false);
    }
  }, [
    updatedDateTime,
    datesForm,
    newDateTime,
    valueChanged,
    showNotification,
    translate,
  ]);

  useEffect((): void => {
    if (deletedTradingPeriod) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeReportSettings.tradingPeriodDeletedSuccessfully',
        ),
      });
    }
  }, [deletedTradingPeriod, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedTradingPeriodIds && updatedTradingPeriodIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedTradingPeriodIds.length > 1
              ? 'backOfficeReportSettings.tradingPeriodsUpdatedSuccessfully'
              : 'backOfficeReportSettings.tradingPeriodUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedTradingPeriodIds, showNotification, translate]);

  useEffect((): void => {
    if (createdTradingPeriodIds && createdTradingPeriodIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdTradingPeriodIds.length > 1
              ? 'backOfficeReportSettings.tradingPeriodsCreatedSuccessfully'
              : 'backOfficeReportSettings.tradingPeriodCreatedSuccessfully'
          }`,
        ),
      });
      setNewTradingPeriods([]);
    }
  }, [
    createdTradingPeriodIds,
    showNotification,
    translate,
    setNewTradingPeriods,
  ]);

  const onChangeDateForm = useCallback(
    (prop: string, value: string) => {
      setDatesForm({
        ...datesForm,
        [prop]: value,
      });
      setNewDateTime({ ...datesForm });
      setComboValueChanged(true);
    },
    [datesForm, setNewDateTime, setComboValueChanged],
  );

  const onChangeShiftRow = useCallback(
    (index: number, id: string, prop: string, value: string): void => {
      if (!id) {
        const currentTradingPeriodsCount =
          Object.keys(tradingPeriodForm).length;
        const updatedTradingPeriods = newTradingPeriods;
        updatedTradingPeriods[index - currentTradingPeriodsCount] = {
          ...updatedTradingPeriods[index - currentTradingPeriodsCount],
          [prop]: value,
        };
        setNewTradingPeriods([...updatedTradingPeriods]);
      } else {
        setTradingPeriodForm(tradingPeriodForm => ({
          ...tradingPeriodForm,
          [id]: {
            ...tradingPeriodForm[id],
            [prop]: value,
            isChanged: true,
          },
        }));
      }
    },
    [tradingPeriodForm, newTradingPeriods],
  );

  const onPressSave = useCallback((): void => {
    const tradingPeriodsToUpdate = Object.values(tradingPeriodForm)
      .filter(tradingPeriod => tradingPeriod.isChanged)
      .map(tradingPeriod => pick(tradingPeriod, ['id', 'name', 'startTime']));

    if (newTradingPeriods.some(tradingPeriod => !tradingPeriod.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeReportSettings.enterTradingPeriodName'),
      });
    } else if (
      newTradingPeriods.some(tradingPeriod => !tradingPeriod.startTime)
    ) {
      showNotification({
        error: true,
        message: translate(
          'backOfficeReportSettings.enterTradingPeriodStartTimeName',
        ),
      });
    } else {
      if (newTradingPeriods.length > 0) createTradingPeriods(newTradingPeriods);
      if (tradingPeriodsToUpdate.length > 0)
        updateTradingPeriods(tradingPeriodsToUpdate);
    }
    const updatedDateTime = pick(datesForm, [
      'startTime',
      'startDay',
      'startMonth',
    ]);
    if (newDateTime) {
      updateDateTime(updatedDateTime as DateTime);
    }
  }, [
    createTradingPeriods,
    updateTradingPeriods,
    newTradingPeriods,
    newDateTime,
    showNotification,
    updateDateTime,
    translate,
    tradingPeriodForm,
    datesForm,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewTradingPeriods([
      ...newTradingPeriods,
      {} as CreateTradingPeriodInput,
    ]);
  }, [newTradingPeriods]);

  const tradingPeriodsData = [
    ...Object.values(tradingPeriodForm),
    ...(newTradingPeriods as unknown as TradingPeriod[]),
  ];

  const dateTimeData = datesForm as DateTime;

  return (
    <ScreenLayout
      title="Report Settings | Oolio"
      loading={loading}
      onSave={onPressSave}
    >
      <Section
        layoutWidth="small"
        title={translate('backOfficeReportSettings.datesTime')}
      >
        <View style={theme.forms.row}>
          <TreatPicker
            testID="report-dateTime-startTime"
            title={translate('backOfficeReportSettings.startTimeTitle')}
            options={START_TIME_OPTIONS || []}
            selectedValue={dateTimeData?.startTime}
            onValueChange={onChangeDateForm.bind(null, 'startTime')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="report-dateTime-startDay"
            title={translate('backOfficeReportSettings.startDayTitle')}
            options={START_DAYS_OPTIONS || []}
            selectedValue={dateTimeData?.startDay}
            onValueChange={onChangeDateForm.bind(null, 'startDay')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="report-dateTime-startMonth"
            title={translate('backOfficeReportSettings.startMonthTitle')}
            options={START_MONTHS_OPTIONS || []}
            selectedValue={dateTimeData?.startMonth}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      </Section>
      <Section
        layoutWidth="small"
        title={translate('backOfficeReportSettings.tradingPeriods')}
      >
        <View style={styles.tableContainer}>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellPeriod]}>
              {translate('backOfficeReportSettings.tradingPeriodName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellTime]}>
              {translate('backOfficeReportSettings.startTime')}
            </Text>
            <View style={styles.cellActions} />
          </View>
          <View>
            {tradingPeriodsData.map((period: TradingPeriod, i: number) => (
              <TradingPeriodRow
                key={i}
                index={i}
                tradingPeriod={period}
                onChangeShiftRow={onChangeShiftRow}
                onDeleteTradingPeriod={onDeleteTradingPeriod}
              />
            ))}
          </View>
        </View>
        <CreateButton onPress={onPressCreateNew} />
      </Section>
    </ScreenLayout>
  );
};
