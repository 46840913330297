import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Money, Currency, DEFAULT_TAX, ComboType } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useNotification } from '../../../../../hooks/Notification';
import { isValidPrice } from '../../../../../utils/validator';
import theme from '../../../../../common/default-theme';
import { Option } from '../../../../../components/Shared/Select/Select';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import SegmentControl from '../../../../../components/Shared/SegmentControl/SegmentControl';
import { useModal } from '@oolio-group/rn-use-modal';
import { ComboTypeModal } from './ComboTypesModal';
import { analyticsService } from '../../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../../constants';

export interface CreateProductForm {
  name: string;
  category?: string;
  sellingPrice?: Money;
  sellingTax: string;
  isVariant?: boolean;
  taxInclusive?: boolean;
  productType?: string;
  isCopyMode?: boolean;
  sourceProductId?: string;
  isCombo?: boolean;
  comboType?: ComboType;
}

enum ProductType {
  PRODUCT = 'PRODUCT',
  COMBO = 'COMBO',
}

interface CreateProductModalProps {
  loading: boolean;
  createProduct?: Partial<CreateProductForm>;
  taxesOptions: Option[];
  productTypeOptions: {
    label: string;
    value: string;
  }[];
  categoryOptions: Option[];
  closeModal: () => void;
  onCreate: (prodInput: CreateProductForm, editMore: boolean) => void;
}

export const CreateProductModal: React.FC<CreateProductModalProps> = ({
  onCreate,
  taxesOptions,
  createProduct: createProductProp,
  categoryOptions,
  productTypeOptions,
  closeModal,
  loading,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { currency, currencySymbol } = useCurrency();
  const { showModal } = useModal();

  const [selectedProductType, setSelectedProductType] = useState(
    ProductType.PRODUCT,
  );
  const [isRegularComboEnabled, setRegularComboFeatureFlag] = useState(false);

  useEffect(() => {
    async function getRegularComboFeatureFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.REGULAR_COMBO,
      );
      setRegularComboFeatureFlag(isEnabled);
    }
    getRegularComboFeatureFlag();
  }, []);

  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value ||
    taxesOptions?.[0]?.value;

  const defaultReportingGroup = productTypeOptions?.[0]?.value;

  const [productForm, setProductForm] = useState<CreateProductForm>({
    sellingTax: defaultTax,
    category: categoryOptions?.[0]?.value,
    name: '',
  });

  const sellingPrice = productForm?.sellingPrice?.amount;
  const { isCopyMode } = productForm;

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'sellingPrice') {
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }
      setProductForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [currencySymbol, currency],
  );

  const isCombo = useMemo(
    () => selectedProductType === ProductType.COMBO,
    [selectedProductType],
  );

  const onSelectComboType = useCallback(
    (comboType: ComboType, productInput: CreateProductForm) => {
      productInput.comboType = comboType;
      onCreate(productInput, true);
    },
    [onCreate],
  );

  const onCreateProduct = useCallback(
    editMore => {
      let message = '';
      if (!productForm.name) {
        message = translate('productSettings.pleaseEnterProductName');
      } else if (!productForm.isVariant && !productForm?.sellingPrice?.amount) {
        message = translate(
          'productSettings.pleaseSelectSellingPriceForTheProduct',
        );
      } else if (!productForm.isVariant && !productForm?.sellingTax) {
        message = translate('productSettings.pleaseSelectSellTaxForTheProduct');
      } else if (
        !productForm.isVariant &&
        !isValidPrice(String(productForm.sellingPrice?.amount))
      ) {
        message = translate('form.requiredField', {
          fieldName: 'Selling Price',
        });
      } else {
        let createProdForm = { ...productForm };
        if (!productForm.isVariant) {
          createProdForm = {
            ...productForm,
            taxInclusive: true,
            sellingPrice: {
              amount: +(productForm?.sellingPrice?.amount || 0),
              currency: productForm?.sellingPrice?.currency as Currency,
            },
          };
          //navigate to compo type modal before creating it
          if (isCombo) {
            closeModal();
            createProdForm = {
              ...createProdForm,
              productType: createProdForm.productType || defaultReportingGroup,
              isCombo: true,
            };
            if (!createProdForm.isCopyMode) {
              showModal(
                <ComboTypeModal
                  onSelect={onSelectComboType}
                  productInput={createProdForm}
                  closeModal={closeModal}
                />,
              );
              return;
            }
          }
        }
        onCreate(createProdForm, editMore);
      }
      if (message) {
        showNotification({
          error: true,
          message,
        });
      }
    },
    [
      onCreate,
      productForm,
      showNotification,
      translate,
      isCombo,
      defaultReportingGroup,
      onSelectComboType,
      showModal,
      closeModal,
    ],
  );

  useEffect(() => {
    if (!Object.values(createProductProp || {})?.length) return;
    setProductForm({
      ...(createProductProp as CreateProductForm),
      sellingTax: createProductProp?.sellingTax || defaultTax,
    });
  }, [defaultTax, createProductProp]);

  const setProductType = useCallback((type: string) => {
    setSelectedProductType(
      type === ProductType.PRODUCT ? ProductType.PRODUCT : ProductType.COMBO,
    );
  }, []);

  const renderProductTypeForm = useCallback(() => {
    const isProduct = selectedProductType === ProductType.PRODUCT;
    return (
      <>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('productSettings.productName')}
            placeholder={translate('productSettings.productName')}
            value={productForm.name}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
          />
          <TreatPicker
            testID="select-category"
            title={translate('backOfficeProducts.selectCategory')}
            options={[
              {
                label: translate(
                  'backOfficeProducts.selectCategoryPlaceholder',
                ),
                value: '',
              },
            ].concat(categoryOptions)}
            selectedValue={productForm?.category || ''}
            onValueChange={value => onChange('category', value)}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        {isProduct && !productForm.isVariant && (
          <View style={theme.forms.row}>
            <InputText
              numericOnly
              testID="input-name"
              title={translate('productSettings.sellingPrice')}
              label={currencySymbol}
              placeholder={'0.00'}
              value={sellingPrice ? sellingPrice.toString() : ''}
              onChangeText={onChange.bind(null, 'sellingPrice')}
              containerStyle={theme.forms.inputHalf}
            />
            <TreatPicker
              testID="select-tax"
              title={translate('productSettings.sellingTax')}
              options={taxesOptions}
              selectedValue={productForm.sellingTax}
              onValueChange={onChange.bind(null, 'sellingTax')}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        )}
        {isProduct && !isCopyMode && (
          <View style={theme.forms.row}>
            <InputToggle
              testID="hasVariant-toggle"
              title={translate('productSettings.hasVariants')}
              isToggled={productForm.isVariant || false}
              onToggle={onChange.bind(
                null,
                'isVariant',
                !productForm.isVariant,
              )}
              containerStyle={theme.forms.inputFluid}
            />
          </View>
        )}

        {!isProduct && (
          <View style={theme.forms.row}>
            <InputText
              numericOnly
              testID="input-name"
              title={translate('productSettings.basePrice')}
              label={currencySymbol}
              placeholder={'0.00'}
              value={sellingPrice ? sellingPrice.toString() : ''}
              onChangeText={onChange.bind(null, 'sellingPrice')}
              containerStyle={theme.forms.inputHalf}
            />
            <TreatPicker
              testID="select-tax"
              title={translate('productSettings.productType')}
              options={productTypeOptions}
              selectedValue={productForm.productType}
              onValueChange={onChange.bind(null, 'productType')}
              containerStyle={theme.forms.inputHalf}
            />
          </View>
        )}
      </>
    );
  }, [
    categoryOptions,
    currencySymbol,
    isCopyMode,
    onChange,
    productForm,
    selectedProductType,
    sellingPrice,
    translate,
    productTypeOptions,
    taxesOptions,
  ]);

  const disabledTab = useMemo(() => {
    const product = !!(productForm.isCopyMode && productForm.isCombo);
    const combo = !!(productForm.isCopyMode && !productForm.isCombo);
    if (product) setSelectedProductType(ProductType.COMBO);
    else if (combo) setSelectedProductType(ProductType.PRODUCT);
    return { product, combo };
  }, [productForm]);

  return (
    <TreatModal
      size="medium"
      type="positive"
      loading={loading}
      title={translate('productSettings.quickCreateProduct')}
      onConfirm={{
        label: translate(
          isCombo ? 'button.continue' : 'productSettings.create',
        ),
        action: onCreateProduct.bind(null, false),
      }}
      secondaryButton={
        !isCombo
          ? {
              label: translate('productSettings.editMore'),
              type: 'neutral',
              action: onCreateProduct.bind(null, true),
            }
          : undefined
      }
      onDismiss={{ action: closeModal }}
    >
      {isRegularComboEnabled && (
        <SegmentControl
          secondary
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginBottom: 20 }}
          selectedTab={selectedProductType}
          onPress={val => setProductType(val)}
          tabs={[
            {
              label: translate('backOfficeProducts.product'),
              value: ProductType.PRODUCT,
              ...(disabledTab.product && { disabled: true }),
            },
            {
              label: translate('productSettings.comboProduct'),
              value: ProductType.COMBO,
              ...(disabledTab.combo && { disabled: true }),
            },
          ]}
        />
      )}

      {renderProductTypeForm()}
    </TreatModal>
  );
};
