import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import {
  getAddressFields,
  AddressFields,
  useTranslation,
} from '@oolio-group/localization';
import InputText from '../Inputs/InputText';
import TreatPicker from '../Select/Picker';
import { isValidPostCode } from '../../../utils/validator';

interface Form {
  line1?: string;
  line2?: string;
  city?: string;
  suburb?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export interface AddressFormProps {
  onChangeAddress: (key: string, value: string) => void;
  value: Form;
  excludedFields?: AddressFields[];
  children?: React.ReactNode;
}

const AddressForm: React.FC<AddressFormProps> = ({
  onChangeAddress,
  value: form,
  excludedFields = [],
  children,
}: AddressFormProps) => {
  const country = form?.country ?? 'AU';
  const addressFields = getAddressFields(country).filter(
    (field: AddressFields) => !excludedFields.includes(field),
  );
  const { translate } = useTranslation();

  const renderAddressFields = useCallback(
    (field: AddressFields) => {
      switch (field) {
        case AddressFields.LINE1:
          return (
            <InputText
              testID="input-line1"
              title={translate('form.addressLine1')}
              value={form?.line1 || ''}
              placeholder={translate('form.addressLine1')}
              onChangeText={onChangeAddress.bind(null, 'line1')}
              containerStyle={theme.forms.inputHalf}
            />
          );

        case AddressFields.LINE2:
          return (
            <InputText
              testID="input-line2"
              title={translate('form.addressLine2')}
              value={form?.line2 || ''}
              placeholder="Optional"
              onChangeText={onChangeAddress.bind(null, 'line2')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.CITY:
          return (
            <InputText
              testID="input-city"
              title={translate('form.city')}
              value={form?.city || ''}
              placeholder={translate('form.city')}
              onChangeText={onChangeAddress.bind(null, 'city')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.SUBURB:
          return (
            <InputText
              testID="input-suburb"
              title={translate('form.suburb')}
              value={form?.suburb || ''}
              placeholder={translate('form.suburb')}
              onChangeText={onChangeAddress.bind(null, 'suburb')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.STATE:
          return (
            <InputText
              testID="input-state"
              title={translate('form.state')}
              value={form?.state || ''}
              placeholder={translate('form.state')}
              onChangeText={onChangeAddress.bind(null, 'state')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.COUNTY:
          return (
            <InputText
              testID="input-county"
              title={translate('form.county')}
              value={form?.state || ''}
              placeholder={translate('form.county')}
              onChangeText={onChangeAddress.bind(null, 'state')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.CODE:
          return (
            <InputText
              testID="input-code"
              title={translate('form.code')}
              value={form?.postalCode}
              placeholder={translate('form.code')}
              errorMessage={
                form?.postalCode &&
                !isValidPostCode(form?.postalCode, form?.country)
                  ? translate('form.invalidInput', {
                      title: translate('form.code'),
                    })
                  : undefined
              }
              onChangeText={onChangeAddress.bind(null, 'postalCode')}
              containerStyle={theme.forms.inputHalf}
            />
          );
        case AddressFields.COUNTRY:
          return (
            <TreatPicker
              testID="select-country"
              title={translate('form.country')}
              selectedValue={form?.country || ''}
              options="countries"
              onValueChange={code => onChangeAddress('country', code)}
              containerStyle={theme.forms.inputHalf}
            />
          );

        default:
          break;
      }
    },
    [form, onChangeAddress, translate],
  );

  return (
    <View style={styles.container}>
      {addressFields.map((x: AddressFields) => renderAddressFields(x))}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    zIndex: -1,
    gap: 20,
    marginBottom: 20,
  },
});

export default AddressForm;
