import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  ModifierGroup as ModifierGroupDefault,
  DEFAULT_ENTITY_ID,
  UpdateModifierGroupInput,
  AlternateName,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { useTranslation } from '@oolio-group/localization';
import { encodeAlternateNameValuesToBase64 } from '@oolio-group/client-utils';
import { useNotification } from '../../../../../hooks/Notification';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { Operation } from '../../../../../types/Operation';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../../common/default-theme';
import styles from '../Options.styles';
import TranslationModal from '../TranslationModal';
import Icon from '../../../../../components/Icon/Icon';
import Search from '../../../../../components/Shared/Search/Search';
import Section from '../../../../../components/Office/Section/Section';
import TreatPicker from '../../../../../components/Shared/Select/Picker';
import Identifier from '../../../../../components/Shared/Identifier/Identifier';
import Pagination from '../../../../../components/Office/Pagination/Pagination';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import CreateButton from '../../../../../components/Office/CreateButton/CreateButton';

interface ModifierGroup extends ModifierGroupDefault {
  isSelected?: boolean;
}

export const OptionGroups: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    error,
    loading,
    modifierGroups: optionsGroups,
    operation,
    updateModifierGroups: updateOptionsGroups,
    getAllModifierGroups,
    cloneModifierGroup,
    createdIds,
  } = useModifierGroups();

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState('all');
  const [optionsGroupsData, setOptionsGroupsData] = useState<
    Record<string, ModifierGroup>
  >({});

  useEffect(() => {
    isFocused && getAllModifierGroups();
  }, [getAllModifierGroups, isFocused]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      operation === Operation.CREATE &&
      createdIds.length
    ) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupCopiedSuccessfully'),
      });
      // navigate to edit modifier group page
      navigation.navigate('CreateModifierGroupTab', {
        modifierGroupId: createdIds[0],
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdIds,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && optionsGroups) {
      setOptionsGroupsData(optionsGroups);
    }
  }, [error, loading, optionsGroups]);

  const optionsGroupsArray = useMemo(() => {
    return Object.values(optionsGroupsData || {});
  }, [optionsGroupsData]);

  const filteredList = useMemo(() => {
    return optionsGroupsArray
      .filter(option =>
        option.name.toLowerCase().includes(searchText.toLowerCase()),
      )
      .filter(option => {
        const isProductGroup = option.products && option.products.length >= 1;
        if (selectedType === 'modifiers')
          return option.modifiers.length > 0 && !isProductGroup;
        if (selectedType === 'products') return isProductGroup;
        return true;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [optionsGroupsArray, searchText, selectedType]);

  const pageItems = useMemo(() => {
    return filteredList.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [filteredList, currentPage]);

  const onCreateOptionsGroup = useCallback((): void => {
    navigation.navigate('OptionGroupTabs', {
      title: 'New Group',
      modifierGroupId: DEFAULT_ENTITY_ID,
    });
  }, [navigation]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && optionsGroups[id]) {
        const selectedOptionGroup = optionsGroups[id];
        showModal(
          <ConfirmationModal
            type="neutral"
            title={translate('modifiers.copyModifierGroup')}
            confirmLabel={translate('modifiers.copy')}
            message={translate('modifiers.modifierGroupCopyDiscription', {
              name: selectedOptionGroup.name,
            })}
            onConfirm={() => {
              cloneModifierGroup({
                id: selectedOptionGroup.id,
              });
            }}
          />,
        );
      }
    },
    [showModal, translate, optionsGroups, cloneModifierGroup],
  );

  const onConfirmTranslations = useCallback(
    (id: string, alternateNames: AlternateName[]) => {
      const optGroups = optionsGroupsArray.filter(x => x && x.id == id);
      if (optGroups?.length) {
        const optGroupsModified: UpdateModifierGroupInput[] = stripProperties(
          optGroups,
          '__typename',
        );
        updateOptionsGroups(
          optGroupsModified.map(x => ({
            id: x.id,
            name: x.name,
            alternateNames: encodeAlternateNameValuesToBase64(alternateNames),
          })) as unknown as UpdateModifierGroupInput[],
        );
        closeModal();
      }
    },
    [optionsGroupsArray, closeModal, updateOptionsGroups],
  );

  const openTranslationModal = useCallback(
    (id: string) => {
      if (id) {
        const modifierAltNames = optionsGroups?.[id]?.alternateNames || [];
        showModal(
          <TranslationModal
            alternateNames={modifierAltNames}
            id={id}
            onConfirm={onConfirmTranslations}
          />,
        );
      }
    },
    [showModal, optionsGroups, onConfirmTranslations],
  );

  const getOptionsText = (group: ModifierGroup) => {
    if (!group.products?.length && !group.modifiers?.length) {
      return translate('productSettings.none');
    }

    if (group.products && group.products.length > 0) {
      return group.products?.map(m => m.name).join(', ');
    } else {
      return group.modifiers?.map(p => p.name).join(', ');
    }
  };

  const GROUP_TYPE_OPTIONS = [
    { label: translate('modifiers.allGroups'), value: 'all' },
    { label: translate('modifiers.modifierGroups'), value: 'modifiers' },
    { label: translate('modifiers.productGroups'), value: 'products' },
  ];

  return (
    <ScreenLayout hideFooter loading={loading} title="Options Groups | Oolio">
      <Section
        layoutWidth="large"
        title={translate('modifiers.optionGroups')}
        subtitle={translate('modifiers.optionGroupsSubtitle')}
      >
        <View style={styles.filters}>
          <TreatPicker
            testID="select-type"
            selectedValue={selectedType}
            onValueChange={setSelectedType}
            options={GROUP_TYPE_OPTIONS}
            containerStyle={styles.dropdown}
          />
          <Search
            testID="search-groups"
            value={searchText}
            placeholder={translate('modifiers.searchOptionGroups')}
            onChangeText={setSearchText}
            containerStyle={styles.search}
          />
          <CreateButton onPress={onCreateOptionsGroup} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('modifiers.optionGroup')}
            </Text>
            <Text style={theme.tables.headerText}>
              {translate('modifiers.options')}
            </Text>
          </View>
          {pageItems.length > 0 ? (
            pageItems.map((group, i) => {
              const isProductGroup =
                group.products && group.products.length >= 1;

              const isEmpty =
                !group.products?.length && !group.modifiers?.length;

              return (
                <TouchableOpacity
                  key={i}
                  testID="row-group"
                  style={theme.tables.row}
                  onPress={(): void =>
                    navigation.navigate('OptionGroupTabs', {
                      title: group.name,
                      modifierGroupId: group.id,
                    })
                  }
                >
                  <Identifier
                    entity={isProductGroup ? 'product' : 'modifier'}
                    containerStyles={styles.identifier}
                  />
                  <Text numberOfLines={1} style={styles.cellName}>
                    {group.name}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={[
                      styles.cellOptions,
                      {
                        color: isEmpty
                          ? theme.colors.grey4
                          : theme.colors.black1,
                      },
                    ]}
                  >
                    {getOptionsText(group)}
                  </Text>
                  <ButtonIcon
                    testID="btn-translations"
                    type="neutralLight"
                    icon="english-to-chinese"
                    onPress={() => openTranslationModal(group.id)}
                  />
                  <ButtonIcon
                    icon="copy"
                    type="neutralLight"
                    testID="btn-copy"
                    onPress={() => onPressCopy(group.id)}
                    containerStyle={styles.cellCopy}
                  />
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey5}
                    />
                  </View>
                </TouchableOpacity>
              );
            })
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {Object.values(optionsGroupsData).length < 1
                  ? translate('modifiers.noOptionGroupsCreated')
                  : translate('common.noMatches', {
                      entity: searchText,
                    })}
              </Text>
            </View>
          )}
          <Pagination
            page={currentPage}
            onPageChange={setCurrentPage}
            dataLength={filteredList.length}
            pageLength={pageItems.length}
            entityName={translate('modifiers.optionGroups')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
