import { useTranslation } from '@oolio-group/localization';
import {
  Icons,
  LoyaltySettings,
  RewardRule,
  StyleFn,
} from '@oolio-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text, ScrollView } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import { getLoyaltyUnit } from '@oolio-group/client-utils';
import Gradient from '../../Gradient/Gradient';

const containerStyle: StyleFn = ({}) => ({
  alignSelf: 'center',
  alignItems: 'center',
  width: 380,
  height: 580,
});

const statisticView: StyleFn = ({ theme }) => ({
  height: 180,
  width: 380,
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: theme.radius.large,
  borderTopRightRadius: theme.radius.large,
  backgroundColor: theme.colors.blue,
});

const earnText: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  ...theme.font14SemiBold,
  fontSize: 20,
  marginTop: theme.spacing.small,
});

const availableRewardsHeading: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: theme.fontSize.medium,
  textAlign: 'center',
});

const earnPointText: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  marginTop: theme.spacing.small,
  color: theme.colors.white,
  textAlign: 'center',
});

const scrollViewStyle: StyleFn = ({}) => ({
  flex: 1,
  minHeight: 300,
});

const requirePointStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.textLight,
  textAlign: 'center',
  padding: theme.padding.small,
});

const rewardNameStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.grey2,
  textAlign: 'center',
  padding: theme.padding.small,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  textTransform: 'uppercase',
});

const actionButtonContainer: StyleFn = ({ theme }) => ({
  marginTop: theme.padding.medium,
  paddingHorizontal: theme.padding.large,
});

const dismissButton: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  backgroundColor: theme.colors.greyLight,
  marginBottom: theme.spacing.small,
  paddingHorizontal: theme.padding.large,
});

const rewardItemContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  paddingVertical: theme.padding.large - 4,
  paddingHorizontal: theme.padding.large,
  marginTop: theme.padding.large,
  borderRadius: theme.border.medium,
  width: '100%',
});

const formContainer: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  alignItems: 'center',
});

const bodyContainerStyle: StyleFn = ({ theme }) => ({
  paddingVertical: theme.padding.large,
  backgroundColor: theme.colors.white,
  borderBottomLeftRadius: theme.radius.large,
  borderBottomRightRadius: theme.radius.large,
  width: 380,
  height: 450,
});

export interface ShowAvailableRewardsProps {
  pointsBalance?: number;
  onDismiss: () => void;
  pointsEarned: number;
  availableRewards: RewardRule[];
  loyaltySettings: Partial<LoyaltySettings>;
}

const ShowAvailableRewards: React.FC<ShowAvailableRewardsProps> = ({
  pointsBalance,
  pointsEarned,
  onDismiss,
  availableRewards,
  loyaltySettings,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(containerStyle)}>
      <View style={css(statisticView)}>
        <Gradient
          style={css(statisticView)}
          colors={['#3E82F0', '#7C4DFF']}
          start={theme.gradient.startAxis}
          end={theme.gradient.endAxis}
          locations={theme.gradient.location}
        >
          <IconButton
            icon={Icons.Star}
            iconColor={theme.colors.white}
            iconSize={50}
          />
          <Text style={css(earnText)} testID="earn-text">
            {translate('customerLoyalty.pointsEarned', {
              points: pointsEarned,
              unit: getLoyaltyUnit(pointsEarned, loyaltySettings),
            })}
          </Text>
          <Text style={css(earnPointText)}>
            {translate('customerLoyalty.earnedPointsThisPurchase', {
              points: pointsEarned,
              unit: getLoyaltyUnit(pointsEarned, loyaltySettings),
            })}
          </Text>
          <Text style={css(earnPointText)} testID={'total-balance'}>
            {translate('customerLoyalty.totalPoints', {
              points: pointsBalance || 0,
              unit: getLoyaltyUnit(pointsBalance || 0, loyaltySettings),
            })}{' '}
          </Text>
        </Gradient>
      </View>
      <View style={css(bodyContainerStyle)}>
        <ScrollView style={css(scrollViewStyle)}>
          <View style={css(formContainer)}>
            <Text style={css(availableRewardsHeading)}>
              {translate('customerLoyalty.availableRewards')}
            </Text>
            {availableRewards.length ? (
              availableRewards.map(reward => {
                return (
                  <View
                    key={reward.id}
                    style={css(rewardItemContainer)}
                    testID="reward-item"
                  >
                    <Text style={css(requirePointStyle)}>
                      {`${reward.pointsRequired} ${getLoyaltyUnit(
                        reward.pointsRequired,
                        loyaltySettings,
                      )}`}
                    </Text>
                    <Text style={css(rewardNameStyle)}>
                      {reward.rewardName}
                    </Text>
                  </View>
                );
              })
            ) : (
              <View style={css(rewardItemContainer)}>
                <Text style={css(requirePointStyle)} testID="no-reward">
                  {translate('customerLoyalty.noRewardsEarned')}
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
        <View style={css(actionButtonContainer)}>
          <Button
            key={'dismiss'}
            testID={'dismiss'}
            containerStyle={css(dismissButton)}
            labelStyle={css(dismissButtonStyle)}
            title={translate('button.dismiss')}
            onPress={onDismiss}
            fluid
          />
        </View>
      </View>
    </View>
  );
};

export default ShowAvailableRewards;
