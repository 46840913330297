import { gql } from '@apollo/client';

const CUSTOMER_BASIC_QUERY = `
  id
  firstName
  lastName
  name
  phone
  email
`;

export const CUSTOMER_ACCOUNT_FRAGMENT = `
    id
    firstName
    lastName
    name
    email
    phone
    phoneNumber
    preferredAddress {
      line1
      line2
      suburb
      city
      postalCode
      isoCountryCode
      state
    }
    customerAccountDetails {
      maxOrderLimit
      maxBalanceLimit
      currentBalance
      accountPayment
    }
    loyaltyMember
    loyaltyPointsBalance
    lifetimeLoyaltyPoints
    createdAt
    enrolledAt
    loyaltyEnrolmentSource
    fetchedAt
    dob
    code
  `;

export const GET_CUSTOMERS = gql`
  query getCustomers($filter:CustomerFilterInput, $limit: Int){
    customers(filter: $filter,limit:$limit) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customerById($id: ID!){
    customerById(id: $id){
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const GET_CUSTOMER_BY_EMAIL_QUERY = gql`
  query customer($email: String!) {
    customer(email: $email) {
      ${CUSTOMER_BASIC_QUERY}
    }
  }
`;

export const GET_CUSTOMER_BY_PHONE_QUERY = gql`
  query customer($phone: String!) {
    customer(phone: $phone) {
      ${CUSTOMER_BASIC_QUERY}
    }
  }
`;

export const GET_CUSTOMER_FRAGMENT = gql`
fragment ICustomer on Customer {
  ${CUSTOMER_ACCOUNT_FRAGMENT}
}`;

export const SEND_CUSTOMER_STATEMENT = gql`
  query sendCustomerStatement($statementFilter: StatementFilterInput) {
    sendCustomerStatement(statementFilter: $statementFilter)
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const ENROLL_CUSTOMER_LOYALTY = gql`
  mutation enrollCustomerLoyalty($input: EnrollCustomerInput!) {
    enrollCustomerLoyalty(input: $input)
    {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;
export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
`;

export const DELIST_LOYALTY_CUSTOMER = gql`
  mutation delistLoyaltyCustomer($id: ID!) {
    delistLoyaltyCustomer(id: $id)
    {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const GET_CUSTOMER_ACCOUNT_SETTINGS = gql`
  query customerAccountSettings {
    customerAccountSettings {
      defaultMaxOrderLimit
      defaultMaxBalanceLimit
      scheduleStatements
    }
  }
`;

export const UPDATE_CUSTOMER_BALANCE_EVENT = gql`
  mutation syncCustomerBalanceEvents($input: [CustomerBalanceEventInput!]) {
    syncCustomerBalanceEvents(input: $input)
  }
`;

export const GET_PAGINATED_CUSTOMERS = gql`
  query getCustomers(
    $filter: CustomerFilterInput
    $limit: Int
    $after: String
  ) {
    paginatedCustomers(filter: $filter, limit: $limit, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ${CUSTOMER_ACCOUNT_FRAGMENT}
        }
      }
    }
  }
`;
