/* eslint-disable react-native/no-inline-styles */
import React, { FC, useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  AdjustmentType,
  OrderItemComboProduct,
  Modifier,
  OrderItem,
  OrderItemModifier,
  OrderItemStatus,
  VoidReason,
  OptionValue,
} from '@oolio-group/domain';
import {
  REFUND_ORDER_ITEM,
  computeOrderItemTotal,
  getAdjustmentLabel,
  getItemSavedAmount,
} from '@oolio-group/order-helper';
import {
  useCurrency,
  useTranslation,
  useLocalization,
} from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import styles from './CartProduct.styles';
import theme from '../../../../common/default-theme';
import CartNote from '../CartNote/CartNote';
import { CartSelectionState } from '../Cart';

export interface CartProductProps {
  testID: string;
  orderItem: OrderItem;
  seat?: string;
  isPaid?: boolean;
  isFired?: boolean;
  isSelected?: boolean;
  selectedSubItem?: string;
  minimised?: boolean;
  disabled?: boolean;
  onSelect?: (state: CartSelectionState) => void;
}

interface ProductParentProps {
  orderItem: OrderItem;
  seat?: string;
  isPaid?: boolean;
  isFired?: boolean;
  isVoided: boolean;
  isTransferred: boolean;
  selectedState?: 'full' | 'partial';
  isVariableQty?: boolean;
  disabled?: boolean;
  getProductName: (orderItem: OrderItem) => string;
  onPress?: () => void;
}

const ProductParent: FC<ProductParentProps> = ({
  orderItem,
  seat,
  isPaid,
  isFired,
  isVoided,
  isTransferred,
  isVariableQty,
  selectedState,
  disabled,
  onPress,
  getProductName,
}) => {
  const { formatCurrency } = useCurrency();

  const bgStyle =
    selectedState === 'full'
      ? styles.selectedView
      : selectedState === 'partial'
      ? styles.partialSelectedView
      : isVoided
      ? styles.voidView
      : isTransferred
      ? styles.transferredView
      : isPaid
      ? styles.paidView
      : {};
  const textStyle =
    selectedState === 'full'
      ? styles.selectedText
      : selectedState === 'partial'
      ? styles.partialSelectedText
      : isVoided
      ? styles.voidText
      : isTransferred
      ? styles.transferredText
      : isPaid
      ? styles.paidText
      : {};

  return (
    <TouchableOpacity
      testID="order-item"
      onPress={onPress}
      style={styles.container}
      disabled={isTransferred || disabled}
    >
      <View style={[styles.quantity, bgStyle]}>
        <Text testID="qty" style={[styles.quantityText, textStyle]}>
          {`${orderItem.status === OrderItemStatus.REFUNDED ? '-' : ''}${
            isVariableQty ? '%' : orderItem.quantity
          }`}
        </Text>
      </View>
      <View style={[styles.name, bgStyle]}>
        {isFired ? (
          <Text testID="fired" style={[styles.firedText, textStyle]}>
            ✓
          </Text>
        ) : null}
        <Text
          testID="name"
          numberOfLines={2}
          ellipsizeMode="middle"
          style={[styles.nameText, textStyle]}
        >
          {getProductName(orderItem)}
        </Text>
        <Text testID="price" style={[styles.priceText, textStyle]}>
          {formatCurrency(computeOrderItemTotal(orderItem))}
        </Text>
        {!!seat ? (
          <Text testID="seat" style={[styles.seatText, textStyle]}>
            {seat}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const CartProduct: FC<CartProductProps> = ({
  testID,
  orderItem,
  seat,
  isPaid,
  isFired,
  isSelected,
  selectedSubItem,
  minimised,
  disabled,
  onSelect,
}) => {
  const { locale } = useLocalization();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const savedAmount = getItemSavedAmount(orderItem);

  const isDisabledOnPress = useMemo(
    () =>
      !!orderItem.comboItems?.length &&
      ![
        OrderItemStatus.DRAFT,
        OrderItemStatus.CREATED,
        OrderItemStatus.ON_HOLD,
      ].includes(orderItem.status),
    [orderItem.comboItems?.length, orderItem.status],
  );

  const isComboItem = !!orderItem.comboItems?.length;
  const variations = orderItem.product?.optionValues;
  const isVoided = orderItem.status === OrderItemStatus.VOID;
  const isTransferred = orderItem.status === OrderItemStatus.TRANSFERRED;
  const isVariableQty = orderItem.product?.variableQuantity;

  const getSizeText = (orderItem: OrderItem) => {
    const units = orderItem.product.measuredBy.units;
    const defaultSize = orderItem.product.measuredBy.defaultSize;

    const sizeText = `${
      orderItem.quantity * defaultSize
    }${units} NET @ ${formatCurrency(
      orderItem.unitPrice,
    )}/${defaultSize}${units}`;

    return sizeText;
  };

  const getProductName = (orderItem: OrderItem) => {
    const localeEntity = getLocaleEntity(
      orderItem.variant ? orderItem.variant : orderItem.product,
      locale.languageTag,
    );
    const productName =
      orderItem.product?.optionValues?.length > 0
        ? orderItem.product?.name?.split('-')[0]
        : orderItem.product?.name;

    return (
      localeEntity.name ||
      productName ||
      (!orderItem.product?.id ? REFUND_ORDER_ITEM.name : 'Unknown')
    );
  };

  const getModifierName = (modifier: OrderItemModifier) => {
    const entity = getLocaleEntity(
      modifier as unknown as Modifier,
      locale.languageTag,
    );

    return entity.name || modifier.name;
  };

  const onSelectVariation = useCallback(
    (variant: string, comboProduct?: OrderItemComboProduct): void => {
      if (comboProduct) {
        if (isDisabledOnPress) return;
        const parentOptionGroupId = comboProduct.modifierGroupId,
          productId = comboProduct.product.id;

        onSelect?.({
          item: orderItem.id,
          product: orderItem.product.id,
          combo: {
            parentModifierGroupId: parentOptionGroupId,
            comboProductId: productId,
            selectedVariantId: comboProduct.variant?.id,
            selectedComboKey: variant,
          },
        });
        return;
      }

      onSelect &&
        onSelect({
          item: orderItem.id,
          product: orderItem.product.id,
          variant: orderItem.variant.id,
          selectedVariantKey: variant,
        });
    },
    [orderItem, onSelect, isDisabledOnPress],
  );

  const onPressProduct = useCallback(() => {
    const quantity = orderItem.quantity;
    onSelect &&
      onSelect({
        item: orderItem.id,
        product: orderItem.product?.id,
        variant: orderItem.variant?.id,
        quantity,
      });
  }, [orderItem, onSelect]);

  const onSelectOption = useCallback(
    (modifier: string, modifierGroup: string, tempModifierId: string) => {
      onSelect &&
        !isComboItem &&
        onSelect({
          item: orderItem.id,
          modifier,
          product: orderItem.product.id,
          modifierGroup,
          selectedModifierKey: tempModifierId,
        });
    },
    [onSelect, isComboItem, orderItem],
  );

  const onSelectComboOption = useCallback(
    (
      selectedComboKey: string,
      product: OrderItemComboProduct,
      modifier?: OrderItemModifier,
    ) => {
      if (isDisabledOnPress) return;

      const parentOptionGroupId = product.modifierGroupId,
        productId = product.product.id,
        childOptionGroupId = modifier?.modifierGroupId;
      onSelect?.({
        item: orderItem.id,
        product: orderItem.product?.id,
        combo: {
          parentModifierGroupId: parentOptionGroupId,
          childModifierGroupId: childOptionGroupId,
          comboProductId: productId,
          selectedComboKey,
          ...(modifier &&
            product.variant?.id && { selectedVariantId: product.variant.id }),
        },
      });
    },
    [onSelect, orderItem.id, orderItem.product?.id, isDisabledOnPress],
  );

  const onSelectAdjustment = useCallback(() => {
    onSelect &&
      onSelect({
        item: orderItem.id,
        selectedItemKeyForAdjustment: orderItem.id,
      });
  }, [orderItem.id, onSelect]);

  const isPartiallySelected = isSelected && selectedSubItem != undefined;
  const isAdjustmentSelected = selectedSubItem === orderItem.id;

  const ParentProduct = (
    <ProductParent
      disabled={disabled}
      orderItem={orderItem}
      seat={seat}
      isPaid={isPaid}
      isFired={isFired}
      isVoided={isVoided}
      isTransferred={isTransferred}
      selectedState={
        isPartiallySelected ? 'partial' : isSelected ? 'full' : undefined
      }
      isVariableQty={isVariableQty}
      onPress={onPressProduct}
      getProductName={getProductName}
    />
  );

  const renderComboProducts = (orderItem: OrderItem) => {
    if (!orderItem.comboItems?.length) return null;

    return (
      <View>
        {orderItem.comboItems.map((product, i) => {
          const productQty = product.quantity || 1;
          const modifiers = product.modifiers ?? [];
          const tempProductId = `${orderItem.id}-${product.id}-${i}`;
          const selected = tempProductId === selectedSubItem;
          const partiallySelected = isSelected && !selected;

          const ProductRender = (
            <>
              <TouchableOpacity
                key={`combo-products-${i}`}
                testID="btn-comboItems"
                onPress={() =>
                  !isDisabledOnPress &&
                  onSelectComboOption(tempProductId, product)
                }
                style={[
                  styles.container,
                  styles.comboProduct,
                  { opacity: isVoided ? 0.3 : 1 },
                ]}
              >
                <View
                  style={[
                    styles.quantity,
                    { backgroundColor: theme.colors.white },
                    isVoided && productQty > 1 && styles.voidView,
                    selected && productQty > 1 && styles.selectedView,
                    partiallySelected &&
                      productQty > 1 &&
                      styles.partialSelectedView,
                  ]}
                >
                  <Text testID="combo-product-qty" style={styles.quantityText}>
                    {productQty || 1}
                  </Text>
                </View>
                <View
                  style={[
                    styles.name,
                    { backgroundColor: theme.colors.white },
                    isVoided && styles.voidView,
                    selected && styles.selectedView,
                    partiallySelected && styles.partialSelectedView,
                  ]}
                >
                  <Text
                    testID="combo-product-name"
                    style={[
                      styles.nameText,
                      selected && styles.selectedText,
                      partiallySelected && styles.partialSelectedText,
                    ]}
                  >
                    {getProductName(product as OrderItem)}
                  </Text>
                  <Text
                    testID="combo-product-price"
                    style={[
                      styles.priceText,
                      selected && styles.selectedText,
                      partiallySelected && styles.partialSelectedText,
                    ]}
                  >
                    {formatCurrency(
                      (orderItem.quantity || 0) *
                        (product.amount || 0) *
                        (productQty || 1),
                    )}
                  </Text>
                </View>
              </TouchableOpacity>
              {renderVariations(product.product.optionValues, product)}
            </>
          );
          const RenderModifier = renderModifier(modifiers, product);

          return (
            <>
              {ProductRender}
              {RenderModifier}
            </>
          );
        })}
      </View>
    );
  };

  const renderModifier = (
    modifiers: OrderItemModifier[],
    comboProduct?: OrderItemComboProduct,
  ) => {
    if (!modifiers?.length) return null;

    return modifiers?.map((modifier, i) => {
      const productId = comboProduct ? `-${comboProduct.id}-` : '-';
      const tempOptionId = `${orderItem.id}${productId}${modifier.id}-${i}`;
      const selected = tempOptionId === selectedSubItem;
      const partiallySelected = isSelected && !selected;
      const comboProductQuantity = comboProduct?.quantity || 1;
      const modQuantity = modifier.quantity || 1;
      const modUnitPrice = modifier.unitPrice || 0;
      const modifierPrice = formatCurrency(
        (orderItem.quantity || 1) *
          comboProductQuantity *
          modUnitPrice *
          modQuantity,
      );

      return (
        <TouchableOpacity
          key={`modifier-${i}`}
          testID="btn-modifier"
          onPress={
            comboProduct
              ? onSelectComboOption.bind(
                  null,
                  tempOptionId,
                  comboProduct,
                  modifier,
                )
              : onSelectOption.bind(
                  null,
                  modifier.id || '',
                  modifier?.modifierGroupId || '',
                  tempOptionId,
                )
          }
          style={[
            styles.container,
            isComboItem && styles.comboProduct,
            { opacity: isVoided ? 0.3 : 1 },
          ]}
        >
          <View
            style={[
              styles.quantity,
              { backgroundColor: theme.colors.white },
              isVoided && modQuantity > 1 && styles.voidView,
              selected && modQuantity > 1 && styles.selectedView,
              partiallySelected &&
                modQuantity > 1 &&
                styles.partialSelectedView,
            ]}
          >
            <Text
              testID="mod-qty"
              style={[styles.quantityText, isComboItem && styles.modifierText]}
            >
              {modQuantity > 1 ? modQuantity : ''}
            </Text>
          </View>
          <View
            style={[
              styles.name,
              { backgroundColor: theme.colors.white },
              isVoided && styles.voidView,
              selected && styles.selectedView,
              partiallySelected && styles.partialSelectedView,
            ]}
          >
            <Text
              testID="mod-name"
              style={[
                styles.nameText,
                isComboItem && styles.modifierText,
                selected && styles.selectedText,
                partiallySelected && styles.partialSelectedText,
              ]}
            >
              {getModifierName(modifier)}
            </Text>
            <Text
              testID="mod-price"
              style={[
                styles.priceText,
                selected && styles.selectedText,
                partiallySelected && styles.partialSelectedText,
              ]}
            >
              {modifierPrice}
            </Text>
          </View>
        </TouchableOpacity>
      );
    });
  };

  const renderVariations = (
    variations: OptionValue[],
    orderItemComboProduct?: OrderItemComboProduct,
  ) => {
    if (!variations?.length) return null;
    return variations?.map((variant, i) => {
      const selected = variant.key === selectedSubItem && isSelected;
      const partiallySelected = variant.key !== selectedSubItem && isSelected;

      return (
        <TouchableOpacity
          key={`variation-${i}`}
          testID="btn-variant"
          style={[
            styles.container,
            styles.variation,
            isVoided && styles.voidView,
            selected && styles.selectedView,
            partiallySelected && styles.partialSelectedView,
            orderItemComboProduct && styles.comboOptionValue,
            { opacity: isVoided ? 0.3 : 1 },
          ]}
          onPress={onSelectVariation.bind(
            null,
            variant.key,
            orderItemComboProduct,
          )}
        >
          <Text
            testID="variant"
            style={[
              styles.nameText,
              selected && styles.selectedText,
              partiallySelected && styles.partialSelectedText,
            ]}
          >
            {variant.value}
          </Text>
        </TouchableOpacity>
      );
    });
  };

  if (minimised) {
    return ParentProduct;
  }

  return (
    <View testID={testID}>
      {/* Parent */}
      {ParentProduct}
      {/* Product Size */}
      {isVariableQty ? (
        <View
          style={[
            styles.container,
            styles.variation,
            isVoided && styles.voidView,
          ]}
        >
          <Text testID="size" style={styles.nameText}>
            {getSizeText(orderItem)}
          </Text>
        </View>
      ) : null}
      {/* Voids */}
      {isVoided ? (
        <View style={[styles.container, styles.voidReason]}>
          <Text testID="void-reason" style={styles.nameText}>
            {translate('order.itemVoidReason', {
              value: translate(`enums.${orderItem.reason as VoidReason}`),
            })}
          </Text>
        </View>
      ) : null}
      {/* Notes */}
      {orderItem.notes?.length > 0 ? (
        <CartNote testID="note" note={orderItem.notes} productNote={true} />
      ) : null}
      {/* Variations */}
      {renderVariations(variations)}

      {renderComboProducts(orderItem)}
      {renderModifier(orderItem.modifiers)}

      {/* Adjustments */}
      {orderItem.discountAmount &&
      orderItem.adjustments?.length &&
      orderItem.adjustments[0].adjustmentType !== AdjustmentType.REWARD ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <TouchableOpacity
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? theme.colors.blueLight
                  : theme.colors.white,
              },
              isAdjustmentSelected && styles.selectedView,
            ]}
            onPress={onSelectAdjustment.bind(null)}
          >
            <Text
              testID="discount-name"
              style={[
                styles.nameText,
                isAdjustmentSelected && styles.selectedText,
              ]}
            >
              {getAdjustmentLabel(orderItem.adjustments?.[0])}
            </Text>
            <Text
              testID="discount-amt"
              style={[
                styles.priceText,
                isAdjustmentSelected && styles.selectedText,
              ]}
            >
              {formatCurrency(-Math.abs(orderItem.discountAmount) || 0)}
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
      {orderItem.surchargeAmount ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <View
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? theme.colors.blueLight
                  : theme.colors.white,
              },
            ]}
          >
            <Text testID="surcharge-name" style={styles.nameText}>
              {getAdjustmentLabel(orderItem.adjustments?.[0])}
            </Text>
            <Text testID="surcharge-amt" style={styles.priceText}>
              {formatCurrency(orderItem.surchargeAmount || 0)}
            </Text>
          </View>
        </View>
      ) : null}
      {!!savedAmount && (
        <Text style={styles.savingsText}>
          {translate('order.savedPrice', {
            amount: formatCurrency(savedAmount),
          })}
        </Text>
      )}
    </View>
  );
};

export default CartProduct;
