import { useCallback, useEffect, useState } from 'react';
import { analyticsService } from '../analytics/AnalyticsService';
import {
  ApiError,
  LoyaltyApi,
  TokenStore,
  ErrorResponse,
  LoyaltyProgram,
  AvailableRewards,
  RewardRule,
  RewardType,
  EnrollmentSource,
  Order as OomOrder,
  EstimatedSnapshot,
  Pageable,
  Member,
} from '@oolio-group/loyalty-sdk';
import { tokenUtility } from '../state/tokenUtility';
import { useSession } from './app/useSession';
import {
  BaseRewardType,
  Customer,
  RewardRule as LegacyRewardRule,
  RewardType as LegacyRewardType,
  LoyaltySnapshot,
  Order,
  Product,
  Variant,
} from '@oolio-group/domain';
import { getAllRedeemedRewards } from '@oolio-group/client-utils';
import { FEATURES } from '../constants';

const tokenStore: TokenStore = {
  getToken() {
    return tokenUtility.token as string;
  },
};

interface Props {
  getProgram?: boolean;
}

export const useOolioLoyalty = (
  { getProgram }: Props = { getProgram: false },
) => {
  const [session] = useSession();
  const [loyaltyApi] = useState(() => {
    return new LoyaltyApi({
      baseUrl: process.env.REACT_APP_LOYALTY_API_URL as string,
      organization: session.currentOrganization?.id as string,
      tokenStore,
    });
  });
  const [isLoyaltyEnabled, setLoyaltyEnabled] = useState(false);

  useEffect(() => {
    analyticsService
      .isFeatureEnabled(FEATURES.OOLIO_LOYALTY)
      .then(setLoyaltyEnabled);
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse>();
  const [availableRewards, setAvailableRewards] = useState<AvailableRewards>();
  const [program, setProgram] = useState<LoyaltyProgram>();
  const [members, setMembers] = useState<Pageable<Member>>();

  const getAvailableRewards = useCallback(
    (memberId: string) => {
      setLoading(true);
      loyaltyApi
        .getMemberAvailableRewards(memberId)
        .then(setAvailableRewards)
        .catch((e: ApiError) => setError(e.body))
        .finally(() => setLoading(false));
    },
    [loyaltyApi, setAvailableRewards, setError],
  );

  const enrollLoyaltyMember = useCallback(
    (customerId: string) => {
      return loyaltyApi.enrollMember(customerId, true, EnrollmentSource.POS);
    },
    [loyaltyApi],
  );

  useEffect(() => {
    if (getProgram) {
      setLoading(true);
      loyaltyApi
        .getLoyaltyProgram()
        .then(setProgram)
        .catch((e: ApiError) => setError(e.body))
        .finally(() => setLoading(false));
    }
  }, [getProgram, loyaltyApi]);

  const [snapshot, setSnapshot] = useState<EstimatedSnapshot>();

  const estimateSnapshot = useCallback(
    async (customerId: string, order: Order, balance?: number) => {
      setLoading(true);
      const currentBalance =
        balance ??
        (await loyaltyApi.getMemberBalance(customerId)).availableBalance;
      const oomOrder = transformOrderToOom(order);
      try {
        const estimated = await loyaltyApi.estimateSnapshot(
          customerId,
          currentBalance,
          oomOrder,
        );
        setSnapshot(estimated);
        return estimated;
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError.body) {
          setError(apiError.body);
        }
      } finally {
        setLoading(false);
      }
    },
    [loyaltyApi],
  );

  const searchMembers = useCallback(
    async (query: string, limit?: number, cursor?: string) => {
      setLoading(true);
      try {
        const members = await loyaltyApi.searchMembers(query, limit, cursor);
        setMembers(members);
        return members;
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError.body) {
          setError(apiError.body);
        }
      } finally {
        setLoading(false);
      }
    },
    [loyaltyApi],
  );

  return {
    isLoyaltyEnabled,
    loading,
    error,
    program,
    availableRewards,
    snapshot,
    members,
    getAvailableRewards,
    enrollLoyaltyMember,
    estimateSnapshot,
    searchMembers,
  };
};

export const mapRewardToLegacyReward = (
  reward: RewardRule,
  products?: Record<string, Product>,
  variants?: Record<string, Variant>,
): LegacyRewardRule => {
  const baseReward: BaseRewardType = {
    id: reward.id,
    rewardName: reward.name,
    pointsRequired: reward.pointsRequired,
  } as BaseRewardType;
  // to fix enum matching
  reward.rewardType = RewardType[reward.rewardType];
  switch (reward.rewardType) {
    case RewardType.DISCOUNT_ENTIRE_SALE:
      return {
        ...baseReward,
        rewardType: LegacyRewardType.DISCOUNT_ENTIRE_SALE,
        discountAmount: reward.payload.discountAmount.amount,
        discountType: reward.payload.discountAmount.type,
        maximumDiscountAmount: reward.maximumDiscount,
      };
    case RewardType.DISCOUNT_CATEGORY: // TODO: migrate to own's case when discount category is supported
    case RewardType.DISCOUNT_PRODUCT:
      return {
        ...baseReward,
        rewardType: LegacyRewardType.PRODUCT_DISCOUNT,
        discountAmount: reward.payload.discountAmount.amount,
        discountType: reward.payload.discountAmount.type,
        maximumDiscountAmount: reward.maximumDiscount,
        products: reward.payload.applicableItems.map(
          item =>
            (products?.[item.refId] ||
              variants?.[item.refId] || { id: item.refId }) as Product,
        ),
      };
    case RewardType.FREE_ITEM:
      return {
        ...baseReward,
        rewardType: LegacyRewardType.FREE_ITEM,
        products: reward.payload.applicableItems.map(
          item =>
            (products?.[item.refId] ||
              variants?.[item.refId] || { id: item.refId }) as Product,
        ),
      };
  }
};

export const mapMemberToCustomer = (member: Member): Customer => {
  return {
    id: member.id,
    firstName: member.firstName,
    lastName: member.lastName,
    name: [member.firstName, member.lastName].join(' '),
    email: member.email,
    phone: member.phone,
    preferredAddress: member.preferredAddress,
    loyaltyMember: member.enrolled,
    loyaltyPointsBalance: member.balance?.availableBalance,
    lifetimeLoyaltyPoints: member.balance?.lifeTimeBalance,
    createdAt: member.createdAt,
    enrolledAt: member.enrolledAt,
    loyaltyEnrolmentSource: member.enrolledThrough,
    dob: member.dob,
    code: member.code,
  };
};

export const mapLoyaltySnapshot = (
  estimated: EstimatedSnapshot,
): LoyaltySnapshot => {
  const snapshotResult = {
    pointsEarned: estimated.pointsEarned,
    availableBalance: estimated.availableBalance,
    availableRewards: estimated.availableRewards.map(reward =>
      mapRewardToLegacyReward(reward),
    ),
  } as LoyaltySnapshot;

  return snapshotResult;
};

const transformOrderToOom = (order: Order): OomOrder => {
  // Note: return a minimally transformed order, with just enough information for the Loyalty to work
  const redeemedRewards = getAllRedeemedRewards(order);

  return {
    id: order.id,
    externalOrderRef: order.orderNumber,
    posLocationId: order.store.id,
    taxes: order.taxes.map(tax => ({
      value: convertDollarsToCents(tax.amount),
    })) as OomOrder['taxes'],
    totalAfterSurcounts: convertDollarsToCents(order.totalPaymentAmount),
    totalBeforeSurcounts: convertDollarsToCents(order.subTotal),
    items: order.orderItems.map(item => {
      // if its a variant product, use the parent product's id
      const productId = item.variant?.id ? item.variant.id : item.product.id;
      return { posId: productId, quantity: item.quantity };
    }) as OomOrder['items'],
    loyalty: {
      memberId: order.customer?.id,
      redeemedRewards: redeemedRewards.map(reward => ({
        rewardId: reward.id,
        quantity: reward.itemQuantity || reward.quantity,
      })),
    },
  } as unknown as OomOrder;
  // TODO: fix OomOrder type to add loyalty section
};

const convertDollarsToCents = (value: number | unknown): number | unknown => {
  if (typeof value === 'number')
    return Math.round((value as number) * 100) as number;
  return value;
};
