import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  Platform,
  StatusBar,
  KeyboardAvoidingView,
} from 'react-native';
import { Helmet } from 'react-helmet';
import {
  Address,
  BusinessSize,
  BusinessStatus,
  BusinessType,
  OnboardInput,
} from '@oolio-group/domain';
import {
  TIMEZONES_MAP,
  Timezone,
  getCountry,
  useTranslation,
  getAddressPlaceholder,
} from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { capitalCase } from 'change-case';
import { useSignUp } from '../../../hooks/app/useSignUp';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';
import { useUserProfile } from '../../../hooks/app/useUserProfile';
import { useAuthentication } from '../../../hooks/app/useAuthentication.web';
import { getSession } from '../../../state/preferences';
import { delay } from '../../../utils/delay';
import { fetchSsoProfile } from '../../../utils/sso';
import { isValidBusinessName } from '../../../utils/validator';
import styles from './OnboardScreen.styles';
import theme from '../../../common/default-theme';
import Logo from '../../../components/Logo/Logo';
import Gradient from '../../../components/Gradient/Gradient';
import Select from '../../../components/Shared/Select/Select';
import InputText from '../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../components/Shared/Inputs/InputPhone';
import SelectAddress from '../../../components/Shared/Select/SelectAddress';
import TreatButton from '../../../components/Shared/TreatButton/TreatButton';
import LoadingScreen from '../../Loading/Loading';
import SegmentControl from '../../../components/Shared/SegmentControl/SegmentControl';
import {
  DEFAULT_COUNTRY_CODE,
  BUSINESS_SIZE_OPTIONS,
  BUSINESS_STATUS_OPTIONS,
} from '../../../constants';

const TIMEZONES: Array<Timezone> = Object.values(TIMEZONES_MAP);

// This screen is only mean to be routed from Oolio portal
// expect to be web only
const Onboard: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const route = useRoute<any>();
  const navigation = useNavigation();
  const { getMe } = useUserProfile();
  const [, setSession] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { loading, onboardOrganization } = useSignUp();
  const { width, height } = theme.useResponsiveDimensions();

  const [step, setStep] = useState(1);
  const [isValidatingOrg, setIsValidatingOrg] = useState(false);
  const [isSignupDisabled, setIsSignupDisabled] = useState(false);
  const [phoneCountryCode, setPhoneCountryCode] =
    useState(DEFAULT_COUNTRY_CODE);

  const [formData, setFormData] = useState<OnboardInput>({
    organization: route.params['orgId'],
    businessName: route.params['orgName'],
    user: route.params['user'],
    country: '',
    businessType: BusinessType.CAFETERIA,
    size: BusinessSize.SMALL,
    status: BusinessStatus.NEW_BUSINESS,
    address: undefined as Address | undefined,
  });

  const _onSignIn = useCallback((): void => {
    navigation.navigate('LoginTypeSelection');
  }, [navigation]);

  const validateOrganizations = useCallback(async () => {
    try {
      setIsValidatingOrg(true);
      const user = await getMe();
      const activeOrg = user.organizations?.find(
        org => org.id === route.params['orgId'],
      );
      if (activeOrg) {
        // manually get from localstorage to avoid deps loop
        const session = await getSession();
        setSession({
          ...session,
          currentOrganization: activeOrg,
        });
        navigation.navigate('BackOffice');
        return;
      }
      throw new Error('Organization not onboarded');
    } catch (error) {
      const user = await fetchSsoProfile();
      if (
        !user ||
        !user.organizations?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (org: any) => org.id === route.params['orgId'],
        )
      ) {
        showNotification({
          message: 'organization not exist',
          error: true,
        });
        _onSignIn();
      }
    } finally {
      setIsValidatingOrg(false);
    }
  }, [
    getMe,
    route.params,
    setSession,
    navigation,
    showNotification,
    _onSignIn,
  ]);

  // Try to finish exchange token
  useAuthentication({
    redirectUrl: location.origin + '/onboarding',
    onAuthorized: validateOrganizations,
  });

  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };

  const addressProvided = Object.keys(formData.address || {}).length > 0;
  const countryProvided = !!formData?.country;

  const isValid = (): boolean => {
    const { businessName } = formData;

    const passes =
      isValidBusinessName(businessName) && addressProvided && countryProvided;
    setIsSignupDisabled(!passes);

    return passes;
  };

  const onboardOrganizationToPos = async (): Promise<void> => {
    if (isValid()) {
      // format phone
      const phone = phoneCountryCode
        ? `${getCountry(phoneCountryCode).phone} ${formData.phone}`
        : formData.phone;

      // TODO: add csurf for this unguarded api
      const result = await onboardOrganization({
        ...formData,
        phone,
      });

      if (result.error) {
        showNotification({ error: true, message: result.error });
      } else if (result.success) {
        showNotification({
          success: true,
          message:
            'Thank you for signing up, check your inbox and confirm your email address to login!',
        });
        await delay(3000);
        navigation.navigate('LoginTypeSelection');
      }
    }
  };

  const _onNext = (): void => {
    if (isValid()) {
      setStep(step + 1);
    }
  };

  const _onPrev = (): void => {
    setStep(step - 1);
  };

  const onSelectAddress = useCallback((address: Address) => {
    const _countryCode = address?.isoCountryCode || DEFAULT_COUNTRY_CODE;
    const defaultTimezone = TIMEZONES.find((_tz: Timezone) => {
      return _tz.country === _countryCode;
    });
    const selectedCountry = getCountry(_countryCode);

    setFormData(formData => ({
      ...formData,
      address,
      country: _countryCode,
      timezone: defaultTimezone?.name,
      currencyCode: selectedCountry?.currency,
    }));
  }, []);

  const addressHint = getAddressPlaceholder(
    !!formData?.country ? formData?.country : phoneCountryCode,
  );

  if (isValidatingOrg) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line react-native/no-raw-text */}
        <title>{`Sign Up | ${translate('appName')}`}</title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={{ width, height }}>
        <Gradient style={styles.screen}>
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <View style={styles.content}>
              <Logo type="logotype" color="primary" />
              {step === 1 ? (
                <View style={styles.form}>
                  <View style={theme.forms.row}>
                    <InputText
                      testID="business-name"
                      title="Business Name"
                      value={formData.businessName}
                      placeholder="Enter name..."
                      onChangeText={text => _onChangeProp('businessName', text)}
                      errorMessage={
                        !isValidBusinessName(formData.businessName) &&
                        isSignupDisabled
                          ? 'Invalid Business Name'
                          : undefined
                      }
                      isVerified={isValidBusinessName(formData.businessName)}
                      containerStyle={theme.forms.inputHalf}
                    />
                    <Select
                      testID="business-type"
                      title="Business Type"
                      placeholder="Select type..."
                      value={formData.businessType}
                      selectedValue={formData.businessType}
                      options={Object.values(BusinessType).map(value => ({
                        label: capitalCase(value),
                        value: value as string,
                      }))}
                      onValueChange={text =>
                        _onChangeProp('businessType', text)
                      }
                      containerStyle={theme.forms.inputHalf}
                    />
                  </View>
                  <View style={theme.forms.row}>
                    <InputPhone
                      testID="phone-number"
                      title="Phone Number"
                      value={formData.phone || ''}
                      onChangeText={text => _onChangeProp('phone', text)}
                      defaultCountry={phoneCountryCode}
                      onPressCountry={(country: string): void =>
                        setPhoneCountryCode(country)
                      }
                      containerStyle={theme.forms.inputHalf}
                    />
                  </View>
                  {/* eslint-disable-next-line react-native/no-inline-styles */}
                  <View style={[theme.forms.row, { marginBottom: 0 }]}>
                    <SelectAddress
                      testID="business-address"
                      title="Business Address"
                      placeholder={addressHint}
                      onChangeAddress={onSelectAddress}
                      address={formData.address as Address}
                      errorMessage={
                        !addressProvided && isSignupDisabled
                          ? 'Invalid Business Address'
                          : undefined
                      }
                      containerStyle={theme.forms.inputFluid}
                    />
                  </View>
                </View>
              ) : (
                <View>
                  <Text style={styles.title}>
                    Help us tailor the best experience for you and your business
                    by answering these couple of questions.
                  </Text>
                  <View>
                    <Text style={styles.subtitle}>
                      {`Which best describes ${formData.businessName}?`}
                    </Text>
                    <SegmentControl
                      secondary
                      testID="segments-businessType"
                      tabs={BUSINESS_STATUS_OPTIONS}
                      onPress={text => _onChangeProp('status', text)}
                      selectedTab={
                        formData.status || BusinessStatus.NEW_BUSINESS
                      }
                    />
                  </View>
                  <View>
                    <Text style={styles.subtitle}>
                      {`How many stores does ${formData.businessName} have?`}
                    </Text>
                    <SegmentControl
                      secondary
                      testID="segments-businessSize"
                      tabs={BUSINESS_SIZE_OPTIONS}
                      onPress={text => _onChangeProp('size', text)}
                      selectedTab={formData.size || BusinessSize.SMALL}
                    />
                  </View>
                </View>
              )}
              <View style={styles.footer}>
                {step === 1 ? (
                  <>
                    <TreatButton
                      testID="signin-button"
                      label="Login"
                      type="primaryLight"
                      onPress={_onSignIn}
                      disabled={loading}
                    />
                    <TreatButton
                      testID="continue-button"
                      label="Continue"
                      type="primary"
                      onPress={_onNext}
                      isLoading={loading}
                      disabled={loading}
                    />
                  </>
                ) : (
                  <>
                    <TreatButton
                      testID="back-button"
                      label="Back"
                      type="primaryLight"
                      onPress={_onPrev}
                      disabled={loading}
                    />
                    <TreatButton
                      testID="signup"
                      label="Get Started!"
                      type="primary"
                      onPress={onboardOrganizationToPos}
                      isLoading={loading}
                      disabled={loading}
                    />
                  </>
                )}
              </View>
            </View>
          </KeyboardAvoidingView>
        </Gradient>
      </View>
    </>
  );
};

export default Onboard;
