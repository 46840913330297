/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleFn, RenderProps } from '@oolio-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import {
  GestureResponderEvent,
  View,
  ActivityIndicator,
  ViewStyle,
  TextStyle,
  NativeSyntheticEvent,
  TargetedEvent,
  TouchableOpacity,
} from 'react-native';
import Title from '../Title/Title';
import Icon, { IconProps } from '../Icon/Icon';
import scale from '../../common/theme';
import { Label } from '../FormInput/FormInput';
import WarningFillIcon from '../../common/assets/WarningFill';

const buttonSizes: { [key: string]: number } = {
  xsmall: scale.moderateScale(16),
  small: scale.moderateScale(24),
  medium: scale.moderateScale(36),
  large: scale.moderateScale(40),
};

const ButtonStyle: StyleFn = ({
  theme,
  primary,
  circular,
  success,
  secondary,
  transparent,
  keypad,
  danger,
  warning,
  info,
  fluid,
  pill,
  color,
  iconPosition,
  raised,
  size,
  disabled,
}) => ({
  flexDirection:
    (iconPosition && (iconPosition === 'left' ? 'row' : 'row-reverse')) ||
    'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  paddingHorizontal: theme.padding.medium,
  marginVertical: 0,
  elevation: raised ? scale.moderateScale(4) : 0,
  marginHorizontal: fluid ? 0 : circular ? 0 : 0,
  width: fluid ? '100%' : undefined,
  height: size ? buttonSizes[size] : null,
  borderRadius: circular
    ? scale.moderateScale(60)
    : pill
    ? scale.moderateScale(12)
    : scale.moderateScale(3),
  backgroundColor: primary
    ? theme.colors.primary
    : keypad
    ? theme.colors.background
    : disabled
    ? theme.colors.boxBorder
    : success
    ? theme.colors.success
    : secondary
    ? theme.colors.secondary
    : danger
    ? theme.colors.danger
    : warning
    ? theme.colors.warning
    : info
    ? theme.colors.info
    : transparent
    ? null
    : color
    ? color
    : theme.colors.white,
  borderColor: raised ? theme.colors.boxBorder : null,
  shadowColor: raised ? theme.colors.charcoal : null,
  shadowOpacity: raised ? scale.moderateScale(0.3) : null,
  shadowRadius: raised ? scale.moderateScale(3.5) : null,
});

const selectedBtnStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: theme.spacing.small,
  backgroundColor: theme.colors.white,
  height: scale.moderateScale(4),
  width: scale.moderateScale(4),
  borderRadius: theme.radius.small,
});

export const BtnSelect: React.FC<any> = (props: any) => (
  <FelaComponent selected={props.selected} style={selectedBtnStyle}>
    {({ style }: RenderProps): React.ReactNode => (
      <View style={[style, props.style]} {...props} />
    )}
  </FelaComponent>
);

export interface LoadingProps {
  iconProps?: Partial<IconProps>;
}

const Loading: React.FC<LoadingProps> = ({ iconProps }) => (
  <FelaComponent>
    {({ theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator color={theme.colors.white} {...iconProps} />
    )}
  </FelaComponent>
);

export interface ButtonProps {
  testID?: string;
  title?: string;
  onPress?: (event?: GestureResponderEvent) => void;
  onLongPress?: (event?: GestureResponderEvent) => void;
  onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
  primary?: boolean;
  circular?: boolean;
  success?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  danger?: boolean;
  fluid?: boolean;
  pill?: boolean;
  keypad?: boolean;
  selected?: boolean;
  warning?: boolean;
  info?: boolean;
  loading?: boolean;
  color?: string;
  height?: string | number;
  containerStyle?: ViewStyle | Object;
  labelStyle?: TextStyle;
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  iconProps?: Partial<IconProps>;
  raised?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  iconContainerStyle?: ViewStyle;
  icon?: string;
  numberOfLines?: number;
  loadingIconProps?: Partial<IconProps>;
  trackedItemQuantity?: number;
  markedAsAllergic?: boolean;
  allergicItemStyle?: ViewStyle;
  numberOfTrackedItemsStyle?: ViewStyle;
  activeOpacity?: number;
}

const Button: React.FC<ButtonProps> = ({
  onPress,
  onLongPress,
  selected,
  title,
  loading,
  containerStyle,
  iconContainerStyle,
  icon,
  markedAsAllergic,
  allergicItemStyle,
  trackedItemQuantity,
  numberOfTrackedItemsStyle,
  ...props
}: ButtonProps) => (
  <FelaComponent style={ButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <TouchableOpacity
        testID={props.testID}
        onPress={onPress}
        onLongPress={onLongPress}
        style={[style, containerStyle]}
        disabled={props.disabled}
        sentry-label={title}
        {...props}
      >
        {selected && <BtnSelect selected={selected} />}
        {markedAsAllergic ? (
          <View style={allergicItemStyle}>
            <WarningFillIcon />
          </View>
        ) : null}

        {trackedItemQuantity !== undefined ? (
          <Label textStyle={numberOfTrackedItemsStyle}>
            {trackedItemQuantity}
          </Label>
        ) : null}
        {loading ? (
          <Loading iconProps={props.loadingIconProps} />
        ) : (
          <>
            {icon && (
              <View style={iconContainerStyle}>
                <Icon name={icon} {...props.iconProps} />
              </View>
            )}
            <Title {...props}>{title}</Title>
          </>
        )}
      </TouchableOpacity>
    )}
  </FelaComponent>
);

Button.defaultProps = {
  size: 'medium',
};

export default Button;
