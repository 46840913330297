import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  layoutContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  layout: {
    width: 1004,
    height: 680,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  sections: {
    position: 'absolute',
    top: 20,
    zIndex: 999,
    alignSelf: 'center',
    flexDirection: 'row',
    padding: 8,
    borderWidth: 1,
    borderRadius: theme.radius.m,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  btnSection: {
    minWidth: 100,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: theme.radius.s,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    alignSelf: 'center',
    alignItems: 'center',
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderRadius: theme.radius.m,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.white,
    ...theme.shadow.light,
  },
  actionGroup: {
    marginRight: 12,
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: theme.colors.grey3,
  },
  btnAction: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: theme.radius.s,
  },
  btnInfo: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.medium,
  },
  legend: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    marginRight: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  marker: {
    width: 12,
    height: 12,
    marginRight: 4,
    borderRadius: 12,
    backgroundColor: theme.colors.accent,
  },
  statusText: {
    fontSize: 14,
    lineHeight: 16,
    color: theme.colors.grey6,
    fontFamily: theme.fonts.medium,
  },
});

export default styles;
