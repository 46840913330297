import {
  App,
  BusinessSize,
  BusinessStatus,
  CatalogueType,
  LOCALE,
  OrderStatus,
} from '@oolio-group/domain';
import { Platform } from 'react-native';
import {
  REACT_APP_OAUTH2_CLIENTID,
  REACT_APP_OOLIO_DOMAIN,
  REACT_APP_OAUTH2_REDIRECT_URL,
  REACT_APP_OAUTH2_AUDIENCE,
} from 'react-native-dotenv';
import { capitalCase } from 'change-case';

/**
 * Default country code for phone number input
 */
export const DEFAULT_COUNTRY_CODE = 'AU';

export const DURATION_FORMAT = ['days', 'hours', 'minutes', 'seconds'];
export const DATABASE_NAME = 'tillx';
export const APOLLO_CACHE_PERSIST_KEY = 'apollo_cache_persist';
export const DATE_FORMAT = 'hh:mm aa dd/MM';

export const DUMP_CHANGE = '2';

export const CATALOGUE_TYPE_OPTIONS = [
  { label: 'menus.posMenu', value: CatalogueType.POS },
  { label: 'menus.kioskMenu', value: CatalogueType.KIOSK },
  { label: 'menus.onlineMenu', value: CatalogueType.ONLINE },
];

interface CachedOrderSetting {
  value: number;
  sortByField: string;
}

export interface ImportEntityStorage {
  productEntityId: string;
  isImportProductMessageCleared?: boolean;
  isProductListMessageCleared?: boolean;
}

export interface ExportEntityStorage {
  productEntityId: string;
}

export const NUMBER_LIMIT_OF_CACHED_ORDERS = {
  [OrderStatus.COMPLETED]: {
    value: 50,
    sortByField: 'updatedAt',
  },
} as Record<string, CachedOrderSetting>;

export enum AuthState {
  LOGGED_IN = 'logged In',
  LOGOUT = 'logout',
  LOADING = 'loading',
}

export interface TokenInfo {
  token?: string;
  idToken?: string;
  refreshToken?: string;
  expiresAfter?: number;
  authState?: AuthState;
  // Set when clicked on `POS app` button
  // This will be cleared once user landed on backoffice
  posAppToken?: string;
  // Set once user logged-in to the Backoffice
  // This will be cleared once user logged-out
  // ENHANCEMENT: once we enabled rotate tokens, we can keep session for longer hours / days
  backOfficeToken?: string;
  activeApp?: App;
}

export const auth0Config = {
  issuer:
    'https://auth.' +
    (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN),
  clientId:
    process.env['REACT_APP_OAUTH2_CLIENTID'] || REACT_APP_OAUTH2_CLIENTID,
  redirectUrl: Platform.select({
    default: 'com.oolio.pos://localhost/callback',
    web:
      process.env['REACT_APP_OAUTH2_REDIRECT_URL'] ||
      REACT_APP_OAUTH2_REDIRECT_URL,
  }),
  additionalParameters: {
    audience:
      process.env['REACT_APP_OAUTH2_AUDIENCE'] || REACT_APP_OAUTH2_AUDIENCE,
    postLogoutRedirect: Platform.select({
      default: 'com.oolio.pos://localhost/callback',
      web:
        process.env['REACT_APP_OAUTH2_REDIRECT_URL'] ||
        REACT_APP_OAUTH2_REDIRECT_URL,
    }),
  },
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  deviceCodeUrl: `https://auth.${
    process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN
  }/oauth/device/code`,
  getTokenUrl: `https://auth.${
    process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN
  }/oauth/token`,
  serviceConfiguration: {
    endSessionEndpoint: Platform.select({
      default:
        'https://auth.' +
        (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
        '/oidc/logout',
      web:
        'https://' +
        (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
        '/oauth2/sign_out',
    }),
  },
};

export const FEATURES = {
  MENU_SCHEDULE: 'MenuSchedule',
  REGULAR_COMBO: 'RegularCombo',
  IMPORT_PRODUCT: 'ImportProduct',
  CATEGORY_DISCOUNT: 'CategoryDiscount',
  MIN_SUPPORTED_VERSION: 'MinSupportedVersion',
  BETA_REPORTS: 'BetaReports',
  PRINTER_POLLING: 'PrinterPolling',
  RECORD_SESSIONS: 'RecordSessions',
  OOLIO_RESERVATION: 'Reservations',
  OOLIO_LOYALTY: 'OolioLoyalty',
  PUBLISH_MENU: 'PublishMenu',
  TRANSFER_ITEMS: 'TransferItems',
};

export const URL_WHATSNEW = 'https://app.getbeamer.com/ooliocom/en';
export const URL_INTERCOM = 'https://intercom.help/oolio/en';

export const HEADER_FOOTER_CHAR_LIMIT = 1000;
export const POS_IDENTIFIER = 'oolio';

export const LOCALE_OPTIONS = [
  {
    label: 'English',
    value: LOCALE.ENGLISH_US,
  },
  {
    label: '中文',
    value: LOCALE.CHINESE,
  },
  {
    label: 'Español',
    value: LOCALE.SPANISH_SPAIN,
  },
  // TODO: Replace DUTCH with GERMAN
  // We are actually rendering text in german,
  // but the enums are incorrectly labelled as dutch.
  {
    label: 'Deutsch',
    value: LOCALE.DUTCH,
  },
  {
    label: 'हिंदी',
    value: LOCALE.HINDI,
  },
];

export const BUSINESS_SIZE_OPTIONS = [
  {
    label: BusinessSize.SMALL,
    value: BusinessSize.SMALL,
  },
  {
    label: BusinessSize.MEDIUM,
    value: BusinessSize.MEDIUM,
  },
  {
    label: BusinessSize.BIG,
    value: BusinessSize.BIG,
  },
];

export const BUSINESS_STATUS_OPTIONS = [
  {
    label: capitalCase(BusinessStatus.EXISTING_BUSINESS),
    value: BusinessStatus.EXISTING_BUSINESS,
  },
  {
    label: capitalCase(BusinessStatus.NEW_BUSINESS),
    value: BusinessStatus.NEW_BUSINESS,
  },
];
