import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const SelectTableStyles = () => {
  const { height } = theme.useResponsiveDimensions();

  return useMemo(
    () =>
      StyleSheet.create({
        backdrop: {
          height,
          justifyContent: 'center',
        },
        scaleDown: {
          transform: height > 768 ? [] : [{ scale: 0.8 }],
        },
        scaleUp: {
          transform: height > 768 ? [] : [{ scale: 1.2 }],
        },
        headerContainer: {
          height: 44,
          backgroundColor: theme.colors.white,
          borderRadius: theme.radius.s,
          alignSelf: 'center',
          width: theme.tableLayout.width + 80,
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 8,
        },
        headerText: {
          fontSize: 14,
          color: theme.colors.dark,
          fontFamily: theme.fonts.medium,
          lineHeight: 20,
        },
        btnClose: {
          height: 44,
          width: 44,
          position: 'absolute',
          left: 0,
          alignItems: 'center',
          justifyContent: 'center',
        },
        container: {
          backgroundColor: theme.colors.white,
          borderRadius: theme.radius.s,
          alignSelf: 'center',
          width: theme.tableLayout.width + 80,
          height: theme.tableLayout.height + 80,
          alignItems: 'center',
          justifyContent: 'flex-end',
          overflow: 'hidden',
        },
        tableLayout: {
          width: theme.tableLayout.width,
          height: theme.tableLayout.height,
          backgroundColor: theme.colors.white,
        },
      }),
    [height],
  );
};

export default SelectTableStyles;
