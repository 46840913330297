/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from 'react-native';

import { Store, Venue } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import Accordion from 'react-native-collapsible/Accordion';
import Search from '../../../components/Shared/Search/Search';
import Icon from '../../../components/Icon/Icon';
import theme from '../../../common/default-theme';

interface VenueAndStoreListProps {
  onSelect?: (venue: Venue, store: Store) => void;
  onSearch?: (searchString: string) => void;
  venuesData: Venue[];
  loading?: boolean;
}

const styles = StyleSheet.create({
  subTitleText: {
    lineHeight: 20,
    fontSize: 16,
    paddingVertical: 14,
    color: theme.colors.black1,
  },
  searchContainer: {
    width: theme.layoutWidth.s,
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionContainer: {
    paddingBottom: 80,
    width: theme.layoutWidth.s,
  },
  venueRow: {
    width: '100%',
    height: 50,
    backgroundColor: theme.colors.grey1,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    marginTop: 10,
  },
  storeRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    paddingHorizontal: 12,
  },
  storeText: {
    lineHeight: 20,
    paddingVertical: 14,
    color: theme.colors.grey7,
  },
  storeName: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadingContainer: {
    width: theme.layoutWidth.s,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const VenueAndStoreList: React.FC<VenueAndStoreListProps> = ({
  onSelect = () => undefined,
  onSearch = () => undefined,
  venuesData,
  loading = false,
}: VenueAndStoreListProps) => {
  const { translate } = useTranslation();

  const [activeSections, setActiveSections] = useState<string[]>([]);

  const handleActiveSections = useCallback((sections: number[] | string[]) => {
    setActiveSections(sections as unknown as string[]);
  }, []);

  useEffect(() => {
    const result: string[] = [];
    venuesData.map(venue => {
      result.push(venue.id);
    });
    handleActiveSections(result);
  }, [handleActiveSections, venuesData]);

  const renderVenues = (section: Venue, index: number, isActive: boolean) => {
    return (
      <View>
        <View style={styles.venueRow}>
          <Text>{section.name}</Text>
          <Icon
            name={isActive ? 'angle-up' : 'angle-down'}
            color={isActive ? theme.colors.accent : theme.colors.black}
          />
        </View>
      </View>
    );
  };
  const renderStores = (section: Venue) => {
    return (
      <View>
        {section.stores.map((store: Store, i: number) => (
          <TouchableOpacity
            key={i}
            onPress={(): void => onSelect(section, store)}
            style={styles.storeRow}
          >
            <View style={styles.storeName}>
              <Icon color={theme.colors.grey5} name="corner-down-right-alt" />
              <Text style={[styles.storeText, { paddingLeft: 10 }]}>
                {store.name}
              </Text>
            </View>
            <Icon size={20} color={theme.colors.grey5} name="angle-right" />
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  return (
    <>
      <Text style={styles.subTitleText}>
        {translate('assignRegister.selectStore')}
      </Text>
      <View style={styles.searchContainer}>
        <Search
          testID="search"
          maxLength={50}
          onChangeText={onSearch}
          placeholder={translate('backOfficeVenues.search')}
          containerStyle={{ flex: 1 }}
        />
      </View>
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size={'large'} color={theme.colors.primary} />
        </View>
      ) : (
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          <View style={styles.accordionContainer}>
            <Accordion
              activeSections={activeSections}
              sections={venuesData as Venue[]}
              touchableComponent={TouchableOpacity}
              renderHeader={renderVenues}
              renderContent={renderStores}
              duration={300}
              expandMultiple
              onChange={handleActiveSections}
              keyExtractor={venue => venue.id}
            />
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default VenueAndStoreList;
